/* 造訪驗證 */
.visitsBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	background: url("../image/visitsBg.jpg") no-repeat 50% 50%;
	background-size: cover;
	width: 100%;
	height: 100vh;
	z-index: 100;
}

#visits-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transform: translate3d(0, -20%, 0);
	color: #fff;

	.title {
		display: block;
		background: url("../image/visitsLogo.png") no-repeat 50% 50%;
		width: 365px;
		height: 315px;
		margin: 0;
		animation: visitsShow 0.8s cubic-bezier(0.37, 0.99, 0.92, 0.96);
	}

	.inputBox {
		display: flex;
		position: relative;
		width: 300px;
		height: 40px;
		border: 1px solid rgba(255, 255, 255, 0.35);
		border-radius: 3px;
		box-sizing: border-box;
		margin: 35px 0 0;
		transition: border-color 0.3s ease-in-out, width 0.8s cubic-bezier(0.53, 0.07, 0.93, 0.44);
		overflow: hidden;
		animation: visitsShow 0.8s cubic-bezier(0.37, 0.99, 0.92, 0.96) 0.1s;

		.passBox {
			flex: 1;
			background: transparent;
			padding-left: 13px;
			box-sizing: border-box;
			border: none;
			font-size: 13px;
			font-family: "Barlow";
			color: rgb(255, 255, 255);
			letter-spacing: 1.5px;
			background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgb(0, 0, 0) 100%);
			opacity: 0.3;
		}

		.subBtn {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(255, 255, 255, 0.35);
			width: 50px;
			transition: background-color 0.3s ease-in-out;
			z-index: 1;

			.sprite {
				fill: white;
				transition: fill 0.3s ease-in-out;
			}
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background: rgb(238, 238, 238);
			width: calc(100% - 50px);
			height: 100%;
			box-shadow: 0 0 2px rgba(0, 0, 0, 0.25) inset;
			transform: translate3d(-100%, 0, 0);
			/*transition: .8s ease-in;*/
		}

		&.sending {
			border-color: white;

			.subBtn {
				background-color: white;

				.sprite {
					fill: useGray(4);
				}
			}

			&::before {
				animation: visitsLoad 0.8s ease-in-out both;
				transform: translate3d(0, 0, 0);
			}
		}

		&.success {
			align-self: flex-end;
			width: 0;
			border: none;
			margin-right: calc((100% - 300px) / 2);

			&::before {
				transform: translate3d(0, 0, 0);
				width: 100%;
			}
		}

		&.error::before {
			transform: translate3d(0, 0, 0);
			width: 100%;
		}

		&:hover {
			border-color: white;

			.subBtn {
				background-color: white;

				.sprite {
					fill: useGray(4);
				}
			}
		}
	}

	.errorMsg {
		font-size: 14px;
		color: rgba(255, 255, 255, 0.6);
		line-height: 2.143;
		text-align: center;

		&.sprite {
			fill: rgba(255, 255, 255, 0.6);
			width: 16px;
			height: 16px;
			margin: -3.4px 5px 0 0;
		}
	}

	input.passBox {
		&:-webkit-autofill {
			box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.1) inset;
			transition: background-color 999999s ease-in;
		}
	}

	@include rwd-width(500) {
		.title {
			background-size: contain;
			width: 100%;
			max-width: 100%;
		}
	}
}

/* aside-bottom */
.aside-bottom {
	position: relative;
	z-index: 1;
	background-color: $mColor1;
	padding: 60px;
	overflow: hidden;

	.aside-info {
		position: relative;
		z-index: 2;
		display: flex;
	}

	.item {
		position: relative;
		flex: none;
		width: 50%;
		font-size: 16px;
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding:0 20px;
.restrict
		svg {
			width: 40px;
			height: 40px;
		}

		.title {
			font-size: 24px;
			font-weight: bold;
			margin-top: 15px;
		}

		.note {
			margin-top: 15px;
			line-height: 1.5em;
			max-width: 500px;
   			text-align: center;
			font-size: 16px;
		}

		.link {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			margin-top: 25px;
			border: 1px solid #fff;
			border-radius: 25px;
			font-size: 24px;
			font-weight: bold;
			padding: 10px 30px;
			letter-spacing: 2px;

			&:hover {
				color: $mColor1;
				background-color: #fff;
			}
		}

		+ .item {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 1px;
				background-color: #fff;
			}
		}
	}

	@include rwd-width(900) {
		.bg1 {
			top: -95px;
			left: -175px;
		}

		.bg2 {
			top: -245px;
			right: -100px;
			bottom: auto;
		}
	}

	@include rwd-width(768) {
		padding: 20px;
	}

	@include rwd-width(600) {
		.bg1 {
			left: -165px;
			top: -50px;
			background-size: 80%;
		}

		.bg2 {
			background-size: 80%;
			top: auto;
			bottom: -90px;
			right: -180px;
		}

		.aside-info {
			flex-direction: column;
			align-items: center;
		}

		.item {
			width: 100%;

			+ .item {
				margin-top: 20px;
				padding-top: 20px;

				&::before {
					right: 0px;
					bottom: auto;
					width: auto;
					height: 1px;
				}
			}
		}
	}

	@include rwd-width(425) {
		.bg1 {
			background-size: 60%;
		}

		.bg2 {
			background-size: 60%;
		}
	}
}

.home-block {
	position: relative;
	margin-top: 55px;

	.irnfont {
		font-family: irnfont;
	}

	.wave {
		position: absolute;
		z-index: 1;
		top: -90px;
		left: 0;
		right: 0;
		height: 90px;
		background: center no-repeat;
		background-size: cover;
	}

	.title {
		display: block;
		font-size: 32px;
		font-family: irnfont;
	}

	.subtitle {
		display: inline-block;
		color: #fff;
		background-color: #cb9464;
		padding: 3px 6px;
		margin-top: 21px;
		letter-spacing: 1.5px;
	}

	.tag {
		position: absolute;
		z-index: 3;
		color: #fff;
		font-size: 19px;
		background-color: $mColor1;
		display: inline-block;
		writing-mode: vertical-lr;
		padding: 10px 3px;
	}

	.caption {
		position: relative;
		z-index: 8;

		.text {
			margin-top: 15px;
			line-height: 1.8em;
			font-size: 17px;
		}
	}

	.col2 {
		max-width: 1480px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		.left,
		.right {
			position: relative;
			flex: none;
			width: calc(50% - 40px);
		}
	}

	.block1 {
		position: relative;
		z-index: 1;
		height: 1000px;
		padding-top: 115px;

		.drawing1 {
			position: absolute;
			z-index: 3;
			top: 20%;
			right: 48%;
			margin: 0;
			max-width: 100%;
		}

		.drawing2 {
			position: absolute;
			z-index: 3;
			top: 2%;
			right: 9%;
			margin: 0;
			max-width: 100%;
		}

		.plum {
			position: absolute;
			z-index: 3;
			top: 30%;
			right: 8%;
			margin: 0;
			max-width: 100%;
		}

		.plum1 {
			position: absolute;
			z-index: 3;
			top: 13%;
			left: 7%;
			margin: 0;
			max-width: 100%;
		}

		.plum2 {
			position: absolute;
			z-index: 3;
			bottom: 20%;
			left: -2%;
			margin: 0;
			max-width: 100%;
		}

		.leaf4 {
			position: absolute;
			z-index: 3;
			bottom: 33%;
			left: 39%;
			margin: 0;
			max-width: 100%;
		}

		.grassland {
			position: absolute;
			z-index: 2;
			left: -20px;
			right: -20px;
			top: 19%;
			margin: 0;
			height: 1200px;
			background: url("/upload/home/grassland.png") center top no-repeat;
			background-size: cover;
		}

		.tree {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 12%;
			margin: 0;
			max-width: 100%;
		}

		.girl {
			position: absolute;
			z-index: 3;
			top: 20%;
			left: 15%;
			margin: 0;
			max-width: 100%;
		}

		.caption {
			margin-left: 53.2%;
			width: 620px;

			.text {
				line-height: 2.4em;
			}
		}

		.scroll {
			position: absolute;
			z-index: 5;
			left: 0;
			right: 0;
			bottom: -115px;
			margin: 0 auto;
			background: url("/upload/home/scroll.png");
			width: 109px;
			height: 98px;
			display: none;
		}
	}

	.block2 {
		position: relative;
		z-index: 2;
		background-color: #ccddc9;
		padding: 50px 0;

		.wave {
			top: -270px;
			height: 270px;
			background-image: url("/upload/home/story1Bg.svg");
		}

		.bubble {
			position: absolute;
			z-index: 1;
			top: 40%;
			left: 7%;
		}

		.plum4 {
			position: absolute;
			z-index: 1;
			top: -13%;
			right: 6%;
		}

		.plum5 {
			position: absolute;
			z-index: 1;
			bottom: 15%;
			left: 35.6%;
		}

		.leaf5 {
			position: absolute;
			z-index: 3;
			top: -65%;
			right: 4.7%;
		}

		.leaf6 {
			position: absolute;
			z-index: 3;
			top: -20%;
			right: 24%;
		}

		.leaf1 {
			position: absolute;
			z-index: 1;
			top: -125px;
			left: -90px;
		}

		.leaf2 {
			position: absolute;
			z-index: 1;
			top: 225px;
			right: -295px;
		}

		.mulberry1 {
			position: absolute;
			z-index: 3;
			top: -5%;
			right: 48%;
		}

		.fresh-mulberry {
			position: absolute;
			z-index: 2;
			top: -76%;
			right: 0.7%;
		}

		.img {
			position: relative;
			z-index: 2;
			margin: -80px 0 0 56px;
		}

		.tag {
			top: 14px;
			left: 136px;
		}
	}

	.block3 {
		position: relative;
		z-index: 3;
		background-color: #e9d8c0;
		padding: 202px 0 45px;

		.wave {
			background-image: url("/upload/home/story2Bg.svg");
		}

		.bubble1 {
			position: absolute;
			z-index: 1;
			top: 12%;
			right: 20%;
		}

		.bubble2 {
			position: absolute;
			z-index: 1;
			bottom: 18%;
			right: 1%;
		}

		.bubble3 {
			position: absolute;
			z-index: 1;
			top: -8%;
			left: -1%;
		}

		.leaf {
			position: absolute;
			z-index: 1;
			top: 2%;
			left: -10.4%;
		}

		.plum4 {
			position: absolute;
			z-index: 1;
			top: 1%;
			right: 3%;
		}

		.plum5 {
			position: absolute;
			z-index: 1;
			bottom: 24%;
			left: -2%;
		}

		.story2-plum {
			position: absolute;
			z-index: 3;
			bottom: -30%;
			right: -10%;
		}

		.col2 {
			max-width: 1520px;

			.left,
			.right {
				position: relative;
				flex: none;
				width: calc(50% - 60px);
			}
		}

		.img {
			position: relative;
			z-index: 2;
			margin: -367px 0 0 -90px;
		}

		.tag {
			top: 32px;
			right: -12px;
		}

		.subtitle {
			margin-top: 23px;
		}

		.caption {
			.text {
				margin-top: 25px;
			}
		}
	}

	.block4 {
		position: relative;
		z-index: 4;
		background-color: #ccddc9;
		padding: 315px 0 50px;

		.wave {
			background-image: url("/upload/home/story3Bg.svg");
		}

		.plum {
			position: absolute;
			z-index: 1;
			top: 16%;
			left: 5%;
		}

		.bubble1 {
			position: absolute;
			z-index: 1;
			top: 18%;
			left: 29%;
		}

		.bubble2 {
			position: absolute;
			z-index: 1;
			bottom: 19%;
			right: 0%;
		}

		.greenlemon1 {
			position: absolute;
			z-index: 1;
			top: -10%;
			left: -10%;
		}

		.greenlemon2 {
			position: absolute;
			z-index: 3;
			bottom: 8%;
			left: 60%;
		}

		.greenlemon3 {
			position: absolute;
			z-index: 1;
			bottom: 48%;
			right: -20%;
		}

		.caption {
			.text {
				margin-top: 25px;
			}
		}

		.img {
			position: relative;
			z-index: 2;
			margin: -345px 0 0 10px;
		}
	}

	.block5 {
		position: relative;
		z-index: 5;
		background-color: #e9d8c0;
		padding: 260px 0 10px;

		.wave {
			background-image: url("/upload/home/story4Bg.svg");
		}

		.plum {
			position: absolute;
			z-index: 1;
			bottom: 28%;
			right: -2%;
		}

		.bubble {
			position: absolute;
			z-index: 1;
			top: 25%;
			left: 7%;
		}

		.mulberry1 {
			position: absolute;
			z-index: 1;
			top: -3.6%;
			left: 5.8%;
		}

		.mulberry2 {
			position: absolute;
			z-index: 1;
			top: 13.5%;
			right: 9.2%;
		}

		.subtitle {
			margin-top: 15px;
		}

		.tag {
			top: -157px;
			left: 44px;
		}

		.img {
			position: relative;
			z-index: 2;
			margin: -180px 0 0 0;
		}

		.col2 {
			max-width: 1520px;

			.left,
			.right {
				position: relative;
				flex: none;
				width: calc(50% - 60px);
			}
		}

		.caption {
			.text {
				margin-top: 25px;
			}
		}
	}

	.products {
		position: relative;
		z-index: 6;
		background: url("/upload/home/bg5.jpg") center top no-repeat;
		padding: 180px 0 0;

		.wave {
			top: 0;
			background-image: url("/upload/home/productsBg.svg");
		}

		.category-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;

			.flag {
				max-width: 100%;
			}

			.category {
				margin-top: 30px;
				overflow: hidden;
				overflow-x: auto;
				max-width: 100%;
				white-space: nowrap;

				.item {
					position: relative;
					font-size: 19px;

					+ .item {
						margin-left: 30px;
						padding-left: 30px;

						&::before {
							content: "";
							position: absolute;
							top: 3px;
							left: -6px;
							width: 1px;
							height: 20px;
							background-color: useGray(6);
						}
					}
				}
			}
		}

		.title {
			margin: 0 auto;
		}

		.caption {
			margin-top: 80px;
			text-align: center;

			.text {
				margin-top: 27px;
			}
		}

		.list-wrap {
			margin-top: 60px;
		}

		.recommend-list {
			position: relative;
			display: none;
			margin: 30px auto 0;
			max-width: 1400px;

			.item {
				display: flex;
				flex-direction: column;
				max-width: 260px;

				.info {
					text-align: center;

					.name {
						color: $mColor3;
						font-weight: bold;
						font-size: 19px;
						margin-top: 23px;
					}

					.subname {
						color: $mColor4;
						font-size: 13px;
						margin-top: 11px;
					}

					.price-info {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-top: 18px;

						.special {
							background-color: $mColor5;
							padding: 0 2px;
							color: #fff;
							margin-right: 6px;
						}

						.price {
							color: $mColor5;
							font-size: 21px;
							font-weight: bold;
						}
					}
				}

				&:hover {
					.info {
						.name {
							color: $mColor1;
						}
					}
				}
			}

			.swiper-container {
				max-width: 1160px;
			}

			.swiper-slide {
				overflow: hidden;

				&:hover {
					border-color: $mColor1;
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				position: absolute;
				z-index: 2px;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px;
				height: 100%;
				background-image: none;
				color: $mColor1;
				margin: 0;

				svg {
					width: 50px;
					height: 50px;
				}

				&.swiper-button-disabled {
					opacity: 0;
				}
			}

			.swiper-button-prev {
				right: 0;
			}

			.swiper-button-next {
				left: auto;
			}

			&.selected {
				display: block;
			}
		}

		.buy-now {
			display: flex;
			justify-content: center;
			padding-top: 14px;
			margin: 50px auto;
			background: url("/upload/home/buynow.png") center no-repeat;
			background-size: contain;
			width: 204px;
			height: 107px;
			color: #fff;
		}
	}

	@include rwd-width(1860) {
		.block1 {
			.plum {
				right: 1%;
			}
		}
	}

	@include rwd-width(1760) {
		.block2,
		.block3,
		.block4,
		.block5 {
			.img {
				max-width: 100%;
			}
		}

		.block1 {
			.tree {
				left: 7%;
			}

			.girl {
				left: 8%;
			}
		}

		.block2 {
			.fresh-mulberry {
				max-width: 450px;
				top: -60%;
				right: 5%;
			}

			.tag {
				left: 60px;
			}

			.img {
				margin: -80px 0 0;
			}
		}

		.block3 {
			.tag {
				top: -30px;
				right: 100px;
			}
		}

		.block4 {
			.greenlemon2 {
				bottom: 3%;
				left: 50%;
			}

			.greenlemon3 {
				right: -5%;
			}
		}
	}

	@include rwd-width(1680) {
		.block1 {
			.plum1 {
				top: 25%;
				left: 2%;
			}

			.girl {
				max-width: 250px;
				top: 27%;
				left: 12%;
			}
		}

		.block2 {
			.plum5 {
				bottom: 5%;
				left: 35%;
			}

			.leaf5 {
				top: -60%;
				right: 9%;
			}
		}

		.block3 {
			.col2 {
				max-width: 1480px;
			}

			.img {
				margin: -210px 0 0;
			}

			.story2-plum {
				max-width: 220px;
				bottom: -20%;
				right: -13%;
			}
		}

		.block4 {
			.img {
				margin: -290px 0 0;
			}
		}

		.block5 {
			.plum {
				bottom: 0;
				max-width: 130px;
			}
			.tag {
				left: 90px;
			}
			.img {
				margin: -120px 0 0 85px;
			}
			.col2 {
				max-width: 1480px;
			}
		}
	}

	@include rwd-width(1540) {
		.col2 {
			margin: 0 30px;
		}

		.block1 {
			.plum {
				right: -3%;
				top: 45%;
			}

			.leaf4 {
				display: none;
			}

			.grassland {
				top: 25%;
				height: 1000px;
			}
		}

		.block2 {
			.leaf6 {
				right: 27%;
			}
		}

		.block3 {
			.tag {
				right: 10px;
			}
		}

		.block4 {
			.greenlemon3 {
				right: 5%;
			}
		}
	}

	@include rwd-width(1360) {
		.caption {
			.text {
				font-size: 18px;
			}
		}

		.block1 {
			.tree {
				max-width: 700px;
				top: 0;
				left: 3%;
			}

			.plum1 {
				max-width: 50px;
			}

			.girl {
				max-width: 200px;
				top: 22%;
			}

			.grassland {
				top: 16%;
			}

			.caption {
				width: 43%;
				margin-left: auto;
				margin-right: 30px;

				.text {
					line-height: 2em;
				}
			}
		}

		.block2 {
			.fresh-mulberry {
				max-width: 380px;
				top: -65%;
				right: 10%;
			}

			.leaf1,
			.leaf2 {
				opacity: 0.5;
			}

			.leaf5 {
				right: 13%;
				max-width: 70px;
			}

			.leaf6 {
				right: 30%;
				max-width: 90px;
			}

			.img {
				margin-top: 0;
			}
		}

		.block3 {
			.leaf {
				left: -15%;
				opacity: 0.5;
			}

			.tag {
				right: -25px;
			}

			.img {
				margin-top: -190px;
			}
		}

		.block4 {
			.img {
				margin-top: -250px;
			}
		}
	}

	@include rwd-width(1260) {
		.tag {
			font-size: 17px;
			padding: 6px 3px;
		}

		.col2 {
			.left,
			.right {
				width: calc(50% - 10px);
			}
		}

		.block1 {
			height: 730px;
			padding-top: 70px;

			.drawing1 {
				right: 50%;
			}

			.drawing2 {
				top: -20px;
			}

			.plum {
				right: -7%;
			}

			.plum1 {
				top: 15%;
				left: -20px;
			}

			.plum2 {
				display: none;
			}

			.girl {
				max-width: 165px;
				top: 23%;
				left: 10%;
			}

			.tree {
				max-width: 600px;
			}

			.grassland {
				height: 800px;
			}

			.caption {
				margin-right: 60px;
			}
		}

		.block2 {
			padding: 50px 0 80px;

			.mulberry1 {
				max-width: 90px;
				top: -12%;
				right: auto;
				left: 40%;
			}

			.plum4 {
				display: none;
			}

			.plum5 {
				bottom: 30px;
				left: 45%;
				max-width: 60px;
			}

			.fresh-mulberry {
				max-width: 330px;
				top: -45%;
			}

			.leaf5 {
				top: -42%;
			}

			.leaf6 {
				top: -10%;
				max-width: 70px;
			}

			.leaf1 {
				left: -280px;
			}

			.leaf2 {
				top: 110px;
				right: -530px;
			}

			.bubble {
				left: -35px;
				max-width: 70px;
			}

			.tag {
				left: 10px;
			}
		}

		.block3 {
			.plum4 {
				display: none;
			}

			.bubble1 {
				top: 22%;
				right: 3%;
				max-width: 70px;
			}

			.bubble2,
			.bubble3 {
				display: none;
			}

			.story2-plum {
				max-width: 190px;
				right: -3%;
			}

			.leaf {
				left: -35%;
			}

			.img {
				margin-left: -60px;
			}

			.tag {
				right: 25px;
			}

			.col2 {
				.left,
				.right {
					width: calc(50% - 10px);
				}
			}
		}

		.block4 {
			padding: 270px 0 80px;

			.plum {
				left: -50px;
				max-width: 130px;
			}

			.bubble2 {
				display: none;
			}

			.greenlemon3 {
				right: -3%;
			}

			.img {
				margin-top: -160px;
			}

			.col2 {
				.left,
				.right {
					width: calc(50% - 10px);
				}
			}
		}

		.block5 {
			.plum {
				display: none;
			}

			.bubble {
				top: 45%;
				left: 0;
				max-width: 70px;
			}

			.mulberry1 {
				top: 30px;
				left: 0;
				max-width: 100px;
			}

			.mulberry2 {
				right: 0;
				max-width: 80px;
			}

			.tag {
				left: 10px;
			}

			.img {
				margin: -90px 0 0;
			}

			.col2 {
				.left,
				.right {
					width: calc(50% - 10px);
				}
			}
		}

		.products .recommend-list .swiper-container {
			margin: 0 50px;
		}
	}

	@include rwd-width(1024) {
		.block1 {
			.grassland {
				top: 28%;
				height: 700px;
			}

			.girl {
				top: 30%;
			}

			.caption {
				width: 50%;
			}
		}

		.block3 .story2-plum {
			max-width: 150px;
			right: 0;
			bottom: -10%;
		}

		.block4 {
			.greenlemon3 {
				right: 5%;
			}
		}

		.products {
			.caption {
				margin: 80px 20px 0;
			}

			.recommend-list {
				.item {
					.info {
						.price-info {
							.price {
								font-size: 17px;
							}
						}
					}
				}
			}
		}
	}

	@include rwd-width(900) {
		.caption {
			.text {
				font-size: 15px;
			}
		}

		.block1 {
			.tree {
				top: 0;
				left: 0;
			}

			.grassland {
				height: 620px;
			}

			.drawing1 {
				max-width: 25px;
			}

			.drawing2 {
				top: 10px;
				max-width: 70px;
			}

			.plum1 {
				top: 23%;
			}

			.plum {
				right: -4%;
				max-width: 70px;
			}

			.caption {
				.text {
					font-size: 16px;
				}
			}
		}

		.block2 {
			.mulberry1 {
				top: -10%;
				left: 5%;
				max-width: 75px;
			}
			.fresh-mulberry {
				max-width: 280px;
				top: -25%;
			}

			.leaf5 {
				top: -22%;
				max-width: 55px;
			}

			.leaf6 {
				top: 10%;
				max-width: 55px;
			}

			.img {
				margin-top: 70px;
			}
		}

		.block3 {
			.plum5 {
				display: none;
			}

			.bubble1 {
				top: 15%;
			}

			.story2-plum {
				bottom: -20%;
			}

			.img {
				margin-top: -65px;
			}
		}

		.block4 {
			padding-top: 150px;

			.img {
				margin: -140px 0 0 -30px;
				max-width: 135%;
			}

			.greenlemon1 {
				top: 0;
				left: 6%;
				max-width: 30px;
			}
			.greenlemon2 {
				bottom: 0;
				max-width: 30px;
			}
			.greenlemon3 {
				right: -18%;
			}

			.plum {
				max-width: 90px;
			}
		}

		.block5 {
			padding-top: 140px;

			.mulberry1 {
				top: 100px;
				left: -15px;
				max-width: 90px;
			}

			.mulberry2 {
				top: 10%;
				max-width: 65px;
			}

			.tag {
				top: 0;
			}

			.img {
				margin: 60px 0 0;
			}
		}
	}

	@include rwd-width(768) {
		.wave {
			top: -40px;
			height: 40px;
		}

		.block1 {
			height: 650px;

			.drawing1 {
				display: none;
			}

			.girl {
				top: 34%;
				left: 5%;
				max-width: 125px;
			}

			.tree {
				left: -80px;
				max-width: 550px;
			}

			.caption {
				width: 60%;
				margin-right: 30px;
			}
		}

		.block2 {
			.wave {
				top: -130px;
				height: 130px;
			}
			.leaf5,
			.leaf6 {
				display: none;
			}
			.fresh-mulberry {
				max-width: 200px;
				top: -20%;
				right: 5%;
			}

			.home-block .block2 .plum5 {
				bottom: 5px;
				left: 48%;
				max-width: 45px;
			}

			.img {
				margin-top: 50px;
				max-width: 105%;
			}
		}

		.block3 {
			.leaf {
				top: 10%;
				left: -50%;
			}
			.bubble1 {
				top: 20%;
				max-width: 50px;
			}
		}

		.block4 {
			.greenlemon1 {
				top: -7%;
			}

			.bubble1 {
				left: 40%;
				max-width: 60px;
			}

			.plum {
				display: none;
			}
		}

		.block5 {
			.bubble {
				display: none;
			}
		}
	}

	@include rwd-width(650) {
		.wave {
			position: relative;
		}

		.col2 {
			flex-direction: column;

			.left,
			.right {
				width: auto;
			}
		}

		.block1 {
			height: auto;
			padding-top: 0;

			.tree {
				position: relative;
				left: 10%;
			}

			.grassland {
				position: relative;
				left: 0;
				right: 0;
				bottom: 0;
				margin-top: -260px;
				height: 700px;
			}

			.girl {
				top: 30%;
				left: 20%;
				max-width: 160px;
			}

			.drawing2 {
				top: 57%;
			}

			.plum,
			.plum1,
			.plum2 {
				display: none;
			}

			.caption {
				width: auto;
				margin: -380px 30px 140px;

				.text {
					text-shadow: 1px 0 0px white, -1px 0 0px white, 0 1px 0px white, 0 -1px 0px white;
					font-weight: bold;
				}
			}
		}

		.block2 {
			padding: 0 0 30px;

			.wave {
				top: -90px;
				height: 90px;
			}

			.leaf1,
			.leaf2,
			.bubble,
			.fresh-mulberry {
				display: none;
			}

			.mulberry1 {
				top: -5%;
				left: 0;
				max-width: 60px;
			}

			.plum5 {
				top: 40%;
				right: 50%;
				max-width: 45px;
			}

			.tag {
				top: 10%;
				left: 5%;
			}

			.col2 {
				margin-top: -110px;
				margin-bottom: 30px;

				.left {
					order: 2;
				}

				.right {
					order: 1;
				}
			}

			.img {
				margin-top: 30px;
				max-width: 100%;
			}
		}

		.block3 {
			padding: 0 0 30px;

			.col2 {
				.left,
				.right {
					width: auto;
				}
			}

			.img {
				margin: 0 auto;
				max-width: 65%;
			}

			.bubble1 {
				display: none;
			}

			.leaf {
				top: 15%;
			}

			.story2-plum {
				right: 19%;
				bottom: -15%;
				max-width: 140px;
			}

			.caption {
				margin-top: 45px;
			}

			.tag {
				top: 5%;
				right: 5%;
			}
		}

		.block4 {
			padding: 0 0 30px;

			.col2 {
				margin-top: 0;
				margin-bottom: 30px;

				.left,
				.right {
					width: auto;
				}

				.left {
					order: 2;
					margin-top: 20px;
				}

				.right {
					order: 1;
				}
			}

			.greenlemon1 {
				display: none;
			}

			.greenlemon2 {
				bottom: 2%;
				right: 50%;
			}

			.greenlemon3 {
				bottom: 15%;
				right: 5%;
				max-width: 50px;
			}

			.bubble1 {
				top: 13%;
				left: 2%;
			}

			.img {
				margin: 30px 0 0;
				max-width: 100%;
			}
		}

		.block5 {
			padding-top: 0;

			.col2 {
				margin-top: 60px;

				.left,
				.right {
					width: auto;
				}
			}

			.mulberry1,
			.mulberry2 {
				display: none;
			}
		}

		.products {
			padding-top: 0;

			.category-wrap {
				margin: 60px 20px 0;
			}

			.caption {
				margin: 40px 20px 0;
				text-align: left;
			}
		}
	}

	@include rwd-width(550) {
		margin-top: 20px;

		.col2 {
			margin: 0 10px;
		}

		.tag {
			font-size: 15px;
		}

		.block1 {
			.girl {
				top: 32%;
			}

			.caption {
				margin-left: 10px;
				margin-right: 10px;

				.text {
					font-size: 14px;
					line-height: 1.7em;
				}
			}
		}

		.block2 {
			.plum5 {
				display: none;
			}
		}

		.block3 {
			.leaf {
				display: none;
			}

			.img {
				max-width: 280px;
				margin: 0 0 0 30px;
			}

			.story2-plum {
				right: 10%;
				max-width: 100px;
			}
		}

		.block4 {
			.col2 {
				margin-top: 0;
			}

			.bubble1 {
				top: 3%;
			}
		}

		.products {
			.category-wrap {
				margin: 60px 20px 0;
			}

			.caption {
				margin: 40px 20px 0;
				text-align: left;
			}
			.recommend-list {
				.swiper-container {
					margin: 0 20px;
				}
			}
		}
	}

	@include rwd-width(425) {
		margin-top: 0;

		.block1 {
			.drawing2 {
				top: 50%;
			}

			.tree {
				left: 5%;
				max-width: 450px;
				margin-top: -50px;
			}

			.girl {
				top: 24%;
				left: 20%;
				max-width: 140px;
			}

			.caption {
				margin: -400px 10px 160px;
			}

			.scroll {
				display: block;
			}
		}

		.block3 {
			.img {
				margin: 0;
			}

			.story2-plum {
				right: 5%;
			}
		}

		.block4 {
			.greenlemon2 {
				max-width: 20px;
			}

			.greenlemon3 {
				max-width: 35px;
			}
		}

		.block5 {
			.col2 {
				margin-top: 0px;
			}

			.img {
				margin-top: 30px;
			}

			.tag {
				top: 20px;
			}
		}

		.products {
			.recommend-list .item {
				max-width: none;
			}
		}
	}
}

@keyframes visitsLoad {
	0% {
		transform: translate3d(-100%, 0, 0);
	}

	30% {
		transform: translate3d(-70%, 0, 0);
	}

	80% {
		transform: translate3d(-20%, 0, 0);
	}

	95% {
		transform: translate3d(-5%, 0, 0);
	}

	100% {
		transform: translate3d(0%, 0, 0);
	}
}

@keyframes visitsShow {
	0% {
		opacity: 0;
		transform: translate3d(0, 45px, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
