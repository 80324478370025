.product-list {
	position: relative;
	background: #fff url("../image/bubble.png") repeat-y;
	padding: 70px 100px;

	img {
		max-width: 100%;
	}

	.list-wrap {
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		grid-gap: 60px;

		.col2 {
			grid-column: 1 / 3;
		}
	}

	.item {
		display: flex;
		flex-direction: column;

		.image {
			max-height: 250px;
		}

		.info {
			text-align: center;

			.name {
				color: $mColor3;
				font-weight: bold;
				font-size: 19px;
				margin-top: 23px;
			}

			.subname {
				color: $mColor4;
				font-size: 13px;
				margin-top: 11px;
				min-height: 34px;
			}

			.price-info {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 18px;

				.special {
					background-color: $mColor5;
					padding: 0 2px;
					color: #fff;
					margin-right: 6px;
				}

				.price {
					color: $mColor5;
					font-size: 21px;
					font-weight: bold;
				}
			}
		}

		&:hover {
			.info {
				.name {
					color: $mColor1;
				}
			}
		}
	}

	@include rwd-width(1200) {
		padding: 60px;

		.list-wrap {
			grid-gap: 50px;
		}
	}

	@include rwd-width(1024) {
		padding: 50px 30px 30px;

		.list-wrap {
			grid-gap: 30px;
		}
	}

	@include rwd-width(900) {
		.list-wrap {
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 20px;

			.col2 {
				grid-column: 2 / 4;
			}
		}
	}

	@include rwd-width(750) {
		padding-top: 200px;
	}

	@include rwd-width(670) {
		.list-wrap {
			grid-template-columns: repeat(2, 1fr);

			.col2 {
				grid-column: 1 / 3;
			}
		}
	}
	@include rwd-width(480) {
		.item {
			.info {
				.name {
					font-size: 16px;
				}
				.price-info {
					.price {
						font-size: 14px;
					}
				}
			}
		}
	}
	@include rwd-width(425) {
		padding: 180px 15px 30px;

		.list-wrap {
			grid-gap: 15px;
		}
	}
}

.product-info {
	position: relative;
	background: #fff no-repeat;
	background-image: url("../image/vein3-green.png"), url("../image/vein2-green.png");
	background-position: 99% -1%, 112% 20%;
	padding: 70px 60px;

	.info-wrap {
		display: flex;
		justify-content: space-between;

		.ileft,
		.iright {
			position: relative;
			width: calc(50% - 20px);
		}

		.swiper-container {
			position: relative;
			height: 260px;
		}

		.product-append {
			position: absolute;
			top: 0;
			left: 0;
			width: 80px;
			height: 340px;
			padding: 40px 0;
		}

		.swiper-slide {
			width: 80px;
			height: 80px;
			border: 1px solid transparent;
			border-radius: 8px;
			overflow: hidden;
			cursor: pointer;

			img {
				max-width: 100%;
			}

			&:hover,
			&.selected {
				border-color: $mColor1;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			position: absolute;
			z-index: 2px;
			top: auto;
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 40px;
			background-image: none;
			color: $mColor1;
			margin: 0;

			svg {
				width: 30px;
				height: 30px;
			}

			&.swiper-button-disabled {
				opacity: 0;
			}
		}

		.swiper-button-prev {
			top: 0;
		}

		.swiper-button-next {
			bottom: 0;
		}

		.cover {
			width: 100%;
			max-height: 650px;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.subname {
			display: inline-block;
			color: $mColor1;
			background-color: $mColor6;
			padding: 2px 5px;
		}

		.name {
			font-size: 30px;
			font-weight: bold;
			color: $mColor3;
			margin: 10px 0;
		}

		.price-wrap {
			display: flex;
			align-items: baseline;

			.price,
			.special {
				color: $mColor5;
				font-size: 30px;
				font-weight: bold;
			}

			.special + .price {
				color: useGray(8);
				font-size: 16px;
				margin-left: 10px;
				text-decoration: line-through;
			}
		}

		.attribs {
			margin-top: 20px;
			min-height: 250px;
		}

		.attrib-title {
			font-size: 21px;
			font-weight: bold;
			color: $mColor1;
		}

		.attrib {
			line-height: 1.2em;
			margin: 15px 0 0;

			.item + .item {
				margin-top: 15px;
			}
		}

		.quantity {
			display: flex;
			align-items: center;
			height: 45px;
			margin-top: 30px;

			select {
				height: 45px;
				width: 200px;
			}

			.stock_num {
				color: $mColor1;
			}
		}

		.buttons {
			margin-top: 30px;

			.button {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			svg {
				width: 24px;
				height: 24px;
			}
		}
	}

	.gline {
		width: 100%;
		height: 6px;
		margin: 5px 0;
		fill: $mColor1;
	}

	.desc-title {
		position: relative;
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			height: 35px;
		}

		.juicy {
			position: absolute;
			top: -80px;
			left: 30px;
			width: 135px;
			height: 196px;
		}
	}

	.desc-block {
		margin-top: 50px;
		color: $mColor3;

		img {
			max-width: 100%;
			object-fit: contain;

			&.txt1 {
				height: 60px;
			}

			&.txt2 {
				height: 50px;
			}
		}

		.tag {
			color: #fff;
			background-color: $mColor1;
			display: inline-block;
		}

		.subtitle {
			margin-top: 20px;
			font-size: 23px;
			font-weight: bold;
		}

		.block1 {
			display: flex;
			flex-direction: column;

			.image {
				position: relative;
				z-index: 1;

				.tag {
					position: absolute;
					z-index: 2;
					top: 30px;
					right: 80px;
					writing-mode: vertical-lr;
					padding: 10px 3px;
				}

				img {
					position: relative;
					z-index: 1;
				}
			}

			.wrap {
				position: relative;
				z-index: 2;
				display: flex;
				justify-content: space-between;
				margin-top: -200px;
				margin-left: 100px;

				.wrap2 {
					align-self: end;
					padding: 0 10px;
					margin: 0 0 130px;
				}
			}
		}

		.block2 {
			margin-top: 50px;

			.tag {
				padding: 3px 10px;
				margin-bottom: 20px;
			}

			.col2 {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.item {
					width: calc(50% - 20px);
				}

				.text {
					margin-top: 20px;
					line-height: 1.7em;
					max-width: 70%;
				}
			}

			.col3 {
				margin: 50px auto 0;
				display: flex;
				justify-content: space-between;
				width: 90%;

				.item {
					width: calc(33.33% - 50px);
				}

				.image {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 0;

					img {
						position: relative;
						z-index: 3;
						width: 100%;
						border-radius: 50%;
					}

					&::before {
						content: "";
						display: block;
						position: absolute;
						z-index: 1;
						background-color: $mColor6;
						border-radius: 50%;
						width: 100%;
						height: 100%;
					}

					&::after {
						content: "";
						display: block;
						position: absolute;
						z-index: 2;
						background-color: $mColor1;
						border-radius: 50%;
						width: 100%;
						height: 100%;
					}

					&.t1 {
						&::before {
							top: -10px;
							left: -10px;
						}

						&::after {
							bottom: -10px;
							left: 0;
						}
					}

					&.t2 {
						&::before {
							bottom: -15px;
							left: -20px;
						}

						&::after {
							bottom: -10px;
							left: 0x;
						}
					}

					&.t3 {
						&::before {
							z-index: 3;
							bottom: -10px;
							right: -5px;
						}

						&::after {
							z-index: 2;
							top: -13px;
							left: -15px;
						}
					}
				}

				.subtitle {
					margin-top: 40px;
					text-align: center;
				}
			}
		}

		.block3 {
			margin-top: 50px;

			.tag {
				padding: 3px 10px;
				margin-bottom: 20px;
			}

			.col2 {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.item {
					position: relative;
					width: calc(50% - 40px);
				}

				.wrap {
					position: absolute;
					top: -45px;
					right: 0;
				}

				.txt2 {
					display: block;
				}

				.text {
					margin-top: 20px;
					line-height: 1.7em;
					max-width: 70%;
				}
			}
		}

		.block4 {
			margin-top: 50px;

			.center {
				text-align: center;
				margin-bottom: 50px;
			}

			.col2 {
				display: flex;
				justify-content: space-between;
				text-align: center;

				.item {
					width: calc(50% - 20px);
				}
			}

			.icon {
				width: 120px;
				height: 120px;
				margin: 0 auto 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $mColor6;
				border-radius: 50%;

				img {
					width: 50%;
					height: 50%;
				}
			}

			.gtxt {
				font-size: 19px;
				font-weight: bold;
				color: $mColor1;
			}

			.text {
				font-size: 28px;
				font-weight: bold;
			}
		}

		.block5 {
			margin-top: 50px;
			text-align: center;

			.center {
				margin-bottom: 50px;
			}

			.wrap {
				display: inline-flex;

				.wrapinfo {
					margin-left: 40px;
				}

				.slogan {
					display: flex;
					justify-content: space-between;

					.item {
						width: 167px;
						height: 163px;
						font-size: 32px;
						font-weight: bold;
						display: flex;
						justify-content: center;
						align-items: center;
						background-size: contain;

						+ .item {
							margin-left: 20px;
						}
					}
				}

				.txt-wrap {
					display: inline-flex;
					justify-content: center;
					text-align-last: left;
					margin-top: 30px;

					.text {
						line-height: 1.5em;
						font-weight: bold;
						font-size: 19px;
					}
				}
			}

			.photo {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 50px;

				img {
					border: 1px solid useGray(14);
				}

				.pleft,
				.pright {
					width: calc(50% - 40px);
				}

				.pleft {
					padding: 0 20px;

					img {
						border-color: $mColor1;
					}
				}

				.caption {
					margin-top: 15px;
					font-size: 17px;
					font-weight: bold;
					color: $mColor1;
				}

				.caption2 {
					color: $mColor4;
				}

				.col2 {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;
					margin: -40px 0 0 -40px;

					.item {
						width: calc(50% - 40px);
						margin: 40px 0 0 40px;
					}
				}
			}
		}

		.block6 {
			margin-top: 50px;
			text-align: center;

			.center {
				margin-bottom: 50px;
			}

			.col3 {
				margin: 50px auto 0;
				display: flex;
				justify-content: space-between;
				width: 90%;

				.item {
					width: calc(33.33% - 50px);
				}

				.image {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 0;

					img {
						position: relative;
						z-index: 3;
						width: 100%;
						border-radius: 50%;
					}

					&::before {
						content: "";
						display: block;
						position: absolute;
						z-index: 1;
						background-color: $mColor6;
						border-radius: 50%;
						width: 100%;
						height: 100%;
					}

					&::after {
						content: "";
						display: block;
						position: absolute;
						z-index: 2;
						background-color: $mColor1;
						border-radius: 50%;
						width: 100%;
						height: 100%;
					}

					&.t1 {
						&::before {
							top: -10px;
							left: -10px;
						}

						&::after {
							bottom: -10px;
							left: 0;
						}
					}

					&.t2 {
						&::before {
							bottom: -15px;
							left: -20px;
						}

						&::after {
							bottom: -10px;
							left: 0x;
						}
					}

					&.t3 {
						&::before {
							z-index: 3;
							bottom: -10px;
							right: -5px;
						}

						&::after {
							z-index: 2;
							top: -13px;
							left: -15px;
						}
					}
				}

				.gtxt {
					color: $mColor1;
					margin: 60px 0 20px;
					font-size: 24px;
					font-weight: bold;
				}

				.text {
					line-height: 1.7em;
					font-size: 17px;
					font-weight: bold;
				}
			}
		}

		.block7 {
			margin-top: 50px;
			text-align: center;

			.center {
				margin-bottom: 50px;
			}

			.qalist {
				position: relative;
				z-index: 1;
				width: 90%;
				margin: 0 auto;

				&::before {
					content: "";
					display: block;
					position: absolute;
					z-index: 2;
					top: -60px;
					left: -45px;
					width: 122px;
					height: 81px;
					background: url("../image/drawing2.png") no-repeat;
					background-size: contain;
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					z-index: 2;
					bottom: -45px;
					right: -50px;
					width: 103px;
					height: 104px;
					background: url("../image/plum2.png") no-repeat;
					background-size: contain;
				}

				.item {
					border: 2px solid $mColor1;
					border-radius: 20px;
					padding: 30px 0;
					text-align: left;

					+ .item {
						margin-top: 30px;
					}
				}

				.question {
					position: relative;
					color: #fff;
					font-size: 24px;
					font-weight: bold;
					padding: 2px 70px;
					line-height: 1.5em;
					background-color: $mColor1;

					&::before {
						content: "";
						display: block;
						position: absolute;
						top: 9px;
						left: 30px;
						width: 29px;
						height: 39px;
						background: url("../image/Q.png") no-repeat;
					}
				}

				.answer {
					position: relative;
					font-size: 24px;
					font-weight: bold;
					padding: 0 70px;
					margin-top: 20px;
					line-height: 1.6em;

					&::before {
						content: "";
						display: block;
						position: absolute;
						top: 5px;
						left: 30px;
						width: 28px;
						height: 31px;
						background: url("../image/A.png") no-repeat;
					}
				}
			}
		}

		.block8 {
			position: relative;
			z-index: 1;
			margin-top: 90px;
			background-color: $mColor6;
			border-radius: 10px;
			padding: 30px;

			.table {
				width: 100%;
				overflow: hidden;
				overflow-x: auto;
			}

			table {
				background-color: transparent;

				&.list {
					th,
					td {
						white-space: nowrap;
					}

					tr {
						border-bottom: 1px solid $mColor1;
					}

					th {
						padding: 10px;
						font-size: 21px;
						font-weight: bold;
					}

					tbody {
						td {
							border: none;
							padding: 10px;
							font-size: 21px;
							font-weight: bold;
						}
					}
				}
			}

			.gline {
				margin-top: 10px;
			}

			.head {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.txt2 {
					height: 40px;
				}

				.note {
					font-size: 17px;
					font-weight: bold;
				}
			}

			.foot {
				margin: 10px 285px 0 0;
				font-size: 17px;
				font-weight: bold;
				line-height: 1.7em;
			}

			.wrap {
				position: absolute;
				z-index: 2;
				bottom: -240px;
				right: 15px;
				max-width: 276px;
			}
		}

		.block9 {
			margin-top: 50px;
			text-align: center;

			.center {
				margin-bottom: 50px;
			}

			.wrap {
				width: 80%;
				margin: 0 auto;
			}

			.col2 {
				display: flex;
				justify-content: space-between;
				text-align: center;

				.item {
					width: calc(50% - 20px);
				}
			}

			.icon {
				width: 120px;
				height: 120px;
				margin: 0 auto 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $mColor6;
				border-radius: 50%;

				img {
					width: 50%;
					height: 50%;
				}
			}

			.gtxt {
				font-size: 21px;
				font-weight: bold;
				color: $mColor1;
			}

			.text {
				font-size: 17px;
				font-weight: bold;
			}

			.caption {
				width: 80%;
				margin: 50px auto 0;
				text-align: left;

				.gtxt {
					font-size: 17px;
				}

				.txt {
					line-height: 1.8em;
					font-weight: bold;
				}
			}
		}

		.block10 {
			margin-top: 50px;
			background-color: $mColor6;
			border-radius: 10px;
			padding: 30px;
			text-align: center;

			.title {
				max-width: 100%;
				max-height: 35px;
			}

			.note {
				font-size: 14px;
				color: $mColor3;
				line-height: 2em;
			}
		}
	}

	.related {
		.related-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.text {
				font-size: 36px;
				font-weight: bold;
				color: $mColor3;
				padding-bottom: 5px;
				background: url("../image/wavypattern.png") bottom repeat-x;
			}
		}

		.related-list {
			position: relative;
			margin-top: 30px;

			.item {
				display: flex;
				flex-direction: column;
				max-width: 260px;

				.info {
					text-align: center;

					.name {
						color: $mColor3;
						font-weight: bold;
						font-size: 19px;
						margin-top: 23px;
					}

					.subname {
						color: $mColor4;
						font-size: 13px;
						margin-top: 11px;
					}

					.price-info {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-top: 18px;

						.special {
							background-color: $mColor5;
							padding: 0 2px;
							color: #fff;
							margin-right: 6px;
						}

						.price {
							color: $mColor5;
							font-size: 21px;
							font-weight: bold;
						}
					}
				}

				&:hover {
					.info {
						.name {
							color: $mColor1;
						}
					}
				}
			}

			.swiper-container {
				max-width: 1160px;
			}

			.swiper-slide {
				overflow: hidden;

				&:hover {
					border-color: $mColor1;
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				position: absolute;
				z-index: 2px;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px;
				height: 100%;
				background-image: none;
				color: $mColor1;
				margin: 0;

				svg {
					width: 40px;
					height: 40px;
				}

				&.swiper-button-disabled {
					opacity: 0;
				}
			}

			.swiper-button-prev {
				right: 0;
			}

			.swiper-button-next {
				left: auto;
			}
		}
	}

	@include rwd-width(1200) {
		padding: 60px;

		.desc-block {
			.block1 {
				.wrap {
					align-items: center;
					margin: -50px 0 0;

					.wrap1 {
						max-width: 250px;
					}

					.wrap2 {
						align-self: auto;
						margin: 0;
					}

					.wrap3 {
						max-width: 200px;
					}
				}
			}

			.block2,
			.block6 {
				.col3 {
					width: 100%;
				}
			}

			.block5 {
				.photo {
					.pleft,
					.pright {
						width: calc(50% - 20px);
					}

					.pleft {
						padding: 0;
					}
				}
			}
		}
	}

	@include rwd-width(1024) {
		padding: 50px 30px 30px;

		.desc-block {
			.block3 {
				.col2 {
					.item {
						width: calc(50% - 20px);
					}
				}
			}

			.block9 {
				.wrap {
					width: 100%;
				}

				.caption {
					width: 90%;
				}
			}
		}

		.related {
			.related-list {
				.item {
					.info {
						.price-info {
							.price {
								font-size: 17px;
							}
						}
					}
				}
			}
		}
	}

	@include rwd-width(900) {
		.info-wrap {
			.buttons {
				flex-wrap: wrap;

				.button {
					width: 100%;

					+ .button {
						margin: 20px 0 0;
					}
				}
			}
		}

		.desc-block {
			.block1 {
				.wrap {
					.wrap1 {
						max-width: 150px;
					}

					.wrap2 {
						display: flex;
						flex-direction: column;
						align-items: center;

						.txt1 {
							height: 40px;
						}
					}

					.wrap3 {
						max-width: 150px;
					}
				}
			}

			.block2 {
				.col2 {
					flex-wrap: wrap;

					.text {
						max-width: none;
					}

					.item {
						width: 100%;

						&.image {
							display: flex;
							justify-content: center;
						}

						+ .item {
							margin-top: 30px;
						}
					}
				}

				.col3 {
					.item {
						width: calc(33.33% - 30px);
					}
				}
			}

			.block3 {
				margin-top: 80px;

				.col2 {
					flex-wrap: wrap;

					.text {
						max-width: none;
					}

					.wrap {
						top: -85px;
					}

					.item {
						width: 100%;

						&.image {
							display: flex;
							justify-content: center;
						}

						+ .item {
							margin-top: 100px;
						}
					}
				}
			}

			.block5 {
				.wrap {
					flex-direction: column;

					.wrapinfo {
						margin: 40px 0 0;
					}
				}
			}

			.block6 {
				.col3 {
					.item {
						width: calc(33.33% - 30px);
					}
				}
			}

			.block8 {
				.foot {
					margin-right: 200px;
				}

				.wrap {
					bottom: -130px;
					max-width: 200px;
				}
			}
		}
	}

	@include rwd-width(768) {
		background-position: 99% -0.5%, 112% 20%;

		.info-wrap {
			flex-wrap: wrap;

			.ileft,
			.iright {
				width: 100%;
			}

			.iright {
				margin-top: 30px;
			}
		}

		.desc-title {
			margin-top: 120px;
		}

		.desc-block {
			.block1 {
				.image .tag {
					top: 20px;
					right: 20px;
				}
			}

			.block2,
			.block6 {
				.col3 {
					flex-direction: column;
					align-items: center;

					.item {
						width: 100%;

						.image {
							max-width: 320px;
							margin: 0 auto;
						}

						+ .item {
							margin-top: 50px;
						}
					}
				}
			}

			.block4,
			.block9 {
				.icon {
					width: 230px;
					height: 230px;
				}

				.col2 {
					flex-direction: column;

					.item {
						width: 100%;

						+ .item {
							margin-top: 30px;
						}
					}

					.col2 {
						flex-direction: row;

						.item {
							width: calc(50% - 20px);

							+ .item {
								margin-top: 0;
							}
						}
					}
				}
			}

			.block5 {
				.photo {
					.pright {
						.col2 {
							margin: -20px 0 0 -20px;

							.item {
								width: calc(50% - 20px);
								margin: 20px 0 0 20px;
							}
						}
					}
				}
			}

			.block7 {
				.qalist {
					width: 100%;
					margin: 0;

					&::before {
						left: 10px;
					}

					&::after {
						width: 80px;
						height: 80px;
						right: 0;
					}
				}
			}

			.block9 {
				.caption {
					width: 100%;
				}
			}
		}
	}

	@include rwd-width(750) {
		padding-top: 200px;

		.desc-block {
			.block8 {
				.foot {
					margin-right: 0;
				}

				.wrap {
					display: none;
				}
			}
		}
	}

	@include rwd-width(650) {
		.desc-title .juicy {
			top: -27px;
			left: 10px;
			width: 81px;
			height: 118px;
		}

		.desc-block {
			.block1 {
				.image .tag,
				.wrap .wrap1,
				.wrap .wrap3 {
					display: none;
				}

				.wrap {
					justify-content: center;
					margin-top: 20px;
				}
			}

			.block4 {
				.center {
					text-align: left;
				}
			}

			.block5 {
				.wrap .wrapinfo .slogan {
					flex-wrap: wrap;
					justify-content: center;
				}

				.photo {
					flex-wrap: wrap;

					.pleft,
					.pright {
						width: 100%;
					}

					.pright {
						margin-top: 40px;
					}
				}
			}

			.block4,
			.block9 {
				.icon {
					width: 150px;
					height: 150px;
				}
			}

			.block7 .qalist {
				.question,
				.answer {
					padding: 2px 20px 2px 70px;
				}
			}

			.block8 {
				.head {
					flex-direction: column;
				}

				table.list {
					th {
						font-size: 19px;
					}

					tbody td {
						font-size: 17px;
					}
				}
			}
		}
	}
	@include rwd-width(480) {
		.desc-block {
			.block4 {
				.gtxt {
					font-size: 16px;
				}
				.text {
					font-size: 19px;
				}
			}
		}
	}
	@include rwd-width(425) {
		padding: 180px 15px 30px;

		.desc-block {
			.block5 .wrap .slogan .item {
				width: 134px;
				height: 130px;
				font-size: 26px;
			}

			.block10 {
				padding: 20px 10px;
			}
		}
	}
}
