/* 主框架 */
body {
	max-width: 1920px;
	margin: 0px auto;
}

.restrict {
	width: 100%;
	max-width: 1640px;
	padding: 0px;
	margin: 0px auto;

	@include rwd-width(1680) {
		margin: 0;
		padding: 0 40px;
	}

	@include rwd-width(750) {
		padding: 0 20px;
	}

	@include rwd-width(425) {
		padding: 0 20px;
	}
}

#tpl-template {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	opacity: 0.5;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

#header {
	position: fixed;
	z-index: 11;
	width: 100%;
	margin: 0 auto;
	height: 90px;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	transition: top 0.3s, height 0.3s;
	padding:20px 0;
	background-color: #fff;
	/*box-shadow:  0px 50px 80px rgba(255, 255, 255, 0.7);
	border-bottom:1px solid #eee;*/
	.header-wrap {
		position: relative;
		display: flex;
		margin: 0 auto;
		width: calc(100% - 60px);
		max-width: 1640px;
		justify-content: space-between;
	}

	.menus {
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		width:calc(100% - 210px - 300px);
		.item {
			margin: 0 30px;
			a { letter-spacing: 0;}
		}
	}

	.submenus {
		display:none;
	} 

	#logo {
		flex: none;
		display: flex;
		align-items: center;
		margin: 100px;
		color: $mColor4;

		svg {
			width: 145px;
			height: 157px;
		}
	}

	#menu {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1245px;
		margin: 0 auto;

		.item {
			position: relative;
			z-index: 1;

			.child {
				position: absolute;
				z-index: 1;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				padding-top: 50px;
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.5s;

				.child-wrap {
					position: relative;
					border-radius: 10px;
					background-color: #fff;
					padding: 10px;
					display: flex;
					flex-direction: column;
					align-items: center;
					min-width: 126px;
					filter: drop-shadow(0px -1px 5px rgba(200, 200, 200, 0.1));

					&::before {
						content: "";
						position: absolute;
						top: -10px;
						left: 50%;
						transform: translateX(-50%);
						display: block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 7.5px 12px 7.5px;
						border-color: transparent transparent #ffffff transparent;
					}
				}

				.link {
					padding: 5px 0;
					font-size: 13px;
					text-underline-offset: 3px;
					white-space: nowrap;

					&:hover {
						color: $mColor1;
						text-decoration: underline;
					}
				}
			}

			.name {
				position: relative;
				z-index: 2;
				padding: 0 10px;
				border-radius: 20px;
				min-width: 140px;
				height: 40px;
				font-size: 18px;
				margin: 0 5px;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					background-color: $mColor1;
					color: #fff;
				}
			}

			&:hover {
				.child {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.block {
		position: relative;
		display: flex;
		align-items: center;
		a {
			display: flex;
			align-items: center;
			padding: 8px 14px;
			transition: 0.3s 0s ease;
			letter-spacing: 0;
			+ a {
				margin-left: 20px;
			}
			&.btn{
				border: 1px solid $mColor4;
				background-color: #fff;
				color:$mColor4;
				font-weight: bold;
				svg {
					width: 20px;
					height: 20px;
					margin-left: 14px;
				}
				&:hover{
					background-color: $mColor4;
					color:#fff;
				}
			}
		}

		.cart-total {
			color: #fff;
			background-color: #c79967;
			border-radius: 5px;
			margin-left: 5px;
			padding: 0 4px;
		}

		.menu-button {
			position: relative;
			display: none;
			height: 25px;
			width: 35px;
			overflow: hidden;
			cursor: pointer;

			.bar,
			.text,
			&::before,
			&::after {
				position: absolute;
				left: 0;
				width: 100%;
				height: 5px;
				background-color: $mColor4;
				content: "";
				transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
			}

			.bar {
				bottom: 0;
			}

			.txt {
				top: 24px;
				font-size: 16px;
				&::before {
					content: "MENU";
					position: absolute;
					top: 5px;
					right: 0;
					left: 0;
					color: $mColor4;
					font-size: 12px;
					font-weight: bold;
					font-family: "Montserrat", Arial, Helvetica, sans-serif;
					text-align: center;
					letter-spacing: 0px;
				}
			}

			&::before {
				top: 0;
			}

			&::after {
				top: 10px;
			}

			&:hover {
				&::before {
					left: -39px;
				}

				&::after {
					left: 39px;
				}

				.txt:before {
					animation: moveUpThenDown 0.8s ease 0.2s forwards, shakeWhileMovingUp 0.8s ease 0.2s forwards,
						shakeWhileMovingDown 0.2s ease 0.8s forwards;
				}
			}
			&.show{
				&::before {
					left: -39px;
				}

				&::after {
					left: 39px;
				}

				.txt:before {
					animation: moveUpThenDown 0.8s ease 0.2s forwards, shakeWhileMovingUp 0.8s ease 0.2s forwards,
						shakeWhileMovingDown 0.2s ease 0.8s forwards;
				}
			}

			@keyframes moveUpThenDown {
				0% {
					top: 0;
				}

				50% {
					top: -20px;
				}

				100% {
					top: -14px;
				}
			}

			@keyframes shakeWhileMovingUp {
				0% {
					transform: rotateZ(0);
				}

				25% {
					transform: rotateZ(-10deg);
				}

				50% {
					transform: rotateZ(0deg);
				}

				75% {
					transform: rotateZ(10deg);
				}

				100% {
					transform: rotateZ(0);
				}
			}

			@keyframes shakeWhileMovingDown {
				0% {
					transform: rotateZ(0);
				}

				80% {
					transform: rotateZ(3deg);
				}

				90% {
					transform: rotateZ(-3deg);
				}

				100% {
					transform: rotateZ(0);
				}
			}
		}
	}

	&.fixed {
		top: 0;
		height: 90px;
		background-color: #fff;
		backdrop-filter: blur(10px);
		box-shadow:none;
		border:none;
		#logo {
			margin: 50px 100px 0;

			svg {
				height: 105px;
			}
		}

		.block {
			bottom: 0;
		}
	}
	@include rwd-width(1400) {
		.menus .item {
			margin:0 15px;
		}
	}
	@include rwd-width(1245) {
		.menus .item {
			margin:0 10px;
		}
	}
	@include rwd-width(1100) {
		.menus .item {
			margin:0 6px;
		}
	}
	@include rwd-width(1040) {
		position: fixed;
		top: 0;
		height: 70px;
		background-color: #fff;
		padding: 10px 0;

		.menus .item {
			display: none;
		}

		.block {
			flex: none;
			position: relative;
			margin-left: 20px;
			
			a {
				&.btn{    padding: 3px 14px;}
				svg {
					width: 26px;
					height: 26px;
				}
			}

			.menu-button {
				display: block;
			}
		}
	}

	@include rwd-width(660) {
		.block {
			a{
				&.btn{
					.text{
					display: none;
					}
					svg {    margin-left: 0;}
				}
				+ a {
					margin-left: 10px;
				}
			}
		}
	}

	@include rwd-width(480) {
		height: 60px;
		padding: 10px 0;
		.header-wrap {
			width: calc(100% - 20px);
		}
		.logo {
			img { height: 40px;}
		}
	}
}

#menu-popup {
	position: fixed;
	z-index: 10;
	top: -100%;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #191E24f0;
	visibility: hidden;
	opacity: 0;
	transition: all 0.5s;
	height: 0;

	.block {
		position: relative;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 120px 20px 15px;
		max-width: 1300px;
		margin: 0 auto;
		height: 100%;
	}
	.control-block{
		display: flex;
		position: relative;
		justify-content: flex-start;
		height:40px;
		&::after{
			content: '';
			position: absolute;
			top:20px;
			right:0;
			width: calc(100%);
			border: 1px solid #666666;
			z-index: 1;
		}
		.back{
			display: none;
			align-items: center;
			justify-content: center;
			z-index: 2;
			border-radius: 20px;
			font-size: 14px;
			border:1px solid #fff;
			height: 38px;
			width: 120px;
			color: #fff;
			svg { margin-right: 10px;}
			&:hover{
				border:1px solid $mColor1;
				color:$mColor1;
			}
		}
		.close {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 38px;
			height: 38px;
			padding: 8px;
			border-radius: 50%;
			background-color: $mColor1;
			fill:#fff;
			color:#fff;
			z-index: 2;
			position: absolute;
			right: 0;
			border: 1px solid $mColor1;
			transition: all 0.5s;
			&:hover{
				background-color:#fff;
				fill:$mColor1;
				color:$mColor1;
			}
		}
	}

	.mtitle {
		width: 200px;
	}

	.menu-block {
		position: relative;
		top: 0;
		right: 0;
		left: 0;
		bottom: 60px;
		margin-top: 20px;
		border-radius: 10px;
		padding: 15px;
		overflow: hidden;
		overflow-y: auto;
		.title { color: $mColor1; font-size: 16px; font-weight: bold; padding: 12px 10px;}
		.menu-effect{
			position: relative;
			left: -100%;
			opacity: 0;
			transition: all 0.5s;
			transition-delay: 0.5s;
			height: 0;
			&.change{
				transition-delay: 0s;
			}
			&.m1 {
				max-width:330px;
			}
			&.mf {
				width: 100%;
				
				.mf_list {
					display: flex;
					.mf_item {
						width:calc(100% / 4); max-width: 330px; margin-bottom: 20px;
						.subitem{
							position: relative;
							z-index: 4;
							width: 100%;
							padding: 10px;
							transition: all 0.5s;
							overflow: hidden;
							opacity: 0;
							margin-top: -40px;
							&.open {
								opacity: 1;
								margin-top: 0;
							}
						} 
						.switch {
							position: relative;
							z-index: 5;
							svg { display: none;
								&:nth-child(1){ display: block;}
								&:nth-child(2){ display: none;}
							}
							&.open{
								svg {
									&:nth-child(1){ display: none;}
									&:nth-child(2){ display: block;}
								}
							}
						}
					}

					@include rwd-width(1040) {
						.mf_item { width:calc(100% / 2);     max-width: initial;}
					}
					@include rwd-width(600) {
						flex-wrap: wrap;
						.mf_item { width:calc(100%); }
					}
				}
			}
		}
		.item {
			padding: 12px 10px;
			font-weight: bold;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.name {
				font-size: 16px;
				color: #fff;
				text-decoration: underline;
				&.selected{
					color: $mColor1;
				}
				&:hover{
					color: $mColor1;
				}
			}
			.icon {
				font-size: 16px;
				color: #fff;
				fill:#fff;
				&.selected{
					color: $mColor1;
				}
				&:hover{
					color: $mColor1;
				}
			}

			.child {
				display: none;
				flex-direction: column;
				margin: 15px 0 0 25px;

				.link {
					&:hover,
					&.selected {
						color: $mColor1;
						text-decoration: underline;
						text-underline-offset: 5px;
					}

					+ .link {
						margin-top: 10px;
					}
				}
			}

			&.selected {
				.child {
					display: flex;
				}
			}
		}
	}

	&.show {
		opacity: 1;
		visibility: visible;
		top:0;
		height: 100%;
		.menu-block {
			.menu-effect{
				&.show{
					opacity: 1;
					left: 0;
					height: auto;
				}
			}
		}
	}
	@include rwd-width(1040) {
		.control-block{
			&.hb{
				&::after{
					width: calc(100% - 120px);
				}
				.back{ display: flex;}
			}
		}
		.block {
			padding: 100px 20px 15px;
		}
	}
	@include rwd-width(768) {
		.block {
			left: 0;
			max-width: none;
		}
	}
	@include rwd-width(480) {
		.block {
			padding: 90px 20px 15px;
		}
	}
}


.waveout {
position: relative;
	height: 25px;
	width: 100%;
	
	overflow: hidden;
	opacity: 0;
	bottom: 20px;


.wave,
.wave::before,
.wave::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	height: 200px;
	margin-left: -100px;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	background-image: -webkit-linear-gradient( rgba(82, 70, 67, 0.8), rgba(62, 50, 57, 0.8));
	background-image: linear-gradient( rgba(82, 70, 67, 0.8), rgba(62, 50, 57, 0.8));
	border-radius: 38% 42%;
	box-shadow: inset 0 0 10vw rgba(255, 255, 255, 0.5);
	-webkit-animation: spin 16s infinite linear;
	animation: spin 16s infinite linear;
}
.wave::before {
	width: 105%;
	height: 95%;
	margin-top: -100px;
	-webkit-transform-origin: 49% 51%;
	transform-origin: 49% 51%;
	border-radius: 40% 38%;
	-webkit-animation: spin 13s infinite linear;
	animation: spin 13s infinite linear;
}
.wave::after {
	width: 102%;
	height: 98%;
	margin-top: -100px;
	-webkit-transform-origin: 51% 49%;
	transform-origin: 51% 49%;
	border-radius: 48% 42%;
	-webkit-animation: spin 10s infinite linear;
	animation: spin 10s infinite linear;
}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.menu-button:hover .waveout{ opacity: 1;}
.menu-button.show .waveout{ opacity: 1;}


#footer {
	clear: both;
	position: relative;
	display: block;
	box-sizing: border-box;
	background-image: url('/upload/footerBg.jpg');
	background-repeat: no-repeat;
	background-position: center bottom;
	box-sizing: border-box;
	background-color: #514642;
	.ellipsis{
		overflow: hidden;
   		text-overflow: ellipsis;
   		white-space: nowrap;
	}
	.footer-wrap {
		position: relative;
		display: block;
		margin: 0 auto;
		width: calc(100% - 60px);
		max-width: 1640px;
	}

	.top-block {

		position: relative;
		display: block;
		padding: 50px 0 30px;;
		letter-spacing: 0;
		font-size: 0;
		box-sizing: border-box;

		.main-block {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 345px;
			.companys {
				position: relative;
				display: block;
				margin-top: 25px;
			}
			.powered {
				font-size: 14px;
				position: relative;
				display: block;
				margin-top: 5px;
				line-height: 2;
				letter-spacing: 1px;
				color: #D1C8C6;
				.text {  
					display: flex;
					
					span {
						color: #D1C8C6;
						&:nth-child(1){
							color: $mColor1;
							margin-right: 12px;
						}
					}
					
					a {
						color: #D1C8C6;
						transition: .3s 0s ease;
						&:hover{
							color: $mColor1;
						}
					}
				}
			}
			.share {
				display: flex;
				color: #fff;
				margin-top:5px;
				.shareBar {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					border-radius: 50%;
		
					.svg {
						width: 30px;
						height: 30px;
					}
		
					&:hover {
						color: #fff;
		
						&.yt {
							background-color: $ytcolor;
						}
		
						&.fb {
							background-color: $fbcolor;
						}
		
						&.line {
							background-color: $linecolor;
						}
		
						&.twitter {
							background-color: $twcolor;
						}
		
						&.ig {
							background: url("../image/igBg.jpg") no-repeat;
						}
					}
				}
			}
		}
		
				
		.menu-block {
			position: relative;
			display: inline-block;
			vertical-align: top;
			padding-top: 20px;
			width: calc(100% - 345px);
			text-align: center;

			.holder {
				position: relative;
				display: inline-block;
				width: 100%;
				max-width: 1080px;
			}
	
			.menu-ul {
				display: -webkit-flex;
				display: flex;
				-webkit-justify-content: space-between;
				justify-content: space-between;
				&>li {
					width: 155px;
					.topic {
						display: block;
						text-align: center;
						line-height: 27px;
						letter-spacing: 1px;
						font-size: 18px;
						font-weight: bold;
						color: $mColor2;
						transition: color .3s 0s ease;
						&+.topic {
							margin-top: 15px;
						}
						&:hover { color: $mColor2; text-decoration: underline;}
						&.selected { color: $mColor2; text-decoration: underline;}
						
					}

					.childs {
						position: relative;
						display: block;
						margin-top: 15px;
						overflow: hidden;
						.child-ul {
							margin-top: -5px;
							&>li {
								margin-top: 5px;
								width: 100%;
								.link {
									position: relative;
									display: block;
									text-align: center;
									line-height: 30px;
									letter-spacing: 1px;
									font-size: 16px;
									color: #D1C8C6;
									transition: color .3s 0s ease;
								}
								
								.link:hover {
									color: $mColor1;
									text-decoration: underline
								}
								
								.link.selected {
									color: $mColor1;
									text-decoration: underline;
								}
								
								.link.selected:before {
									content: "";
									display: inline-block;
									vertical-align: 3px;
									margin-right: 5px;
									width: 5px;
									height: 5px;
									border: 1px $mColor2 solid;
									border-radius: 50%;
									box-sizing: border-box;
								}
							}
						}
					}
				}
			}
		}
	}



	

	.bot-block {
		position: relative;
		display: flex;
		padding: 10px 0 20px;
		box-sizing: border-box;
		justify-content: center;
		color: $mColor2;
		
		.copyright {
			display: flex;
			justify-content: center;
			align-items: center;
			color: $mColor2;
			span {
				color: $mColor1;	
				font-weight: bold;
			}
			div {
				text-align: center;
			}
		}
	}

	@include rwd-width(1220) {
		.bot-block {
			.copyright {
				flex-wrap: wrap;
			}
		}
	}
	@include rwd-width(1160) {
		.top-block {
			.main-block {
				.share {
					width: 100%;
				}
			}
		}
	}
	@include rwd-width(900) {
		.top-block {
			padding: 50px 0 10px;
			
			.main-block {
				margin: 0 auto;
				width: 100%;
				display: flex;
				justify-content: space-between;

				.logo {
					display: block;
					text-align: center;
				}
				.share{
					justify-content: flex-start;
					position: absolute;
					left:0;
					bottom:0;
				}
				.companys {
					margin-top: 0;
					text-align: center;
					margin-left:20px;
					.powered {
						margin-left: 0;
						.text {
							margin-left: 0;
							width: auto;
							text-align: left;
						}
					}
				}
			}
			.menu-block {
				display: block;
				width: 100%;
				.menu-ul{
					justify-content: space-between;
					&>li {
						width: auto;
					}
				}
			}
		}
	}

	@include rwd-width(660) {
		
		.top-block {
			padding: 50px 0 10px;
			.main-block {
				display: block;

				.logo {
					display: block;
					text-align: center;
				}
				.share{
					justify-content: center;
					position: relative;
					left:auto;
					bottom:auto;
				}
				.companys {
				    margin-top: 25px;
					margin-left: 0;
					.powered {
						.text {
							justify-content: center;
						}
					}
				}
				.links {
					display: block;
					width: 100%;
					height: auto;
					.link-ul {
						text-align: center;
					}
				}
			}
			.menu-block {
				display: none;
				margin: 0 auto;
				max-width: 150px;
				.menu-ul{
					justify-content: space-between;
					&>li {
						width: auto;
					}
				}

				.childs {
					display: none;
				}
			}
		}
	}

	@include rwd-width(425) {
		.bot-block {
			.copyright {
				font-size: 14px;
			}
		}
	}
}

#footer-bar {
	position: fixed;
	z-index: 14;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	justify-content: space-around;
	align-items: center;
	height: 60px;
	background: #e9f2e5;

	.item {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 14px;

		svg {
			width: 18px;
			height: 18px;
			margin-bottom: 5px;
		}

		&:hover {
			color: $mColor1;
		}
	}

	@include rwd-width(425) {
		display: flex;

		+ #footer {
			margin-bottom: 60px;
		}
	}
}

#container {
	position: relative;
	z-index: 0;
	overflow: hidden;
	padding: 90px 0 90px;
	
	&.home {
		background-repeat: no-repeat;
	}


	@include rwd-width(1440) {
		background-position: center top;


	}

	@include rwd-width(1200) {

	}

	@include rwd-width(1024) {
		padding-top: 70px;

	}

	@include rwd-width(900) {

	}

	@include rwd-width(780) {

	}

	@include rwd-width(560) {

	}

	@include rwd-width(425) {

	}
}

#content {
	min-height: 1100px;
}

/* 進度條 */
.progress-model {
	position: fixed;
	z-index: 9999;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;

	.progress-wrap {
		width: 480px;
		background-color: white;
		padding: 5px;
		border-radius: 5px;
		flex-direction: column;

		.progress-text {
			line-height: 20px;
			min-height: 24px;
			text-align: center;
		}
	}
}

// 文章共用格式
.art-desc {
	img {
		max-width: 100%;
		width: auto !important;
		height: auto !important;
		margin: 0px auto;
		display: block;
	}

	.item {
		position: relative;
		width: 100%;

		img {
			width: auto !important;
			height: auto !important;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}

		ul,
		ol {
			padding-left: 30px;
		}

		.title {
			color: $mColor1;
			font-size: 21px;
			font-weight: bold;
			line-height: 1.2em;

			+ .txt {
				margin-top: 35px;
			}

			+ .col2 {
				margin-top: 20px;
			}
		}

		.txt {
			line-height: 2em;
			word-break: break-all;

			&.letter:first-letter {
				color: $mColor1;
				font-size: 32px;
				font-weight: bold;
			}

			+ .title {
				margin-top: 35px;
			}
		}

		&.text-center {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}

		&.text-right {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			text-align: right;
		}

		&.image.border {
			position: relative;
			width: auto;
			display: inline-flex;

			img {
				position: relative;
				z-index: 2;
				max-width: calc(100% - 15px);
				margin-left: 0;
			}

			&::before {
				content: "";
				position: absolute;
				z-index: 1;
				top: 20px;
				left: 15px;
				width: calc(100% - 20px);
				height: calc(100% - 10px);
				display: block;
				border: 5px solid $mColor1;
			}
		}

		&.iframe::before {
			content: "";
			display: block;
			padding-top: 56.25%;
		}

		+ .item,
		+ .col2,
		+ .col3,
		+ .icon-title {
			margin-top: 45px;
		}
	}

	.icon-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 2px solid $mColor1;
		padding-bottom: 5px;

		svg {
			width: 36px;
			height: 36px;
			margin-left: 20px;
		}

		.it-title {
			font-size: 18px;
			font-weight: bold;
			margin: 0 20px;
		}

		.it-subtitle {
			color: $mColor1;
			font-size: 18px;
			font-weight: bold;
		}

		+ .item,
		+ .col2,
		+ .col3 {
			margin-top: 15px;
		}
	}

	.cktime {
		position: relative;
		margin-top: 90px;
		background-color: #f0f5ee;
		padding: 20px 20px 10px;
		border-radius: 10px;

		.cooktime {
			position: absolute;
			top: -40px;
			right: 0;
			width: 360px;
			height: 43px;
			fill: #f0f5ee;
		}

		.cookroom {
			display: block;
			width: 191px;
			height: 34px;
			margin: 0 auto;
			fill: $mColor1;
		}

		.cookline {
			width: 100%;
			height: 6px;
			margin-top: 10px;
			fill: $mColor1;
		}

		.cookdesc {
			margin-left: 20px;
			line-height: 1.3em;
			letter-spacing: 1.2px;

			li + li {
				margin-top: 10px;
			}
		}
		.doctor {
			position: absolute;
			right: 15px;
			bottom: 0;
			@include rwd-width(1440) {
				&.hide1440 {
					display: none;
				}
			}
			@include rwd-width(1280) {
				&.hide1280 {
					display: none;
				}
			}
			@include rwd-width(1024) {
				&.hide1024 {
					display: none;
				}
			}
			@include rwd-width(768) {
				&.hide768 {
					display: none;
				}
			}
			@include rwd-width(600) {
				&.hide600 {
					display: none;
				}
			}
			@include rwd-width(480) {
				&.hide480 {
					display: none;
				}
			}
		}

		@include rwd-width(460) {
			.cooktime {
				top: -30px;
				left: 0;
				margin: 0 auto;
				width: 288px;
				height: 35px;
			}
		}
	}

	.col2 {
		display: flex;
		justify-content: space-between;

		&.center {
			align-items: center;
		}

		.item {
			width: calc(50% - 40px);

			&.image.border {
				display: block;
				width: calc(50% - 40px);
			}

			+ .item {
				margin: 0;
			}
		}

		+ .item,
		+ .col2,
		+ .col3,
		+ .icon-title {
			margin-top: 45px;
		}

		.col2 {
			.item {
				width: calc(50% - 10px);
			}
		}
	}

	.col3 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: -15px 0 0 -15px;

		&.center {
			align-items: center;
		}

		.item {
			width: calc(33.33% - 15px);
			margin: 15px 0 0 15px;
		}

		+ .item,
		+ .col2,
		+ .col3,
		+ .icon-title {
			margin-top: 45px;
		}
	}

	@include rwd-width(1440) {
		.col2 {
			.col2 {
				flex-direction: column;

				.item {
					width: 100%;

					+ .item {
						margin-top: 30px;
					}
				}
			}
		}
	}

	@include rwd-width(900) {
		.item {
			&.image.border {
				width: auto;
				margin-bottom: 45px;
			}
		}

		.col2 {
			flex-wrap: wrap;
			justify-content: center;

			.item {
				order: 2;
				width: 100%;

				+ .item {
					margin-top: 30px;
				}

				&.image {
					order: 1;
					margin: 0 0 30px;

					&.border {
						width: auto;
						display: inline-flex;
						margin-bottom: 60px;
					}
				}
			}

			+ .col2 {
				margin-top: 30px;
			}
		}

		.col3 {
			justify-content: flex-start;

			.item {
				width: calc(50% - 15px);
			}
		}
	}

	@include rwd-width(500) {
		.col3 {
			margin: 0;

			.item {
				width: 100%;
				margin: 15px 0 0;
			}
		}
	}
}

/* 訊息通知 */
#notify-model {
	position: fixed;
	z-index: 1005;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	#notify {
		position: fixed;
		z-index: 1006;
		top: 15%;
		left: 0;
		right: 0;
		margin: auto;
		display: inline-flex;
		justify-content: center;
		filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
		opacity: 0;
		visibility: hidden;
		transition: all 0.4s;
		max-width: 900px;
		max-height: 70%;
		width: calc(100% - 20px);

		.notify-wrap {
			position: relative;
		}

		.close {
			position: absolute;
			right: -10px;
			top: -10px;
			width: 24px;
			height: 24px;
			color: useGray(12);
			background-color: white;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 20px;
				height: 20px;
			}
		}

		.message {
			margin: 0px auto;
			padding: 8px 15px;
			line-height: 24px;
			border-radius: 4px;
			border: 5px solid useGray(12);
			background-color: white;
			max-width: 900px;
			word-break: break-all;
			max-height: 100%;
			overflow: auto;
		}

		&.attention {
			.message {
				color: $yellow;
				border-color: $yellow;
			}

			.close {
				display: none;
			}
		}

		&.success {
			.message,
			.close {
				color: $green;
				border-color: $green;
			}
		}

		&.warning {
			.message,
			.close {
				color: $red;
				border-color: $red;
			}
		}

		&.show {
			visibility: visible;
			opacity: 1;
		}
	}

	&.show {
		visibility: visible;
		opacity: 1;
	}

	&.delay {
		transition-delay: 0.4s;
	}
}

/* 彈出視窗 */
.overlay {
	position: fixed;
	z-index: 999;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	margin: 0px;
	opacity: 0;
	visibility: hidden;
	background-color: #00000066;
	transition: all 0.4s;

	.popup {
		position: absolute;
		z-index: 1000;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		margin: auto;
		max-width: 1000px;
		max-height: 750px;
		opacity: 0;
		visibility: hidden;
		background-color: white;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		transition: all 0.4s;
		overflow: hidden;
		border-radius: 5px;

		.close {
			position: absolute;
			z-index: 10;
			right: 10px;
			top: 15px;
			width: 40px;
			height: 40px;
			opacity: 0.3;

			&:before,
			&:after {
				position: absolute;
				left: 20px;
				content: " ";
				height: 40px;
				width: 2px;
				background-color: useGray(6);
				transition: transform 0.5s;
			}

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}

			&:hover {
				opacity: 1;
				cursor: pointer;

				&:before {
					background-color: $mColor1;
					transform: rotate3d(0, 0, 1, 225deg);
				}

				&:after {
					background-color: $mColor1;
					transform: rotate3d(0, 0, 1, 135deg);
				}
			}
		}

		.contenter {
			overflow-x: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			height: 100%;
		}

		&.show {
			visibility: visible;
			opacity: 1;
		}

		@include rwd-width(1040) {
			margin-left: 20px;
			margin-right: 20px;
			max-width: none !important;
		}

		@include rwd-width(768) {
			border-radius: 0;
			margin: 0px;
			max-width: none !important;
			max-height: none !important;
		}

		@include rwd-height(425) {
			margin-top: 0px;
			margin-bottom: 0px;
		}
	}

	.mCSB_scrollTools {
		.mCSB_draggerRail {
			width: 7px;
		}

		.mCSB_dragger .mCSB_dragger_bar {
			width: 5px;
		}
	}

	&.show {
		visibility: visible;
		opacity: 1;
	}

	&.delay {
		transition-delay: 0.4s;
	}
}

/* 麵包屑 */
.breadcrumb {
	font-size: 14px;
	color: #fff;

	a:hover {
		color: $mColor1;
	}
}

/* 表單元件 */
.uiform {
	.item {
		.label {
			flex: none;
			display: flex;
			align-items: center;
			min-width: 90px;

			svg {
				margin-right: 5px;
			}

			&.required::after {
				content: "*";
				color: $mColor1;
				margin-left: 5px;
			}
		}

		.input {
			position: relative;
			height: 40px;
			border-bottom: 1px solid useGray(15);
			display: flex;
			align-items: center;

			input {
				flex: auto;
				height: 100%;
				border: none;
				min-width: 0;
			}

			&.radio,
			&.checkbox {
				border-bottom: none;

				.wrap {
					display: flex;

					+ .wrap {
						margin-left: 15px;
					}
				}

				input {
					height: 15px;
					width: 15px;
				}

				.label {
					min-width: auto;

					+ .wrap {
						margin-left: 15px;
					}
				}
			}

			&:hover,
			&:focus {
				border-bottom-color: $mColor1;
			}

			&.rb {
				border-bottom-color: #e80009;
			}
		}

		.textarea {
			display: flex;
			align-items: center;

			textarea {
				flex: auto;
				min-height: 150px;
				border: none;
				min-width: 0;
				background-color: $mColor6;
			}
		}

		.select {
			display: flex;
			justify-content: space-between;
			height: 40px;

			select {
				flex: auto;
				border: none;
				border-bottom: 1px solid useGray(15);
				height: 100%;

				&.rb {
					border-bottom-color: #e80009;
				}

				+ select {
					margin-left: 20px;
				}
			}
		}

		.value {
			height: 40px;
			display: flex;
			align-items: center;
			word-break: break-all;
			padding-bottom: 5px;
			border-bottom: 1px solid useGray(15);
		}

		&.captcha {
			.label {
				letter-spacing: 9px;
			}
			.refresh_captcha {
				height: 100%;

				img {
					max-height: 100%;
				}
			}
		}

		+ .item {
			margin-top: 20px;
		}

		&.col2 {
			display: flex;
			justify-content: space-between;
			margin: 40px 0;
		}
	}

	.wrap-col2 {
		display: flex;
		flex-wrap: wrap;
		margin: -20px 0 0 -40px;

		.item {
			width: calc(50% - 40px);
			margin: 20px 0 0 40px;
		}

		+ .wrap-col2 {
			margin-top: 0;
		}

		@include rwd-width(650) {
			margin: 0;

			.item {
				width: 100%;
				margin: 20px 0 0 0;
			}
		}
	}
}

/* 分頁 */
.pagination {
	margin-top: 60px;
	display: flex;
	justify-content: center;
	width:100%;
	
	b,
	a:hover {
		color: $mColor1;
		text-decoration: underline;
		text-underline-offset: 8px;
	}

	a,
	b {
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		text-decoration: none;
		font-size: 17px;

		+ a,
		+ b {
			margin-left: 10px;
		}
	}
	.arrow{
		&:first-child,
		&:last-child {
			color: #fff;
			background-color: $mColor4;
		}
	}
	a.arrow{
		&:hover{
			&:first-child,
			&:last-child {
				color: #fff;
				background-color: $mColor1;
			}
		}
	}

	svg {
		width: 30px;
		height: 30px;
	}
}

/* 分享列表 */
.share-list {
	display: flex;
	align-items: center;
	margin-left: 10px;
	.share-text {
		color: useGray(12);
	}

	hr {
		margin: 10px;
		width: 15px;
		height: 1px;
		background-color: useGray(12);
		border: 0;
	}

	.shareBar {
		display: flex;
		align-items: center;
		border-radius: 17.5px;
		transition: background-color 1s;
		color: useGray(12);

		.icon {
			width: 35px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.txt {
			max-width: 0;
			overflow: hidden;
			transition: max-width 0.5s;
		}

		&:hover {
			color: #fff;

			.txt {
				max-width: 80px;
				margin-right: 15px;
			}

			&.fb {
				background-color: $fbcolor;
			}

			&.yt {
				background-color: $ytcolor;
			}

			&.line {
				background-color: $linecolor;
			}

			&.twitter {
				background-color: $twcolor;
			}
		}

		& + .shareBar {
			margin-left: 10px;
		}
	}

	&.vt {
		flex-direction: column;
		align-items: flex-start;

		.shareBar {
			color: #fff;

			svg {
				width: 24px;
				height: 24px;
			}

			.txt {
				max-width: 80px;
				margin-right: 15px;
			}

			&.fb {
				background-color: $fbcolor;
			}

			&.yt {
				background-color: $ytcolor;
			}

			&.line {
				background-color: $linecolor;
			}

			&.twitter {
				background-color: $twcolor;
			}

			&.ig {
				background: url("../image/igBg.jpg") no-repeat;
			}

			& + .shareBar {
				margin-left: 0;
				margin-top: 15px;
			}
		}
	}

	&.autowrap {
		flex-wrap: wrap;

		.share-text {
			width: 100%;
		}

		.bars-list {
			display: flex;
			flex-wrap: wrap;
			padding-top: 20px;
			margin: -20px 0 0 -20px;
		}

		.shareBar {
			color: #fff;
			margin: 20px 0 0 20px;

			svg {
				width: 24px;
				height: 24px;
			}

			.txt {
				max-width: 80px;
				margin-right: 15px;
			}

			&.fb {
				background-color: $fbcolor;
			}

			&.yt {
				background-color: $ytcolor;
			}

			&.line {
				background-color: $linecolor;
			}

			&.twitter {
				background-color: $twcolor;
			}

			&.ig {
				background: url("../image/igBg.jpg") no-repeat;
			}

			& + .shareBar {
				margin: 20px 0 0 20px;
			}
		}
	}
}
