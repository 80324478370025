@use "sass:math";

// 基礎色
$red: #e80009;
$green: #589f35;
$yellow: #f6b81b;
$blue: #1d50a2;

// 社群平台顏色
$fbcolor: #3b5997;
$linecolor: #00ba01;
$twcolor: #1da1f2;
$ytcolor: #ff0000;

// 主色
$mColor1: #ED6B28;
$mColor2: #fff;
$mColor3: #482e23;
$mColor4: #524643;
$mColor5: #ffa200;
$mColor6: #f0f5ee;

$ellLen: 3; // ellipsis 多列上限
$family: "Microsoft JhengHei", sans-serif, Arial, Helvetica;

// 自訂字型
@font-face {
	font-family: "irnfont";
	src: url("/upload/font.woff2") format("woff2");
}

// 灰階色
$grays: ();
$maxstep: 20;
$step: math.div(100, $maxstep);

@for $i from 1 to $maxstep {
	$grays: append($grays, change-color(#000, $lightness: $i * $step * 1%));
}

@function useGray($index: 8) {
	@return nth($grays, $index);
}

@function showGray() {
	@debug "----------";
	$i: 1;

	@each $gray in $grays {
		@debug $i $gray;
		$i: $i (+1);
	}

	@return "----------";
}

// RWD 設定
@mixin rwd-width($px, $above: true) {
	@if $above {
		@media (max-width: #{$px}px) {
			@content;
		}
	} @else {
		@media (max-width: #{$px - 1}px) {
			@content;
		}
	}
}

@mixin rwd-height($px, $above: true) {
	@if $above {
		@media (max-height: #{$px}px) {
			@content;
		}
	} @else {
		@media (max-height: #{$px - 1}px) {
			@content;
		}
	}
}

@import "_reset";
@import "_base";
@import "_layout";
@import "_model";
@import "_article";
@import "_product";
@import "_account";
@import "_cart";
