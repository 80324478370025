/* HTML 標籤 */
* {
	outline: none;
}

body {
	font-size: 15px;
	background-color: #F5F4F3;
	color: useGray(6);
	letter-spacing: 1px;
	visibility: visible;
	color: $mColor4;
	&.fixed {
		overflow: hidden;
	}
}

a {
	text-decoration: none;
	cursor: pointer;
	font-size: inherit;
	color: inherit;
	letter-spacing: 1px;

	&.selected,
	&:hover {
		color: $mColor1;
	}
}

input {
	outline: 0;
	font-size: 1em;

	&[type="text"],
	&[type="password"],
	&[type="date"],
	&[type="email"],
	&[type="number"] {
		padding: 0px 5px;
		border: 1px solid useGray(17);
		vertical-align: top;
		border-radius: 0;
	}

	&[type="radio"],
	&[type="checkbox"] {
		cursor: pointer;
		vertical-align: -2px;
		width: 15px;
		height: 15px;
	}

	&[type="number"] {
		appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}

select {
	padding: 2px;
	border: 1px solid useGray(17);
	cursor: pointer;
	vertical-align: middle;
	outline: 0;
	background-color: #fff;
	border-radius: 0;
	font-size: 15px;
}

textarea {
	font-size: 1em;
	padding: 5px;
	resize: none;
	border: 1px solid useGray(17);
	outline: 0;
	border-radius: 0;
}

label {
	cursor: pointer;
}

table {
	width: 100%;
	background-color: #fff;

	&.list {
		td {
			padding: 5px;
			line-height: 1.6em;

			&.center {
				text-align: center;
			}

			&.right {
				text-align: right;
			}

			&.wd100 {
				width: 100px;
			}

			&.wd150 {
				width: 150px;
			}

			&.wd200 {
				width: 200px;
			}
		}

		thead {
			td {
				color: useGray(6);
				font-weight: bolder;
				background-color: useGray(18);
			}
		}

		tbody {
			td {
				border-bottom: 1px solid useGray(17);
			}

			tr:hover {
				background-color: useGray(19);
			}
		}
	}
}

svg {
	width: 20px;
	height: 20px;
}

.img_captcha {
	cursor: pointer;
}

.empty {
	margin-left: 20px;
}

.hide {
	display: none !important;
}

.main-color {
	color: $mColor1;
}

.small {
	font-size: 13px;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: normal;
	word-break: break-word;

	@for $i from 1 through $ellLen {
		&-#{$i} {
			@extend .ellipsis;

			-webkit-line-clamp: $i;
		}
	}
}

.red,
.error {
	color: $red;
}

.green {
	color: $green;
}

.buttons {
	display: flex;
	flex-wrap: wrap;

	.button {
		cursor: pointer;
		color: $mColor1;
		background-color: #fff;
		border: 1px solid $mColor1;
		font-size: 17px;
		font-weight: bolder;
		padding: 13px 20px;
		text-align: center;
		transition: color 0.5s, background-color 0.5s, border-color 0.5s;

		&:hover {
			color: #fff;
			background-color: $mColor1;
		}

		&.gray {
			color: useGray(6);
			border-color: useGray(19);
			background-color: useGray(19);

			&:hover {
				color: #fff;
				background-color: useGray(15);
			}
		}

		&.red {
			color: #fff;
			border-color: #ff6f75;
			background-color: #ff6f75;

			&:hover {
				color: #ff6f75;
				border-color: #ff6f75;
				background-color: #fff;
			}
		}

		&.brown {
			color: #fff;
			border-color: $mColor2;
			background-color: $mColor2;

			&:hover {
				color: $mColor2;
				border-color: $mColor2;
				background-color: #fff;
			}
		}

		&.green {
			color: #fff;
			border-color: $mColor1;
			background-color: $mColor1;

			&:hover {
				color: $mColor1;
				border-color: $mColor1;
				background-color: #fff;
			}
		}

		&.dark {
			color: #fff;
			border-color: useGray(7);
			background-color: useGray(7);

			&:hover {
				color: useGray(7);
				border-color: useGray(7);
				background-color: #fff;
			}
		}

		&.facebook {
			color: #fff;
			background-color: $fbcolor;
		}
	}

	&.right {
		justify-content: flex-end;
	}

	&.col2 {
		width: 100%;
		justify-content: space-between;

		.button {
			box-sizing: border-box;
			width: calc(50% - 10px);
		}
	}

	&.max {
		width: 100%;

		.button {
			width: 100%;
		}
	}
}

.noJS {
	> * {
		display: none !important;
	}

	> noscript {
		display: block !important;
		width: 100%;
		line-height: 10em;
		font-size: 21px;
		text-align: center;
		color: red;
	}
}

@for $i from 0 to 10 {
	$j: 5 * $i;

	.mt#{$j} {
		margin-top: #{$j}px !important;
	}

	.ml#{$j} {
		margin-left: #{$j}px !important;
	}

	.mr#{$j} {
		margin-right: #{$j}px !important;
	}

	.mb#{$j} {
		margin-bottom: #{$j}px !important;
	}
}
