/* 會員登入與註冊 */
.login-block,
.register-block {
	display: flex;

	.left-block {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		flex: none;
		width: 50%;

		.login-bg {
			position: relative;
			z-index: 1;
			background: center no-repeat;
			background-size: cover;
			width: 100%;
			height: 100%;
		}

		.link {
			color: $mColor1;
		}

		.wrap {
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-size: 21px;
			font-weight: bold;
			transform: translateY(-30px);

			svg {
				width: 90px;
				height: 90px;
				color: $mColor1;
				margin-bottom: 15px;
			}
		}
	}

	.right-block {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		flex: none;
		width: 50%;
		padding: 60px 40px;
		overflow: hidden;
		overflow-y: auto;

		.firstbuy {
			margin-top: 10px;
		}

		.title {
			color: #000;
			font-size: 19px;
			font-weight: bold;
		}

		.uiform {
			margin: 20px 0 60px;
		}
	}

	.social-wrap {
		display: flex;
		flex-wrap: wrap;
		margin: 10px 0 0 -10px;

		.item {
			margin: 10px 0 0 10px;
			width: 128px;
			height: 90px;
			border: 1px solid useGray(15);
			background-color: #fff;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			svg {
				width: 30px;
				height: 30px;
				margin-bottom: 5px;
			}

			&:hover {
				color: $mColor1;
				border-color: $mColor1;
			}

			&.facebook {
				color: #fff;
				background-color: $fbcolor;
				border-color: $fbcolor;

				&:hover {
					color: $fbcolor;
					background-color: #fff;
				}
			}

			&.line {
				color: #fff;
				background-color: $linecolor;
				border-color: $linecolor;

				&:hover {
					color: $linecolor;
					background-color: #fff;
				}
			}
		}
	}

	@include rwd-width(1024) {
		.left-block,
		.right-block {
			width: 50%;
		}
	}

	@include rwd-width(768) {
		height: 100vh;

		.right-block {
			width: 50%;
			padding: 30px 20px;
		}
	}

	@include rwd-width(650) {
		.left-block {
			right: 0;
			bottom: auto;
			width: 100%;
			height: 240px;

			.login-bg {
				background-position: center 25%;
			}

			.wrap {
				transform: none;
			}
		}

		.right-block {
			top: 240px;
			left: 0;
			width: 100%;
		}
	}

	@include rwd-width(500) {
		.left-block {
			.login-bg {
				background-position: center 10%;
			}

			.wrap {
				svg {
					width: 70px;
					height: 70px;
				}
			}
		}
	}

	@include rwd-width(375) {
		.left-block {
			.login-bg {
				background-position: center 0%;
			}
		}
	}
}

/* 忘記密碼 */
.forgotten-block {
	padding: 40px;

	.title {
		color: #000;
		font-size: 19px;
		font-weight: bold;
	}

	.note {
		color: useGray(8);
	}

	.uiform {
		margin: 20px 0 0;
	}

	.buttons {
		margin-top: 20px;
	}

	@include rwd-width(425) {
		padding: 40px 20px;

		.buttons.col2 {
			.button {
				width: 100%;

				+ .button {
					margin-top: 10px;
				}
			}
		}
	}
}

/* 會員專區 */
.account-block {
	background: url("../image/vein1-green.png"), url("../image/vein3-green.png"), url("../image/juicy2.png");
	background-repeat: no-repeat;
	background-position: left -50px bottom -90px, right -30px top -50px, left 35px top 20px;
	background-size: 344px 259px, 314px 205px, 195px 272px;
	padding: 50px;
	min-height: 400px;

	.title {
		text-align: center;

		.icon {
			color: #fff;
			background-color: $mColor1;
			width: 40px;
			height: 40px;
			border-radius: 10px;
			box-sizing: border-box;
			padding: 7px;
			margin: 0 auto;

			svg {
				width: 26px;
				height: 26px;
			}
		}

		.txt {
			margin-top: 5px;
			font-weight: bold;
			font-size: 18px;
			color: $mColor1;
		}

		.welcome {
			font-size: 17px;
			margin-top: 9px;
			color: useGray(9);
		}
	}

	.func-area {
		display: flex;
		flex-wrap: wrap;
		margin: 25px 0 0 -10px;

		.item {
			position: relative;
			width: calc(16.667% - 10px);
			margin: 10px 0 0 10px;
			background-color: $mColor6;
			border-radius: 50%;
			transition: all 0.5s;
			display: flex;
			justify-content: center;
			align-items: center;

			.unread {
				position: absolute;
				top: 7px;
				right: 7px;
				line-height: 15px;
				background-color: $red;
				color: #fff;
				padding: 2px 4px;
				border-radius: 5px;
				font-family: sans-serif;
				font-size: 17px;
			}

			.wrap {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			svg {
				width: 60px;
				height: 60px;
				color: $mColor1;
			}

			.name {
				margin-top: 15px;
				font-size: 17px;
				font-weight: bold;
				color: useGray(9);
			}

			&::before {
				content: "";
				padding-top: 100%;
			}

			&:hover {
				background-color: $mColor1;
				transform: translateY(-10px);

				svg,
				.name {
					color: #fff;
				}
			}
		}
	}

	@include rwd-width(1023) {
		.func-area {
			.item {
				width: calc(20% - 10px);
			}
		}
	}

	@include rwd-width(900) {
		.func-area {
			.item {
				width: calc(25% - 10px);
			}
		}
	}

	@include rwd-width(768) {
		height: 100vh;
	}

	@include rwd-width(700) {
		.title {
			.welcome {
				font-weight: bold;
				text-shadow: 1px 0px 2px white, 0px 1px 2px white, -1px 0px 2px white, 0px -1px 2px white;
			}
		}
		.func-area {
			.item {
				width: calc(33.33% - 10px);
			}
		}
	}

	@include rwd-width(550) {
		.func-area {
			.item {
				width: calc(50% - 10px);
			}
		}
	}

	@include rwd-width(425) {
		padding: 50px 20px;
	}
}

/* 變更密碼 */
.password-block {
	padding: 40px;

	.title {
		color: #000;
		font-size: 19px;
		font-weight: bold;
	}

	.uiform {
		margin: 20px 0 0;
	}

	.buttons {
		margin-top: 20px;
	}

	@include rwd-width(425) {
		padding: 40px 20px;

		.buttons.col2 {
			.button {
				width: 100%;

				+ .button {
					margin-top: 10px;
				}
			}
		}
	}
}

/* 修改資料 */
.edit-block {
	background: url("../image/vein1-green.png"), url("../image/vein3-green.png");
	background-repeat: no-repeat;
	background-position: left -8% bottom -45%, right -10% top -25%;
	background-size: 344px 259px, 314px 205px;
	padding: 50px;

	.title {
		color: #000;
		font-size: 19px;
		font-weight: bold;
	}

	.uiform {
		margin-top: 30px;
	}

	.picture {
		background: center no-repeat;
		width: 150px;
		height: 150px;
		background-size: contain;
		margin: 20px auto;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		.wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
		}

		.picture-change {
			background: useGray(18) center no-repeat;
			color: $mColor4;
			width: 30px;
			height: 30px;
			padding: 5px;
			border-radius: 50%;
		}

		@include rwd-width(650) {
			margin-bottom: 0;
		}
	}

	.buttons {
		margin-top: 50px;

		&.col2 {
			.button {
				max-width: 160px;
			}
		}
	}

	@include rwd-width(768) {
		padding: 50px 20px;
	}

	@include rwd-width(650) {
		.uiform {
			margin-top: 0;
		}
	}

	@include rwd-width(425) {
		.buttons.col2 {
			.button {
				width: 100%;
				max-width: none;

				+ .button {
					margin-top: 10px;
				}
			}
		}
	}
}

.wish-block {
	background: url("../image/vein1-green.png"), url("../image/vein3-green.png");
	background-repeat: no-repeat;
	background-position: left -8% bottom -45%, right -10% top -25%;
	background-size: 344px 259px, 314px 205px;
	padding: 50px;

	.title {
		color: #000;
		font-size: 19px;
		font-weight: bold;
	}

	.wish-list {
		margin-top: 20px;

		.body {
			margin-top: 40px;

			.item {
				display: flex;
				align-items: center;

				.thumb {
					flex: none;
					background: center no-repeat;
					background-size: contain;
					width: 80px;
					height: 80px;
				}

				.wrap {
					flex: auto;
					display: flex;
					align-items: center;
				}

				.name {
					flex: auto;
					font-size: 18px;
					padding: 0 5px;
				}

				.stock {
					flex: none;
					width: 100px;
					text-align: center;
				}

				.buttons {
					flex: none;
					max-width: 150px;

					.button {
						padding: 5px;

						+ .button {
							margin-top: 5px;
						}
					}
				}

				+ .item {
					margin-top: 10px;
					padding-top: 10px;
					border-top: 1px solid useGray(16);
				}

				@include rwd-width(650) {
					.wrap {
						flex-wrap: wrap;
						margin-left: 10px;
					}

					.buttons {
						max-width: none;
						flex-wrap: nowrap;
						margin-top: 10px;

						.button + .button {
							margin: 0 0 0 10px;
						}
					}
				}

				@include rwd-width(425) {
					.name {
						width: 100%;
					}
					.stock {
						margin-top: 5px;
						text-align: left;
					}
					.buttons {
						margin-top: 5px;
						.button {
							font-size: 15px;
						}
					}
				}
			}

			.empty {
				font-size: 19px;
				margin: 0;
				font-weight: bold;
			}
		}
	}

	.buttons.action {
		margin-top: 40px;
	}

	@include rwd-width(600) {
		padding: 50px 20px;
	}

	@include rwd-width(425) {
		padding: 50px 10px;
	}
}

.order-block {
	background: url("../image/vein1-green.png"), url("../image/vein3-green.png");
	background-repeat: no-repeat;
	background-position: left -8% bottom -45%, right -10% top -25%;
	background-size: 344px 259px, 314px 205px;
	padding: 50px;

	.title {
		color: #000;
		font-size: 19px;
		font-weight: bold;
	}

	.order-list {
		margin-top: 20px;

		.pagination {
			margin-top: 20px;
		}
	}

	.order-info {
		margin-top: 20px;

		.info {
			display: flex;
			justify-content: space-between;

			.bold {
				font-weight: bold;
			}
		}

		.products,
		.payship,
		.historys,
		.comment,
		.buttons {
			margin-top: 20px;
		}

		.products {
			.total {
				text-align: right;
			}
		}
		.payment-total {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;

			.item {
				flex: auto;
			}

			.pdata {
				display: inline-block;
				padding: 5px;
				background-color: useGray(19);

				.data {
					display: flex;
				}

				.name {
					text-align: right;
					min-width: 100px;
				}
			}

			.totals {
				flex: none;
				max-width: 480px;
			}

			.wrap {
				display: flex;
				justify-content: flex-end;

				.value {
					min-width: 100px;
					padding-right: 5px;
					text-align: right;
				}
			}

			.buttons {
				margin-top: 0;
			}
		}

		.payship {
			display: flex;
			flex-wrap: wrap;

			.wrap {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;

				.title {
					width: 100%;
				}

				+ .wrap {
					margin-top: 20px;
				}
			}

			.item {
				display: flex;
				width: calc(50% - 10px);
				text-align: left;
				word-break: break-all;

				.name {
					min-width: 100px;
					text-align: right;
				}
			}
		}

		.historys {
			.item {
				display: flex;
				flex-wrap: wrap;
				padding: 5px;

				+ .item {
					border-top: 1px solid useGray(16);
				}

				&:hover {
					background-color: useGray(19);
				}
			}

			.status {
				margin-left: 15px;
			}

			.wrap {
				width: 100%;
				display: flex;
			}

			.note {
				width: 80px;
			}
			.txt {
				width: calc(100% - 80px);
			}
		}

		.buttons {
			.button {
				min-width: 180px;
			}
		}

		@include rwd-width(900) {
			.payship {
				.item {
					width: 100%;
				}
			}
		}

		@include rwd-width(700) {
			.info {
				flex-wrap: wrap;

				.item {
					width: calc(50% - 10px);
				}
			}

			table.list thead td {
				white-space: nowrap;
			}

			.payment-total {
				flex-wrap: wrap;

				.item {
					order: 2;
					flex: none;
					width: 100%;

					&.totals {
						order: 1;
						margin-bottom: 10px;
						max-width: none;
					}
				}

				.pdata {
					width: 100%;
				}

				.buttons {
					.button {
						width: 100%;
					}
				}
			}
		}

		@include rwd-width(425) {
			.info .item {
				width: 100%;
			}
		}
	}

	.buttons {
		margin-top: 40px;
	}

	@include rwd-width(700) {
		padding: 50px 20px;
	}

	@include rwd-width(560) {
		.order-list {
			table.list {
				td.total {
					display: none;
				}
			}
		}
	}

	@include rwd-width(500) {
		padding: 50px 10px;

		.order-list {
			table.list {
				tbody td {
					font-size: 13px;
				}
				td.date {
					display: none;
				}
			}
		}
	}
}
