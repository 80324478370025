@charset "UTF-8";
@font-face {
  font-family: "irnfont";
  src: url("/upload/font.woff2") format("woff2");
}
* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: "Microsoft JhengHei", sans-serif, Arial, Helvetica;
  vertical-align: baseline;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  overflow: -moz-scrollbars-vertical;
}

img {
  border: none;
}

table {
  border-collapse: collapse;
}

svg {
  margin: 0;
  padding: 0;
  fill: currentColor;
  vertical-align: top;
}

/* HTML 標籤 */
* {
  outline: none;
}

body {
  font-size: 15px;
  background-color: #F5F4F3;
  color: #4d4d4d;
  letter-spacing: 1px;
  visibility: visible;
  color: #524643;
}
body.fixed {
  overflow: hidden;
}

a {
  text-decoration: none;
  cursor: pointer;
  font-size: inherit;
  color: inherit;
  letter-spacing: 1px;
}
a.selected, a:hover {
  color: #ED6B28;
}

input {
  outline: 0;
  font-size: 1em;
}
input[type=text], input[type=password], input[type=date], input[type=email], input[type=number] {
  padding: 0px 5px;
  border: 1px solid #d9d9d9;
  vertical-align: top;
  border-radius: 0;
}
input[type=radio], input[type=checkbox] {
  cursor: pointer;
  vertical-align: -2px;
  width: 15px;
  height: 15px;
}
input[type=number] {
  appearance: textfield;
}
input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  padding: 2px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  vertical-align: middle;
  outline: 0;
  background-color: #fff;
  border-radius: 0;
  font-size: 15px;
}

textarea {
  font-size: 1em;
  padding: 5px;
  resize: none;
  border: 1px solid #d9d9d9;
  outline: 0;
  border-radius: 0;
}

label {
  cursor: pointer;
}

table {
  width: 100%;
  background-color: #fff;
}
table.list td {
  padding: 5px;
  line-height: 1.6em;
}
table.list td.center {
  text-align: center;
}
table.list td.right {
  text-align: right;
}
table.list td.wd100 {
  width: 100px;
}
table.list td.wd150 {
  width: 150px;
}
table.list td.wd200 {
  width: 200px;
}
table.list thead td {
  color: #4d4d4d;
  font-weight: bolder;
  background-color: #e6e6e6;
}
table.list tbody td {
  border-bottom: 1px solid #d9d9d9;
}
table.list tbody tr:hover {
  background-color: #f2f2f2;
}

svg {
  width: 20px;
  height: 20px;
}

.img_captcha {
  cursor: pointer;
}

.empty {
  margin-left: 20px;
}

.hide {
  display: none !important;
}

.main-color {
  color: #ED6B28;
}

.small {
  font-size: 13px;
}

.ellipsis, .ellipsis-3, .ellipsis-2, .ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
}
.ellipsis-1 {
  -webkit-line-clamp: 1;
}
.ellipsis-2 {
  -webkit-line-clamp: 2;
}
.ellipsis-3 {
  -webkit-line-clamp: 3;
}

.red,
.error {
  color: #e80009;
}

.green {
  color: #589f35;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
}
.buttons .button {
  cursor: pointer;
  color: #ED6B28;
  background-color: #fff;
  border: 1px solid #ED6B28;
  font-size: 17px;
  font-weight: bolder;
  padding: 13px 20px;
  text-align: center;
  transition: color 0.5s, background-color 0.5s, border-color 0.5s;
}
.buttons .button:hover {
  color: #fff;
  background-color: #ED6B28;
}
.buttons .button.gray {
  color: #4d4d4d;
  border-color: #f2f2f2;
  background-color: #f2f2f2;
}
.buttons .button.gray:hover {
  color: #fff;
  background-color: #bfbfbf;
}
.buttons .button.red {
  color: #fff;
  border-color: #ff6f75;
  background-color: #ff6f75;
}
.buttons .button.red:hover {
  color: #ff6f75;
  border-color: #ff6f75;
  background-color: #fff;
}
.buttons .button.brown {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}
.buttons .button.brown:hover {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}
.buttons .button.green {
  color: #fff;
  border-color: #ED6B28;
  background-color: #ED6B28;
}
.buttons .button.green:hover {
  color: #ED6B28;
  border-color: #ED6B28;
  background-color: #fff;
}
.buttons .button.dark {
  color: #fff;
  border-color: #595959;
  background-color: #595959;
}
.buttons .button.dark:hover {
  color: #595959;
  border-color: #595959;
  background-color: #fff;
}
.buttons .button.facebook {
  color: #fff;
  background-color: #3b5997;
}
.buttons.right {
  justify-content: flex-end;
}
.buttons.col2 {
  width: 100%;
  justify-content: space-between;
}
.buttons.col2 .button {
  box-sizing: border-box;
  width: calc(50% - 10px);
}
.buttons.max {
  width: 100%;
}
.buttons.max .button {
  width: 100%;
}

.noJS > * {
  display: none !important;
}
.noJS > noscript {
  display: block !important;
  width: 100%;
  line-height: 10em;
  font-size: 21px;
  text-align: center;
  color: red;
}

.mt0 {
  margin-top: 0px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

/* 主框架 */
body {
  max-width: 1920px;
  margin: 0px auto;
}

.restrict {
  width: 100%;
  max-width: 1640px;
  padding: 0px;
  margin: 0px auto;
}
@media (max-width: 1680px) {
  .restrict {
    margin: 0;
    padding: 0 40px;
  }
}
@media (max-width: 750px) {
  .restrict {
    padding: 0 20px;
  }
}
@media (max-width: 425px) {
  .restrict {
    padding: 0 20px;
  }
}

#tpl-template {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0.5;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

#header {
  position: fixed;
  z-index: 11;
  width: 100%;
  margin: 0 auto;
  height: 90px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transition: top 0.3s, height 0.3s;
  padding: 20px 0;
  background-color: #fff;
  /*box-shadow:  0px 50px 80px rgba(255, 255, 255, 0.7);
  border-bottom:1px solid #eee;*/
}
#header .header-wrap {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: calc(100% - 60px);
  max-width: 1640px;
  justify-content: space-between;
}
#header .menus {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 210px - 300px);
}
#header .menus .item {
  margin: 0 30px;
}
#header .menus .item a {
  letter-spacing: 0;
}
#header .submenus {
  display: none;
}
#header #logo {
  flex: none;
  display: flex;
  align-items: center;
  margin: 100px;
  color: #524643;
}
#header #logo svg {
  width: 145px;
  height: 157px;
}
#header #menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1245px;
  margin: 0 auto;
}
#header #menu .item {
  position: relative;
  z-index: 1;
}
#header #menu .item .child {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
}
#header #menu .item .child .child-wrap {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 126px;
  filter: drop-shadow(0px -1px 5px rgba(200, 200, 200, 0.1));
}
#header #menu .item .child .child-wrap::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 12px 7.5px;
  border-color: transparent transparent #ffffff transparent;
}
#header #menu .item .child .link {
  padding: 5px 0;
  font-size: 13px;
  text-underline-offset: 3px;
  white-space: nowrap;
}
#header #menu .item .child .link:hover {
  color: #ED6B28;
  text-decoration: underline;
}
#header #menu .item .name {
  position: relative;
  z-index: 2;
  padding: 0 10px;
  border-radius: 20px;
  min-width: 140px;
  height: 40px;
  font-size: 18px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#header #menu .item .name:hover {
  background-color: #ED6B28;
  color: #fff;
}
#header #menu .item:hover .child {
  opacity: 1;
  visibility: visible;
}
#header .block {
  position: relative;
  display: flex;
  align-items: center;
}
#header .block a {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  transition: 0.3s 0s ease;
  letter-spacing: 0;
}
#header .block a + a {
  margin-left: 20px;
}
#header .block a.btn {
  border: 1px solid #524643;
  background-color: #fff;
  color: #524643;
  font-weight: bold;
}
#header .block a.btn svg {
  width: 20px;
  height: 20px;
  margin-left: 14px;
}
#header .block a.btn:hover {
  background-color: #524643;
  color: #fff;
}
#header .block .cart-total {
  color: #fff;
  background-color: #c79967;
  border-radius: 5px;
  margin-left: 5px;
  padding: 0 4px;
}
#header .block .menu-button {
  position: relative;
  display: none;
  height: 25px;
  width: 35px;
  overflow: hidden;
  cursor: pointer;
}
#header .block .menu-button .bar,
#header .block .menu-button .text, #header .block .menu-button::before, #header .block .menu-button::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #524643;
  content: "";
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
#header .block .menu-button .bar {
  bottom: 0;
}
#header .block .menu-button .txt {
  top: 24px;
  font-size: 16px;
}
#header .block .menu-button .txt::before {
  content: "MENU";
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  color: #524643;
  font-size: 12px;
  font-weight: bold;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  text-align: center;
  letter-spacing: 0px;
}
#header .block .menu-button::before {
  top: 0;
}
#header .block .menu-button::after {
  top: 10px;
}
#header .block .menu-button:hover::before {
  left: -39px;
}
#header .block .menu-button:hover::after {
  left: 39px;
}
#header .block .menu-button:hover .txt:before {
  animation: moveUpThenDown 0.8s ease 0.2s forwards, shakeWhileMovingUp 0.8s ease 0.2s forwards, shakeWhileMovingDown 0.2s ease 0.8s forwards;
}
#header .block .menu-button.show::before {
  left: -39px;
}
#header .block .menu-button.show::after {
  left: 39px;
}
#header .block .menu-button.show .txt:before {
  animation: moveUpThenDown 0.8s ease 0.2s forwards, shakeWhileMovingUp 0.8s ease 0.2s forwards, shakeWhileMovingDown 0.2s ease 0.8s forwards;
}
@keyframes moveUpThenDown {
  0% {
    top: 0;
  }
  50% {
    top: -20px;
  }
  100% {
    top: -14px;
  }
}
@keyframes shakeWhileMovingUp {
  0% {
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  50% {
    transform: rotateZ(0deg);
  }
  75% {
    transform: rotateZ(10deg);
  }
  100% {
    transform: rotateZ(0);
  }
}
@keyframes shakeWhileMovingDown {
  0% {
    transform: rotateZ(0);
  }
  80% {
    transform: rotateZ(3deg);
  }
  90% {
    transform: rotateZ(-3deg);
  }
  100% {
    transform: rotateZ(0);
  }
}
#header.fixed {
  top: 0;
  height: 90px;
  background-color: #fff;
  backdrop-filter: blur(10px);
  box-shadow: none;
  border: none;
}
#header.fixed #logo {
  margin: 50px 100px 0;
}
#header.fixed #logo svg {
  height: 105px;
}
#header.fixed .block {
  bottom: 0;
}
@media (max-width: 1400px) {
  #header .menus .item {
    margin: 0 15px;
  }
}
@media (max-width: 1245px) {
  #header .menus .item {
    margin: 0 10px;
  }
}
@media (max-width: 1100px) {
  #header .menus .item {
    margin: 0 6px;
  }
}
@media (max-width: 1040px) {
  #header {
    position: fixed;
    top: 0;
    height: 70px;
    background-color: #fff;
    padding: 10px 0;
  }
  #header .menus .item {
    display: none;
  }
  #header .block {
    flex: none;
    position: relative;
    margin-left: 20px;
  }
  #header .block a.btn {
    padding: 3px 14px;
  }
  #header .block a svg {
    width: 26px;
    height: 26px;
  }
  #header .block .menu-button {
    display: block;
  }
}
@media (max-width: 660px) {
  #header .block a.btn .text {
    display: none;
  }
  #header .block a.btn svg {
    margin-left: 0;
  }
  #header .block a + a {
    margin-left: 10px;
  }
}
@media (max-width: 480px) {
  #header {
    height: 60px;
    padding: 10px 0;
  }
  #header .header-wrap {
    width: calc(100% - 20px);
  }
  #header .logo img {
    height: 40px;
  }
}

#menu-popup {
  position: fixed;
  z-index: 10;
  top: -100%;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #191E24f0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  height: 0;
}
#menu-popup .block {
  position: relative;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 120px 20px 15px;
  max-width: 1300px;
  margin: 0 auto;
  height: 100%;
}
#menu-popup .control-block {
  display: flex;
  position: relative;
  justify-content: flex-start;
  height: 40px;
}
#menu-popup .control-block::after {
  content: "";
  position: absolute;
  top: 20px;
  right: 0;
  width: 100%;
  border: 1px solid #666666;
  z-index: 1;
}
#menu-popup .control-block .back {
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #fff;
  height: 38px;
  width: 120px;
  color: #fff;
}
#menu-popup .control-block .back svg {
  margin-right: 10px;
}
#menu-popup .control-block .back:hover {
  border: 1px solid #ED6B28;
  color: #ED6B28;
}
#menu-popup .control-block .close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  padding: 8px;
  border-radius: 50%;
  background-color: #ED6B28;
  fill: #fff;
  color: #fff;
  z-index: 2;
  position: absolute;
  right: 0;
  border: 1px solid #ED6B28;
  transition: all 0.5s;
}
#menu-popup .control-block .close:hover {
  background-color: #fff;
  fill: #ED6B28;
  color: #ED6B28;
}
#menu-popup .mtitle {
  width: 200px;
}
#menu-popup .menu-block {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 60px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px;
  overflow: hidden;
  overflow-y: auto;
}
#menu-popup .menu-block .title {
  color: #ED6B28;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 10px;
}
#menu-popup .menu-block .menu-effect {
  position: relative;
  left: -100%;
  opacity: 0;
  transition: all 0.5s;
  transition-delay: 0.5s;
  height: 0;
}
#menu-popup .menu-block .menu-effect.change {
  transition-delay: 0s;
}
#menu-popup .menu-block .menu-effect.m1 {
  max-width: 330px;
}
#menu-popup .menu-block .menu-effect.mf {
  width: 100%;
}
#menu-popup .menu-block .menu-effect.mf .mf_list {
  display: flex;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item {
  width: 25%;
  max-width: 330px;
  margin-bottom: 20px;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item .subitem {
  position: relative;
  z-index: 4;
  width: 100%;
  padding: 10px;
  transition: all 0.5s;
  overflow: hidden;
  opacity: 0;
  margin-top: -40px;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item .subitem.open {
  opacity: 1;
  margin-top: 0;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item .switch {
  position: relative;
  z-index: 5;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item .switch svg {
  display: none;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item .switch svg:nth-child(1) {
  display: block;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item .switch svg:nth-child(2) {
  display: none;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item .switch.open svg:nth-child(1) {
  display: none;
}
#menu-popup .menu-block .menu-effect.mf .mf_list .mf_item .switch.open svg:nth-child(2) {
  display: block;
}
@media (max-width: 1040px) {
  #menu-popup .menu-block .menu-effect.mf .mf_list .mf_item {
    width: 50%;
    max-width: initial;
  }
}
@media (max-width: 600px) {
  #menu-popup .menu-block .menu-effect.mf .mf_list {
    flex-wrap: wrap;
  }
  #menu-popup .menu-block .menu-effect.mf .mf_list .mf_item {
    width: 100%;
  }
}
#menu-popup .menu-block .item {
  padding: 12px 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#menu-popup .menu-block .item .name {
  font-size: 16px;
  color: #fff;
  text-decoration: underline;
}
#menu-popup .menu-block .item .name.selected {
  color: #ED6B28;
}
#menu-popup .menu-block .item .name:hover {
  color: #ED6B28;
}
#menu-popup .menu-block .item .icon {
  font-size: 16px;
  color: #fff;
  fill: #fff;
}
#menu-popup .menu-block .item .icon.selected {
  color: #ED6B28;
}
#menu-popup .menu-block .item .icon:hover {
  color: #ED6B28;
}
#menu-popup .menu-block .item .child {
  display: none;
  flex-direction: column;
  margin: 15px 0 0 25px;
}
#menu-popup .menu-block .item .child .link:hover, #menu-popup .menu-block .item .child .link.selected {
  color: #ED6B28;
  text-decoration: underline;
  text-underline-offset: 5px;
}
#menu-popup .menu-block .item .child .link + .link {
  margin-top: 10px;
}
#menu-popup .menu-block .item.selected .child {
  display: flex;
}
#menu-popup.show {
  opacity: 1;
  visibility: visible;
  top: 0;
  height: 100%;
}
#menu-popup.show .menu-block .menu-effect.show {
  opacity: 1;
  left: 0;
  height: auto;
}
@media (max-width: 1040px) {
  #menu-popup .control-block.hb::after {
    width: calc(100% - 120px);
  }
  #menu-popup .control-block.hb .back {
    display: flex;
  }
  #menu-popup .block {
    padding: 100px 20px 15px;
  }
}
@media (max-width: 768px) {
  #menu-popup .block {
    left: 0;
    max-width: none;
  }
}
@media (max-width: 480px) {
  #menu-popup .block {
    padding: 90px 20px 15px;
  }
}

.waveout {
  position: relative;
  height: 25px;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  bottom: 20px;
}
.waveout .wave,
.waveout .wave::before,
.waveout .wave::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-left: -100px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-image: -webkit-linear-gradient(rgba(82, 70, 67, 0.8), rgba(62, 50, 57, 0.8));
  background-image: linear-gradient(rgba(82, 70, 67, 0.8), rgba(62, 50, 57, 0.8));
  border-radius: 38% 42%;
  box-shadow: inset 0 0 10vw rgba(255, 255, 255, 0.5);
  -webkit-animation: spin 16s infinite linear;
  animation: spin 16s infinite linear;
}
.waveout .wave::before {
  width: 105%;
  height: 95%;
  margin-top: -100px;
  -webkit-transform-origin: 49% 51%;
  transform-origin: 49% 51%;
  border-radius: 40% 38%;
  -webkit-animation: spin 13s infinite linear;
  animation: spin 13s infinite linear;
}
.waveout .wave::after {
  width: 102%;
  height: 98%;
  margin-top: -100px;
  -webkit-transform-origin: 51% 49%;
  transform-origin: 51% 49%;
  border-radius: 48% 42%;
  -webkit-animation: spin 10s infinite linear;
  animation: spin 10s infinite linear;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.menu-button:hover .waveout {
  opacity: 1;
}

.menu-button.show .waveout {
  opacity: 1;
}

#footer {
  clear: both;
  position: relative;
  display: block;
  box-sizing: border-box;
  background-image: url("/upload/footerBg.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  box-sizing: border-box;
  background-color: #514642;
}
#footer .ellipsis, #footer .ellipsis-1, #footer .ellipsis-2, #footer .ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#footer .footer-wrap {
  position: relative;
  display: block;
  margin: 0 auto;
  width: calc(100% - 60px);
  max-width: 1640px;
}
#footer .top-block {
  position: relative;
  display: block;
  padding: 50px 0 30px;
  letter-spacing: 0;
  font-size: 0;
  box-sizing: border-box;
}
#footer .top-block .main-block {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 345px;
}
#footer .top-block .main-block .companys {
  position: relative;
  display: block;
  margin-top: 25px;
}
#footer .top-block .main-block .powered {
  font-size: 14px;
  position: relative;
  display: block;
  margin-top: 5px;
  line-height: 2;
  letter-spacing: 1px;
  color: #D1C8C6;
}
#footer .top-block .main-block .powered .text {
  display: flex;
}
#footer .top-block .main-block .powered .text span {
  color: #D1C8C6;
}
#footer .top-block .main-block .powered .text span:nth-child(1) {
  color: #ED6B28;
  margin-right: 12px;
}
#footer .top-block .main-block .powered .text a {
  color: #D1C8C6;
  transition: 0.3s 0s ease;
}
#footer .top-block .main-block .powered .text a:hover {
  color: #ED6B28;
}
#footer .top-block .main-block .share {
  display: flex;
  color: #fff;
  margin-top: 5px;
}
#footer .top-block .main-block .share .shareBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
#footer .top-block .main-block .share .shareBar .svg {
  width: 30px;
  height: 30px;
}
#footer .top-block .main-block .share .shareBar:hover {
  color: #fff;
}
#footer .top-block .main-block .share .shareBar:hover.yt {
  background-color: #ff0000;
}
#footer .top-block .main-block .share .shareBar:hover.fb {
  background-color: #3b5997;
}
#footer .top-block .main-block .share .shareBar:hover.line {
  background-color: #00ba01;
}
#footer .top-block .main-block .share .shareBar:hover.twitter {
  background-color: #1da1f2;
}
#footer .top-block .main-block .share .shareBar:hover.ig {
  background: url("../image/igBg.jpg") no-repeat;
}
#footer .top-block .menu-block {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-top: 20px;
  width: calc(100% - 345px);
  text-align: center;
}
#footer .top-block .menu-block .holder {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 1080px;
}
#footer .top-block .menu-block .menu-ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
#footer .top-block .menu-block .menu-ul > li {
  width: 155px;
}
#footer .top-block .menu-block .menu-ul > li .topic {
  display: block;
  text-align: center;
  line-height: 27px;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  transition: color 0.3s 0s ease;
}
#footer .top-block .menu-block .menu-ul > li .topic + .topic {
  margin-top: 15px;
}
#footer .top-block .menu-block .menu-ul > li .topic:hover {
  color: #fff;
  text-decoration: underline;
}
#footer .top-block .menu-block .menu-ul > li .topic.selected {
  color: #fff;
  text-decoration: underline;
}
#footer .top-block .menu-block .menu-ul > li .childs {
  position: relative;
  display: block;
  margin-top: 15px;
  overflow: hidden;
}
#footer .top-block .menu-block .menu-ul > li .childs .child-ul {
  margin-top: -5px;
}
#footer .top-block .menu-block .menu-ul > li .childs .child-ul > li {
  margin-top: 5px;
  width: 100%;
}
#footer .top-block .menu-block .menu-ul > li .childs .child-ul > li .link {
  position: relative;
  display: block;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1px;
  font-size: 16px;
  color: #D1C8C6;
  transition: color 0.3s 0s ease;
}
#footer .top-block .menu-block .menu-ul > li .childs .child-ul > li .link:hover {
  color: #ED6B28;
  text-decoration: underline;
}
#footer .top-block .menu-block .menu-ul > li .childs .child-ul > li .link.selected {
  color: #ED6B28;
  text-decoration: underline;
}
#footer .top-block .menu-block .menu-ul > li .childs .child-ul > li .link.selected:before {
  content: "";
  display: inline-block;
  vertical-align: 3px;
  margin-right: 5px;
  width: 5px;
  height: 5px;
  border: 1px #fff solid;
  border-radius: 50%;
  box-sizing: border-box;
}
#footer .bot-block {
  position: relative;
  display: flex;
  padding: 10px 0 20px;
  box-sizing: border-box;
  justify-content: center;
  color: #fff;
}
#footer .bot-block .copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
#footer .bot-block .copyright span {
  color: #ED6B28;
  font-weight: bold;
}
#footer .bot-block .copyright div {
  text-align: center;
}
@media (max-width: 1220px) {
  #footer .bot-block .copyright {
    flex-wrap: wrap;
  }
}
@media (max-width: 1160px) {
  #footer .top-block .main-block .share {
    width: 100%;
  }
}
@media (max-width: 900px) {
  #footer .top-block {
    padding: 50px 0 10px;
  }
  #footer .top-block .main-block {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  #footer .top-block .main-block .logo {
    display: block;
    text-align: center;
  }
  #footer .top-block .main-block .share {
    justify-content: flex-start;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  #footer .top-block .main-block .companys {
    margin-top: 0;
    text-align: center;
    margin-left: 20px;
  }
  #footer .top-block .main-block .companys .powered {
    margin-left: 0;
  }
  #footer .top-block .main-block .companys .powered .text {
    margin-left: 0;
    width: auto;
    text-align: left;
  }
  #footer .top-block .menu-block {
    display: block;
    width: 100%;
  }
  #footer .top-block .menu-block .menu-ul {
    justify-content: space-between;
  }
  #footer .top-block .menu-block .menu-ul > li {
    width: auto;
  }
}
@media (max-width: 660px) {
  #footer .top-block {
    padding: 50px 0 10px;
  }
  #footer .top-block .main-block {
    display: block;
  }
  #footer .top-block .main-block .logo {
    display: block;
    text-align: center;
  }
  #footer .top-block .main-block .share {
    justify-content: center;
    position: relative;
    left: auto;
    bottom: auto;
  }
  #footer .top-block .main-block .companys {
    margin-top: 25px;
    margin-left: 0;
  }
  #footer .top-block .main-block .companys .powered .text {
    justify-content: center;
  }
  #footer .top-block .main-block .links {
    display: block;
    width: 100%;
    height: auto;
  }
  #footer .top-block .main-block .links .link-ul {
    text-align: center;
  }
  #footer .top-block .menu-block {
    display: none;
    margin: 0 auto;
    max-width: 150px;
  }
  #footer .top-block .menu-block .menu-ul {
    justify-content: space-between;
  }
  #footer .top-block .menu-block .menu-ul > li {
    width: auto;
  }
  #footer .top-block .menu-block .childs {
    display: none;
  }
}
@media (max-width: 425px) {
  #footer .bot-block .copyright {
    font-size: 14px;
  }
}

#footer-bar {
  position: fixed;
  z-index: 14;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  background: #e9f2e5;
}
#footer-bar .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}
#footer-bar .item svg {
  width: 18px;
  height: 18px;
  margin-bottom: 5px;
}
#footer-bar .item:hover {
  color: #ED6B28;
}
@media (max-width: 425px) {
  #footer-bar {
    display: flex;
  }
  #footer-bar + #footer {
    margin-bottom: 60px;
  }
}

#container {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 90px 0 90px;
}
#container.home {
  background-repeat: no-repeat;
}
@media (max-width: 1440px) {
  #container {
    background-position: center top;
  }
}
@media (max-width: 1024px) {
  #container {
    padding-top: 70px;
  }
}
#content {
  min-height: 1100px;
}

/* 進度條 */
.progress-model {
  position: fixed;
  z-index: 9999;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-model .progress-wrap {
  width: 480px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  flex-direction: column;
}
.progress-model .progress-wrap .progress-text {
  line-height: 20px;
  min-height: 24px;
  text-align: center;
}

.art-desc img {
  max-width: 100%;
  width: auto !important;
  height: auto !important;
  margin: 0px auto;
  display: block;
}
.art-desc .item {
  position: relative;
  width: 100%;
}
.art-desc .item img {
  width: auto !important;
  height: auto !important;
}
.art-desc .item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.art-desc .item ul,
.art-desc .item ol {
  padding-left: 30px;
}
.art-desc .item .title {
  color: #ED6B28;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.2em;
}
.art-desc .item .title + .txt {
  margin-top: 35px;
}
.art-desc .item .title + .col2 {
  margin-top: 20px;
}
.art-desc .item .txt {
  line-height: 2em;
  word-break: break-all;
}
.art-desc .item .txt.letter:first-letter {
  color: #ED6B28;
  font-size: 32px;
  font-weight: bold;
}
.art-desc .item .txt + .title {
  margin-top: 35px;
}
.art-desc .item.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.art-desc .item.text-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
.art-desc .item.image.border {
  position: relative;
  width: auto;
  display: inline-flex;
}
.art-desc .item.image.border img {
  position: relative;
  z-index: 2;
  max-width: calc(100% - 15px);
  margin-left: 0;
}
.art-desc .item.image.border::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 15px;
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  display: block;
  border: 5px solid #ED6B28;
}
.art-desc .item.iframe::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.art-desc .item + .item,
.art-desc .item + .col2,
.art-desc .item + .col3,
.art-desc .item + .icon-title {
  margin-top: 45px;
}
.art-desc .icon-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #ED6B28;
  padding-bottom: 5px;
}
.art-desc .icon-title svg {
  width: 36px;
  height: 36px;
  margin-left: 20px;
}
.art-desc .icon-title .it-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 20px;
}
.art-desc .icon-title .it-subtitle {
  color: #ED6B28;
  font-size: 18px;
  font-weight: bold;
}
.art-desc .icon-title + .item,
.art-desc .icon-title + .col2,
.art-desc .icon-title + .col3 {
  margin-top: 15px;
}
.art-desc .cktime {
  position: relative;
  margin-top: 90px;
  background-color: #f0f5ee;
  padding: 20px 20px 10px;
  border-radius: 10px;
}
.art-desc .cktime .cooktime {
  position: absolute;
  top: -40px;
  right: 0;
  width: 360px;
  height: 43px;
  fill: #f0f5ee;
}
.art-desc .cktime .cookroom {
  display: block;
  width: 191px;
  height: 34px;
  margin: 0 auto;
  fill: #ED6B28;
}
.art-desc .cktime .cookline {
  width: 100%;
  height: 6px;
  margin-top: 10px;
  fill: #ED6B28;
}
.art-desc .cktime .cookdesc {
  margin-left: 20px;
  line-height: 1.3em;
  letter-spacing: 1.2px;
}
.art-desc .cktime .cookdesc li + li {
  margin-top: 10px;
}
.art-desc .cktime .doctor {
  position: absolute;
  right: 15px;
  bottom: 0;
}
@media (max-width: 1440px) {
  .art-desc .cktime .doctor.hide1440 {
    display: none;
  }
}
@media (max-width: 1280px) {
  .art-desc .cktime .doctor.hide1280 {
    display: none;
  }
}
@media (max-width: 1024px) {
  .art-desc .cktime .doctor.hide1024 {
    display: none;
  }
}
@media (max-width: 768px) {
  .art-desc .cktime .doctor.hide768 {
    display: none;
  }
}
@media (max-width: 600px) {
  .art-desc .cktime .doctor.hide600 {
    display: none;
  }
}
@media (max-width: 480px) {
  .art-desc .cktime .doctor.hide480 {
    display: none;
  }
}
@media (max-width: 460px) {
  .art-desc .cktime .cooktime {
    top: -30px;
    left: 0;
    margin: 0 auto;
    width: 288px;
    height: 35px;
  }
}
.art-desc .col2 {
  display: flex;
  justify-content: space-between;
}
.art-desc .col2.center {
  align-items: center;
}
.art-desc .col2 .item {
  width: calc(50% - 40px);
}
.art-desc .col2 .item.image.border {
  display: block;
  width: calc(50% - 40px);
}
.art-desc .col2 .item + .item {
  margin: 0;
}
.art-desc .col2 + .item,
.art-desc .col2 + .col2,
.art-desc .col2 + .col3,
.art-desc .col2 + .icon-title {
  margin-top: 45px;
}
.art-desc .col2 .col2 .item {
  width: calc(50% - 10px);
}
.art-desc .col3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -15px 0 0 -15px;
}
.art-desc .col3.center {
  align-items: center;
}
.art-desc .col3 .item {
  width: calc(33.33% - 15px);
  margin: 15px 0 0 15px;
}
.art-desc .col3 + .item,
.art-desc .col3 + .col2,
.art-desc .col3 + .col3,
.art-desc .col3 + .icon-title {
  margin-top: 45px;
}
@media (max-width: 1440px) {
  .art-desc .col2 .col2 {
    flex-direction: column;
  }
  .art-desc .col2 .col2 .item {
    width: 100%;
  }
  .art-desc .col2 .col2 .item + .item {
    margin-top: 30px;
  }
}
@media (max-width: 900px) {
  .art-desc .item.image.border {
    width: auto;
    margin-bottom: 45px;
  }
  .art-desc .col2 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .art-desc .col2 .item {
    order: 2;
    width: 100%;
  }
  .art-desc .col2 .item + .item {
    margin-top: 30px;
  }
  .art-desc .col2 .item.image {
    order: 1;
    margin: 0 0 30px;
  }
  .art-desc .col2 .item.image.border {
    width: auto;
    display: inline-flex;
    margin-bottom: 60px;
  }
  .art-desc .col2 + .col2 {
    margin-top: 30px;
  }
  .art-desc .col3 {
    justify-content: flex-start;
  }
  .art-desc .col3 .item {
    width: calc(50% - 15px);
  }
}
@media (max-width: 500px) {
  .art-desc .col3 {
    margin: 0;
  }
  .art-desc .col3 .item {
    width: 100%;
    margin: 15px 0 0;
  }
}

/* 訊息通知 */
#notify-model {
  position: fixed;
  z-index: 1005;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
#notify-model #notify {
  position: fixed;
  z-index: 1006;
  top: 15%;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-flex;
  justify-content: center;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  max-width: 900px;
  max-height: 70%;
  width: calc(100% - 20px);
}
#notify-model #notify .notify-wrap {
  position: relative;
}
#notify-model #notify .close {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  color: #999999;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#notify-model #notify .close svg {
  width: 20px;
  height: 20px;
}
#notify-model #notify .message {
  margin: 0px auto;
  padding: 8px 15px;
  line-height: 24px;
  border-radius: 4px;
  border: 5px solid #999999;
  background-color: white;
  max-width: 900px;
  word-break: break-all;
  max-height: 100%;
  overflow: auto;
}
#notify-model #notify.attention .message {
  color: #f6b81b;
  border-color: #f6b81b;
}
#notify-model #notify.attention .close {
  display: none;
}
#notify-model #notify.success .message,
#notify-model #notify.success .close {
  color: #589f35;
  border-color: #589f35;
}
#notify-model #notify.warning .message,
#notify-model #notify.warning .close {
  color: #e80009;
  border-color: #e80009;
}
#notify-model #notify.show {
  visibility: visible;
  opacity: 1;
}
#notify-model.show {
  visibility: visible;
  opacity: 1;
}
#notify-model.delay {
  transition-delay: 0.4s;
}

/* 彈出視窗 */
.overlay {
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  opacity: 0;
  visibility: hidden;
  background-color: #00000066;
  transition: all 0.4s;
}
.overlay .popup {
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  max-width: 1000px;
  max-height: 750px;
  opacity: 0;
  visibility: hidden;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
  overflow: hidden;
  border-radius: 5px;
}
.overlay .popup .close {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 15px;
  width: 40px;
  height: 40px;
  opacity: 0.3;
}
.overlay .popup .close:before, .overlay .popup .close:after {
  position: absolute;
  left: 20px;
  content: " ";
  height: 40px;
  width: 2px;
  background-color: #4d4d4d;
  transition: transform 0.5s;
}
.overlay .popup .close:before {
  transform: rotate(45deg);
}
.overlay .popup .close:after {
  transform: rotate(-45deg);
}
.overlay .popup .close:hover {
  opacity: 1;
  cursor: pointer;
}
.overlay .popup .close:hover:before {
  background-color: #ED6B28;
  transform: rotate3d(0, 0, 1, 225deg);
}
.overlay .popup .close:hover:after {
  background-color: #ED6B28;
  transform: rotate3d(0, 0, 1, 135deg);
}
.overlay .popup .contenter {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
.overlay .popup.show {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 1040px) {
  .overlay .popup {
    margin-left: 20px;
    margin-right: 20px;
    max-width: none !important;
  }
}
@media (max-width: 768px) {
  .overlay .popup {
    border-radius: 0;
    margin: 0px;
    max-width: none !important;
    max-height: none !important;
  }
}
@media (max-height: 425px) {
  .overlay .popup {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.overlay .mCSB_scrollTools .mCSB_draggerRail {
  width: 7px;
}
.overlay .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 5px;
}
.overlay.show {
  visibility: visible;
  opacity: 1;
}
.overlay.delay {
  transition-delay: 0.4s;
}

/* 麵包屑 */
.breadcrumb {
  font-size: 14px;
  color: #fff;
}
.breadcrumb a:hover {
  color: #ED6B28;
}

/* 表單元件 */
.uiform .item .label {
  flex: none;
  display: flex;
  align-items: center;
  min-width: 90px;
}
.uiform .item .label svg {
  margin-right: 5px;
}
.uiform .item .label.required::after {
  content: "*";
  color: #ED6B28;
  margin-left: 5px;
}
.uiform .item .input {
  position: relative;
  height: 40px;
  border-bottom: 1px solid #bfbfbf;
  display: flex;
  align-items: center;
}
.uiform .item .input input {
  flex: auto;
  height: 100%;
  border: none;
  min-width: 0;
}
.uiform .item .input.radio, .uiform .item .input.checkbox {
  border-bottom: none;
}
.uiform .item .input.radio .wrap, .uiform .item .input.checkbox .wrap {
  display: flex;
}
.uiform .item .input.radio .wrap + .wrap, .uiform .item .input.checkbox .wrap + .wrap {
  margin-left: 15px;
}
.uiform .item .input.radio input, .uiform .item .input.checkbox input {
  height: 15px;
  width: 15px;
}
.uiform .item .input.radio .label, .uiform .item .input.checkbox .label {
  min-width: auto;
}
.uiform .item .input.radio .label + .wrap, .uiform .item .input.checkbox .label + .wrap {
  margin-left: 15px;
}
.uiform .item .input:hover, .uiform .item .input:focus {
  border-bottom-color: #ED6B28;
}
.uiform .item .input.rb {
  border-bottom-color: #e80009;
}
.uiform .item .textarea {
  display: flex;
  align-items: center;
}
.uiform .item .textarea textarea {
  flex: auto;
  min-height: 150px;
  border: none;
  min-width: 0;
  background-color: #f0f5ee;
}
.uiform .item .select {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.uiform .item .select select {
  flex: auto;
  border: none;
  border-bottom: 1px solid #bfbfbf;
  height: 100%;
}
.uiform .item .select select.rb {
  border-bottom-color: #e80009;
}
.uiform .item .select select + select {
  margin-left: 20px;
}
.uiform .item .value {
  height: 40px;
  display: flex;
  align-items: center;
  word-break: break-all;
  padding-bottom: 5px;
  border-bottom: 1px solid #bfbfbf;
}
.uiform .item.captcha .label {
  letter-spacing: 9px;
}
.uiform .item.captcha .refresh_captcha {
  height: 100%;
}
.uiform .item.captcha .refresh_captcha img {
  max-height: 100%;
}
.uiform .item + .item {
  margin-top: 20px;
}
.uiform .item.col2 {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}
.uiform .wrap-col2 {
  display: flex;
  flex-wrap: wrap;
  margin: -20px 0 0 -40px;
}
.uiform .wrap-col2 .item {
  width: calc(50% - 40px);
  margin: 20px 0 0 40px;
}
.uiform .wrap-col2 + .wrap-col2 {
  margin-top: 0;
}
@media (max-width: 650px) {
  .uiform .wrap-col2 {
    margin: 0;
  }
  .uiform .wrap-col2 .item {
    width: 100%;
    margin: 20px 0 0 0;
  }
}

/* 分頁 */
.pagination {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.pagination b,
.pagination a:hover {
  color: #ED6B28;
  text-decoration: underline;
  text-underline-offset: 8px;
}
.pagination a,
.pagination b {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 17px;
}
.pagination a + a,
.pagination a + b,
.pagination b + a,
.pagination b + b {
  margin-left: 10px;
}
.pagination .arrow:first-child, .pagination .arrow:last-child {
  color: #fff;
  background-color: #524643;
}
.pagination a.arrow:hover:first-child, .pagination a.arrow:hover:last-child {
  color: #fff;
  background-color: #ED6B28;
}
.pagination svg {
  width: 30px;
  height: 30px;
}

/* 分享列表 */
.share-list {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.share-list .share-text {
  color: #999999;
}
.share-list hr {
  margin: 10px;
  width: 15px;
  height: 1px;
  background-color: #999999;
  border: 0;
}
.share-list .shareBar {
  display: flex;
  align-items: center;
  border-radius: 17.5px;
  transition: background-color 1s;
  color: #999999;
}
.share-list .shareBar .icon {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-list .shareBar .txt {
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.5s;
}
.share-list .shareBar:hover {
  color: #fff;
}
.share-list .shareBar:hover .txt {
  max-width: 80px;
  margin-right: 15px;
}
.share-list .shareBar:hover.fb {
  background-color: #3b5997;
}
.share-list .shareBar:hover.yt {
  background-color: #ff0000;
}
.share-list .shareBar:hover.line {
  background-color: #00ba01;
}
.share-list .shareBar:hover.twitter {
  background-color: #1da1f2;
}
.share-list .shareBar + .shareBar {
  margin-left: 10px;
}
.share-list.vt {
  flex-direction: column;
  align-items: flex-start;
}
.share-list.vt .shareBar {
  color: #fff;
}
.share-list.vt .shareBar svg {
  width: 24px;
  height: 24px;
}
.share-list.vt .shareBar .txt {
  max-width: 80px;
  margin-right: 15px;
}
.share-list.vt .shareBar.fb {
  background-color: #3b5997;
}
.share-list.vt .shareBar.yt {
  background-color: #ff0000;
}
.share-list.vt .shareBar.line {
  background-color: #00ba01;
}
.share-list.vt .shareBar.twitter {
  background-color: #1da1f2;
}
.share-list.vt .shareBar.ig {
  background: url("../image/igBg.jpg") no-repeat;
}
.share-list.vt .shareBar + .shareBar {
  margin-left: 0;
  margin-top: 15px;
}
.share-list.autowrap {
  flex-wrap: wrap;
}
.share-list.autowrap .share-text {
  width: 100%;
}
.share-list.autowrap .bars-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  margin: -20px 0 0 -20px;
}
.share-list.autowrap .shareBar {
  color: #fff;
  margin: 20px 0 0 20px;
}
.share-list.autowrap .shareBar svg {
  width: 24px;
  height: 24px;
}
.share-list.autowrap .shareBar .txt {
  max-width: 80px;
  margin-right: 15px;
}
.share-list.autowrap .shareBar.fb {
  background-color: #3b5997;
}
.share-list.autowrap .shareBar.yt {
  background-color: #ff0000;
}
.share-list.autowrap .shareBar.line {
  background-color: #00ba01;
}
.share-list.autowrap .shareBar.twitter {
  background-color: #1da1f2;
}
.share-list.autowrap .shareBar.ig {
  background: url("../image/igBg.jpg") no-repeat;
}
.share-list.autowrap .shareBar + .shareBar {
  margin: 20px 0 0 20px;
}

/* 造訪驗證 */
.visitsBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: url("../image/visitsBg.jpg") no-repeat 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 100;
}

#visits-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, -20%, 0);
  color: #fff;
}
#visits-form .title {
  display: block;
  background: url("../image/visitsLogo.png") no-repeat 50% 50%;
  width: 365px;
  height: 315px;
  margin: 0;
  animation: visitsShow 0.8s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
#visits-form .inputBox {
  display: flex;
  position: relative;
  width: 300px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 3px;
  box-sizing: border-box;
  margin: 35px 0 0;
  transition: border-color 0.3s ease-in-out, width 0.8s cubic-bezier(0.53, 0.07, 0.93, 0.44);
  overflow: hidden;
  animation: visitsShow 0.8s cubic-bezier(0.37, 0.99, 0.92, 0.96) 0.1s;
}
#visits-form .inputBox .passBox {
  flex: 1;
  background: transparent;
  padding-left: 13px;
  box-sizing: border-box;
  border: none;
  font-size: 13px;
  font-family: "Barlow";
  color: white;
  letter-spacing: 1.5px;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, black 100%);
  opacity: 0.3;
}
#visits-form .inputBox .subBtn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.35);
  width: 50px;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
}
#visits-form .inputBox .subBtn .sprite {
  fill: white;
  transition: fill 0.3s ease-in-out;
}
#visits-form .inputBox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #eeeeee;
  width: calc(100% - 50px);
  height: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25) inset;
  transform: translate3d(-100%, 0, 0);
  /*transition: .8s ease-in;*/
}
#visits-form .inputBox.sending {
  border-color: white;
}
#visits-form .inputBox.sending .subBtn {
  background-color: white;
}
#visits-form .inputBox.sending .subBtn .sprite {
  fill: #333333;
}
#visits-form .inputBox.sending::before {
  animation: visitsLoad 0.8s ease-in-out both;
  transform: translate3d(0, 0, 0);
}
#visits-form .inputBox.success {
  align-self: flex-end;
  width: 0;
  border: none;
  margin-right: calc((100% - 300px) / 2);
}
#visits-form .inputBox.success::before {
  transform: translate3d(0, 0, 0);
  width: 100%;
}
#visits-form .inputBox.error::before {
  transform: translate3d(0, 0, 0);
  width: 100%;
}
#visits-form .inputBox:hover {
  border-color: white;
}
#visits-form .inputBox:hover .subBtn {
  background-color: white;
}
#visits-form .inputBox:hover .subBtn .sprite {
  fill: #333333;
}
#visits-form .errorMsg {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 2.143;
  text-align: center;
}
#visits-form .errorMsg.sprite {
  fill: rgba(255, 255, 255, 0.6);
  width: 16px;
  height: 16px;
  margin: -3.4px 5px 0 0;
}
#visits-form input.passBox:-webkit-autofill {
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.1) inset;
  transition: background-color 999999s ease-in;
}
@media (max-width: 500px) {
  #visits-form .title {
    background-size: contain;
    width: 100%;
    max-width: 100%;
  }
}

/* aside-bottom */
.aside-bottom {
  position: relative;
  z-index: 1;
  background-color: #ED6B28;
  padding: 60px;
  overflow: hidden;
}
.aside-bottom .aside-info {
  position: relative;
  z-index: 2;
  display: flex;
}
.aside-bottom .item {
  position: relative;
  flex: none;
  width: 50%;
  font-size: 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}
.aside-bottom .item .restrict svg {
  width: 40px;
  height: 40px;
}
.aside-bottom .item .title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
}
.aside-bottom .item .note {
  margin-top: 15px;
  line-height: 1.5em;
  max-width: 500px;
  text-align: center;
  font-size: 16px;
}
.aside-bottom .item .link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 25px;
  border: 1px solid #fff;
  border-radius: 25px;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 2px;
}
.aside-bottom .item .link:hover {
  color: #ED6B28;
  background-color: #fff;
}
.aside-bottom .item + .item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  background-color: #fff;
}
@media (max-width: 900px) {
  .aside-bottom .bg1 {
    top: -95px;
    left: -175px;
  }
  .aside-bottom .bg2 {
    top: -245px;
    right: -100px;
    bottom: auto;
  }
}
@media (max-width: 768px) {
  .aside-bottom {
    padding: 20px;
  }
}
@media (max-width: 600px) {
  .aside-bottom .bg1 {
    left: -165px;
    top: -50px;
    background-size: 80%;
  }
  .aside-bottom .bg2 {
    background-size: 80%;
    top: auto;
    bottom: -90px;
    right: -180px;
  }
  .aside-bottom .aside-info {
    flex-direction: column;
    align-items: center;
  }
  .aside-bottom .item {
    width: 100%;
  }
  .aside-bottom .item + .item {
    margin-top: 20px;
    padding-top: 20px;
  }
  .aside-bottom .item + .item::before {
    right: 0px;
    bottom: auto;
    width: auto;
    height: 1px;
  }
}
@media (max-width: 425px) {
  .aside-bottom .bg1 {
    background-size: 60%;
  }
  .aside-bottom .bg2 {
    background-size: 60%;
  }
}

.home-block {
  position: relative;
  margin-top: 55px;
}
.home-block .irnfont {
  font-family: irnfont;
}
.home-block .wave {
  position: absolute;
  z-index: 1;
  top: -90px;
  left: 0;
  right: 0;
  height: 90px;
  background: center no-repeat;
  background-size: cover;
}
.home-block .title {
  display: block;
  font-size: 32px;
  font-family: irnfont;
}
.home-block .subtitle {
  display: inline-block;
  color: #fff;
  background-color: #cb9464;
  padding: 3px 6px;
  margin-top: 21px;
  letter-spacing: 1.5px;
}
.home-block .tag {
  position: absolute;
  z-index: 3;
  color: #fff;
  font-size: 19px;
  background-color: #ED6B28;
  display: inline-block;
  writing-mode: vertical-lr;
  padding: 10px 3px;
}
.home-block .caption {
  position: relative;
  z-index: 8;
}
.home-block .caption .text {
  margin-top: 15px;
  line-height: 1.8em;
  font-size: 17px;
}
.home-block .col2 {
  max-width: 1480px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.home-block .col2 .left,
.home-block .col2 .right {
  position: relative;
  flex: none;
  width: calc(50% - 40px);
}
.home-block .block1 {
  position: relative;
  z-index: 1;
  height: 1000px;
  padding-top: 115px;
}
.home-block .block1 .drawing1 {
  position: absolute;
  z-index: 3;
  top: 20%;
  right: 48%;
  margin: 0;
  max-width: 100%;
}
.home-block .block1 .drawing2 {
  position: absolute;
  z-index: 3;
  top: 2%;
  right: 9%;
  margin: 0;
  max-width: 100%;
}
.home-block .block1 .plum {
  position: absolute;
  z-index: 3;
  top: 30%;
  right: 8%;
  margin: 0;
  max-width: 100%;
}
.home-block .block1 .plum1 {
  position: absolute;
  z-index: 3;
  top: 13%;
  left: 7%;
  margin: 0;
  max-width: 100%;
}
.home-block .block1 .plum2 {
  position: absolute;
  z-index: 3;
  bottom: 20%;
  left: -2%;
  margin: 0;
  max-width: 100%;
}
.home-block .block1 .leaf4 {
  position: absolute;
  z-index: 3;
  bottom: 33%;
  left: 39%;
  margin: 0;
  max-width: 100%;
}
.home-block .block1 .grassland {
  position: absolute;
  z-index: 2;
  left: -20px;
  right: -20px;
  top: 19%;
  margin: 0;
  height: 1200px;
  background: url("/upload/home/grassland.png") center top no-repeat;
  background-size: cover;
}
.home-block .block1 .tree {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 12%;
  margin: 0;
  max-width: 100%;
}
.home-block .block1 .girl {
  position: absolute;
  z-index: 3;
  top: 20%;
  left: 15%;
  margin: 0;
  max-width: 100%;
}
.home-block .block1 .caption {
  margin-left: 53.2%;
  width: 620px;
}
.home-block .block1 .caption .text {
  line-height: 2.4em;
}
.home-block .block1 .scroll {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: -115px;
  margin: 0 auto;
  background: url("/upload/home/scroll.png");
  width: 109px;
  height: 98px;
  display: none;
}
.home-block .block2 {
  position: relative;
  z-index: 2;
  background-color: #ccddc9;
  padding: 50px 0;
}
.home-block .block2 .wave {
  top: -270px;
  height: 270px;
  background-image: url("/upload/home/story1Bg.svg");
}
.home-block .block2 .bubble {
  position: absolute;
  z-index: 1;
  top: 40%;
  left: 7%;
}
.home-block .block2 .plum4 {
  position: absolute;
  z-index: 1;
  top: -13%;
  right: 6%;
}
.home-block .block2 .plum5 {
  position: absolute;
  z-index: 1;
  bottom: 15%;
  left: 35.6%;
}
.home-block .block2 .leaf5 {
  position: absolute;
  z-index: 3;
  top: -65%;
  right: 4.7%;
}
.home-block .block2 .leaf6 {
  position: absolute;
  z-index: 3;
  top: -20%;
  right: 24%;
}
.home-block .block2 .leaf1 {
  position: absolute;
  z-index: 1;
  top: -125px;
  left: -90px;
}
.home-block .block2 .leaf2 {
  position: absolute;
  z-index: 1;
  top: 225px;
  right: -295px;
}
.home-block .block2 .mulberry1 {
  position: absolute;
  z-index: 3;
  top: -5%;
  right: 48%;
}
.home-block .block2 .fresh-mulberry {
  position: absolute;
  z-index: 2;
  top: -76%;
  right: 0.7%;
}
.home-block .block2 .img {
  position: relative;
  z-index: 2;
  margin: -80px 0 0 56px;
}
.home-block .block2 .tag {
  top: 14px;
  left: 136px;
}
.home-block .block3 {
  position: relative;
  z-index: 3;
  background-color: #e9d8c0;
  padding: 202px 0 45px;
}
.home-block .block3 .wave {
  background-image: url("/upload/home/story2Bg.svg");
}
.home-block .block3 .bubble1 {
  position: absolute;
  z-index: 1;
  top: 12%;
  right: 20%;
}
.home-block .block3 .bubble2 {
  position: absolute;
  z-index: 1;
  bottom: 18%;
  right: 1%;
}
.home-block .block3 .bubble3 {
  position: absolute;
  z-index: 1;
  top: -8%;
  left: -1%;
}
.home-block .block3 .leaf {
  position: absolute;
  z-index: 1;
  top: 2%;
  left: -10.4%;
}
.home-block .block3 .plum4 {
  position: absolute;
  z-index: 1;
  top: 1%;
  right: 3%;
}
.home-block .block3 .plum5 {
  position: absolute;
  z-index: 1;
  bottom: 24%;
  left: -2%;
}
.home-block .block3 .story2-plum {
  position: absolute;
  z-index: 3;
  bottom: -30%;
  right: -10%;
}
.home-block .block3 .col2 {
  max-width: 1520px;
}
.home-block .block3 .col2 .left,
.home-block .block3 .col2 .right {
  position: relative;
  flex: none;
  width: calc(50% - 60px);
}
.home-block .block3 .img {
  position: relative;
  z-index: 2;
  margin: -367px 0 0 -90px;
}
.home-block .block3 .tag {
  top: 32px;
  right: -12px;
}
.home-block .block3 .subtitle {
  margin-top: 23px;
}
.home-block .block3 .caption .text {
  margin-top: 25px;
}
.home-block .block4 {
  position: relative;
  z-index: 4;
  background-color: #ccddc9;
  padding: 315px 0 50px;
}
.home-block .block4 .wave {
  background-image: url("/upload/home/story3Bg.svg");
}
.home-block .block4 .plum {
  position: absolute;
  z-index: 1;
  top: 16%;
  left: 5%;
}
.home-block .block4 .bubble1 {
  position: absolute;
  z-index: 1;
  top: 18%;
  left: 29%;
}
.home-block .block4 .bubble2 {
  position: absolute;
  z-index: 1;
  bottom: 19%;
  right: 0%;
}
.home-block .block4 .greenlemon1 {
  position: absolute;
  z-index: 1;
  top: -10%;
  left: -10%;
}
.home-block .block4 .greenlemon2 {
  position: absolute;
  z-index: 3;
  bottom: 8%;
  left: 60%;
}
.home-block .block4 .greenlemon3 {
  position: absolute;
  z-index: 1;
  bottom: 48%;
  right: -20%;
}
.home-block .block4 .caption .text {
  margin-top: 25px;
}
.home-block .block4 .img {
  position: relative;
  z-index: 2;
  margin: -345px 0 0 10px;
}
.home-block .block5 {
  position: relative;
  z-index: 5;
  background-color: #e9d8c0;
  padding: 260px 0 10px;
}
.home-block .block5 .wave {
  background-image: url("/upload/home/story4Bg.svg");
}
.home-block .block5 .plum {
  position: absolute;
  z-index: 1;
  bottom: 28%;
  right: -2%;
}
.home-block .block5 .bubble {
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 7%;
}
.home-block .block5 .mulberry1 {
  position: absolute;
  z-index: 1;
  top: -3.6%;
  left: 5.8%;
}
.home-block .block5 .mulberry2 {
  position: absolute;
  z-index: 1;
  top: 13.5%;
  right: 9.2%;
}
.home-block .block5 .subtitle {
  margin-top: 15px;
}
.home-block .block5 .tag {
  top: -157px;
  left: 44px;
}
.home-block .block5 .img {
  position: relative;
  z-index: 2;
  margin: -180px 0 0 0;
}
.home-block .block5 .col2 {
  max-width: 1520px;
}
.home-block .block5 .col2 .left,
.home-block .block5 .col2 .right {
  position: relative;
  flex: none;
  width: calc(50% - 60px);
}
.home-block .block5 .caption .text {
  margin-top: 25px;
}
.home-block .products {
  position: relative;
  z-index: 6;
  background: url("/upload/home/bg5.jpg") center top no-repeat;
  padding: 180px 0 0;
}
.home-block .products .wave {
  top: 0;
  background-image: url("/upload/home/productsBg.svg");
}
.home-block .products .category-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-block .products .category-wrap .flag {
  max-width: 100%;
}
.home-block .products .category-wrap .category {
  margin-top: 30px;
  overflow: hidden;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
}
.home-block .products .category-wrap .category .item {
  position: relative;
  font-size: 19px;
}
.home-block .products .category-wrap .category .item + .item {
  margin-left: 30px;
  padding-left: 30px;
}
.home-block .products .category-wrap .category .item + .item::before {
  content: "";
  position: absolute;
  top: 3px;
  left: -6px;
  width: 1px;
  height: 20px;
  background-color: #4d4d4d;
}
.home-block .products .title {
  margin: 0 auto;
}
.home-block .products .caption {
  margin-top: 80px;
  text-align: center;
}
.home-block .products .caption .text {
  margin-top: 27px;
}
.home-block .products .list-wrap {
  margin-top: 60px;
}
.home-block .products .recommend-list {
  position: relative;
  display: none;
  margin: 30px auto 0;
  max-width: 1400px;
}
.home-block .products .recommend-list .item {
  display: flex;
  flex-direction: column;
  max-width: 260px;
}
.home-block .products .recommend-list .item .info {
  text-align: center;
}
.home-block .products .recommend-list .item .info .name {
  color: #482e23;
  font-weight: bold;
  font-size: 19px;
  margin-top: 23px;
}
.home-block .products .recommend-list .item .info .subname {
  color: #524643;
  font-size: 13px;
  margin-top: 11px;
}
.home-block .products .recommend-list .item .info .price-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}
.home-block .products .recommend-list .item .info .price-info .special {
  background-color: #ffa200;
  padding: 0 2px;
  color: #fff;
  margin-right: 6px;
}
.home-block .products .recommend-list .item .info .price-info .price {
  color: #ffa200;
  font-size: 21px;
  font-weight: bold;
}
.home-block .products .recommend-list .item:hover .info .name {
  color: #ED6B28;
}
.home-block .products .recommend-list .swiper-container {
  max-width: 1160px;
}
.home-block .products .recommend-list .swiper-slide {
  overflow: hidden;
}
.home-block .products .recommend-list .swiper-slide:hover {
  border-color: #ED6B28;
}
.home-block .products .recommend-list .swiper-button-prev,
.home-block .products .recommend-list .swiper-button-next {
  position: absolute;
  z-index: 2px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  background-image: none;
  color: #ED6B28;
  margin: 0;
}
.home-block .products .recommend-list .swiper-button-prev svg,
.home-block .products .recommend-list .swiper-button-next svg {
  width: 50px;
  height: 50px;
}
.home-block .products .recommend-list .swiper-button-prev.swiper-button-disabled,
.home-block .products .recommend-list .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}
.home-block .products .recommend-list .swiper-button-prev {
  right: 0;
}
.home-block .products .recommend-list .swiper-button-next {
  left: auto;
}
.home-block .products .recommend-list.selected {
  display: block;
}
.home-block .products .buy-now {
  display: flex;
  justify-content: center;
  padding-top: 14px;
  margin: 50px auto;
  background: url("/upload/home/buynow.png") center no-repeat;
  background-size: contain;
  width: 204px;
  height: 107px;
  color: #fff;
}
@media (max-width: 1860px) {
  .home-block .block1 .plum {
    right: 1%;
  }
}
@media (max-width: 1760px) {
  .home-block .block2 .img,
.home-block .block3 .img,
.home-block .block4 .img,
.home-block .block5 .img {
    max-width: 100%;
  }
  .home-block .block1 .tree {
    left: 7%;
  }
  .home-block .block1 .girl {
    left: 8%;
  }
  .home-block .block2 .fresh-mulberry {
    max-width: 450px;
    top: -60%;
    right: 5%;
  }
  .home-block .block2 .tag {
    left: 60px;
  }
  .home-block .block2 .img {
    margin: -80px 0 0;
  }
  .home-block .block3 .tag {
    top: -30px;
    right: 100px;
  }
  .home-block .block4 .greenlemon2 {
    bottom: 3%;
    left: 50%;
  }
  .home-block .block4 .greenlemon3 {
    right: -5%;
  }
}
@media (max-width: 1680px) {
  .home-block .block1 .plum1 {
    top: 25%;
    left: 2%;
  }
  .home-block .block1 .girl {
    max-width: 250px;
    top: 27%;
    left: 12%;
  }
  .home-block .block2 .plum5 {
    bottom: 5%;
    left: 35%;
  }
  .home-block .block2 .leaf5 {
    top: -60%;
    right: 9%;
  }
  .home-block .block3 .col2 {
    max-width: 1480px;
  }
  .home-block .block3 .img {
    margin: -210px 0 0;
  }
  .home-block .block3 .story2-plum {
    max-width: 220px;
    bottom: -20%;
    right: -13%;
  }
  .home-block .block4 .img {
    margin: -290px 0 0;
  }
  .home-block .block5 .plum {
    bottom: 0;
    max-width: 130px;
  }
  .home-block .block5 .tag {
    left: 90px;
  }
  .home-block .block5 .img {
    margin: -120px 0 0 85px;
  }
  .home-block .block5 .col2 {
    max-width: 1480px;
  }
}
@media (max-width: 1540px) {
  .home-block .col2 {
    margin: 0 30px;
  }
  .home-block .block1 .plum {
    right: -3%;
    top: 45%;
  }
  .home-block .block1 .leaf4 {
    display: none;
  }
  .home-block .block1 .grassland {
    top: 25%;
    height: 1000px;
  }
  .home-block .block2 .leaf6 {
    right: 27%;
  }
  .home-block .block3 .tag {
    right: 10px;
  }
  .home-block .block4 .greenlemon3 {
    right: 5%;
  }
}
@media (max-width: 1360px) {
  .home-block .caption .text {
    font-size: 18px;
  }
  .home-block .block1 .tree {
    max-width: 700px;
    top: 0;
    left: 3%;
  }
  .home-block .block1 .plum1 {
    max-width: 50px;
  }
  .home-block .block1 .girl {
    max-width: 200px;
    top: 22%;
  }
  .home-block .block1 .grassland {
    top: 16%;
  }
  .home-block .block1 .caption {
    width: 43%;
    margin-left: auto;
    margin-right: 30px;
  }
  .home-block .block1 .caption .text {
    line-height: 2em;
  }
  .home-block .block2 .fresh-mulberry {
    max-width: 380px;
    top: -65%;
    right: 10%;
  }
  .home-block .block2 .leaf1,
.home-block .block2 .leaf2 {
    opacity: 0.5;
  }
  .home-block .block2 .leaf5 {
    right: 13%;
    max-width: 70px;
  }
  .home-block .block2 .leaf6 {
    right: 30%;
    max-width: 90px;
  }
  .home-block .block2 .img {
    margin-top: 0;
  }
  .home-block .block3 .leaf {
    left: -15%;
    opacity: 0.5;
  }
  .home-block .block3 .tag {
    right: -25px;
  }
  .home-block .block3 .img {
    margin-top: -190px;
  }
  .home-block .block4 .img {
    margin-top: -250px;
  }
}
@media (max-width: 1260px) {
  .home-block .tag {
    font-size: 17px;
    padding: 6px 3px;
  }
  .home-block .col2 .left,
.home-block .col2 .right {
    width: calc(50% - 10px);
  }
  .home-block .block1 {
    height: 730px;
    padding-top: 70px;
  }
  .home-block .block1 .drawing1 {
    right: 50%;
  }
  .home-block .block1 .drawing2 {
    top: -20px;
  }
  .home-block .block1 .plum {
    right: -7%;
  }
  .home-block .block1 .plum1 {
    top: 15%;
    left: -20px;
  }
  .home-block .block1 .plum2 {
    display: none;
  }
  .home-block .block1 .girl {
    max-width: 165px;
    top: 23%;
    left: 10%;
  }
  .home-block .block1 .tree {
    max-width: 600px;
  }
  .home-block .block1 .grassland {
    height: 800px;
  }
  .home-block .block1 .caption {
    margin-right: 60px;
  }
  .home-block .block2 {
    padding: 50px 0 80px;
  }
  .home-block .block2 .mulberry1 {
    max-width: 90px;
    top: -12%;
    right: auto;
    left: 40%;
  }
  .home-block .block2 .plum4 {
    display: none;
  }
  .home-block .block2 .plum5 {
    bottom: 30px;
    left: 45%;
    max-width: 60px;
  }
  .home-block .block2 .fresh-mulberry {
    max-width: 330px;
    top: -45%;
  }
  .home-block .block2 .leaf5 {
    top: -42%;
  }
  .home-block .block2 .leaf6 {
    top: -10%;
    max-width: 70px;
  }
  .home-block .block2 .leaf1 {
    left: -280px;
  }
  .home-block .block2 .leaf2 {
    top: 110px;
    right: -530px;
  }
  .home-block .block2 .bubble {
    left: -35px;
    max-width: 70px;
  }
  .home-block .block2 .tag {
    left: 10px;
  }
  .home-block .block3 .plum4 {
    display: none;
  }
  .home-block .block3 .bubble1 {
    top: 22%;
    right: 3%;
    max-width: 70px;
  }
  .home-block .block3 .bubble2,
.home-block .block3 .bubble3 {
    display: none;
  }
  .home-block .block3 .story2-plum {
    max-width: 190px;
    right: -3%;
  }
  .home-block .block3 .leaf {
    left: -35%;
  }
  .home-block .block3 .img {
    margin-left: -60px;
  }
  .home-block .block3 .tag {
    right: 25px;
  }
  .home-block .block3 .col2 .left,
.home-block .block3 .col2 .right {
    width: calc(50% - 10px);
  }
  .home-block .block4 {
    padding: 270px 0 80px;
  }
  .home-block .block4 .plum {
    left: -50px;
    max-width: 130px;
  }
  .home-block .block4 .bubble2 {
    display: none;
  }
  .home-block .block4 .greenlemon3 {
    right: -3%;
  }
  .home-block .block4 .img {
    margin-top: -160px;
  }
  .home-block .block4 .col2 .left,
.home-block .block4 .col2 .right {
    width: calc(50% - 10px);
  }
  .home-block .block5 .plum {
    display: none;
  }
  .home-block .block5 .bubble {
    top: 45%;
    left: 0;
    max-width: 70px;
  }
  .home-block .block5 .mulberry1 {
    top: 30px;
    left: 0;
    max-width: 100px;
  }
  .home-block .block5 .mulberry2 {
    right: 0;
    max-width: 80px;
  }
  .home-block .block5 .tag {
    left: 10px;
  }
  .home-block .block5 .img {
    margin: -90px 0 0;
  }
  .home-block .block5 .col2 .left,
.home-block .block5 .col2 .right {
    width: calc(50% - 10px);
  }
  .home-block .products .recommend-list .swiper-container {
    margin: 0 50px;
  }
}
@media (max-width: 1024px) {
  .home-block .block1 .grassland {
    top: 28%;
    height: 700px;
  }
  .home-block .block1 .girl {
    top: 30%;
  }
  .home-block .block1 .caption {
    width: 50%;
  }
  .home-block .block3 .story2-plum {
    max-width: 150px;
    right: 0;
    bottom: -10%;
  }
  .home-block .block4 .greenlemon3 {
    right: 5%;
  }
  .home-block .products .caption {
    margin: 80px 20px 0;
  }
  .home-block .products .recommend-list .item .info .price-info .price {
    font-size: 17px;
  }
}
@media (max-width: 900px) {
  .home-block .caption .text {
    font-size: 15px;
  }
  .home-block .block1 .tree {
    top: 0;
    left: 0;
  }
  .home-block .block1 .grassland {
    height: 620px;
  }
  .home-block .block1 .drawing1 {
    max-width: 25px;
  }
  .home-block .block1 .drawing2 {
    top: 10px;
    max-width: 70px;
  }
  .home-block .block1 .plum1 {
    top: 23%;
  }
  .home-block .block1 .plum {
    right: -4%;
    max-width: 70px;
  }
  .home-block .block1 .caption .text {
    font-size: 16px;
  }
  .home-block .block2 .mulberry1 {
    top: -10%;
    left: 5%;
    max-width: 75px;
  }
  .home-block .block2 .fresh-mulberry {
    max-width: 280px;
    top: -25%;
  }
  .home-block .block2 .leaf5 {
    top: -22%;
    max-width: 55px;
  }
  .home-block .block2 .leaf6 {
    top: 10%;
    max-width: 55px;
  }
  .home-block .block2 .img {
    margin-top: 70px;
  }
  .home-block .block3 .plum5 {
    display: none;
  }
  .home-block .block3 .bubble1 {
    top: 15%;
  }
  .home-block .block3 .story2-plum {
    bottom: -20%;
  }
  .home-block .block3 .img {
    margin-top: -65px;
  }
  .home-block .block4 {
    padding-top: 150px;
  }
  .home-block .block4 .img {
    margin: -140px 0 0 -30px;
    max-width: 135%;
  }
  .home-block .block4 .greenlemon1 {
    top: 0;
    left: 6%;
    max-width: 30px;
  }
  .home-block .block4 .greenlemon2 {
    bottom: 0;
    max-width: 30px;
  }
  .home-block .block4 .greenlemon3 {
    right: -18%;
  }
  .home-block .block4 .plum {
    max-width: 90px;
  }
  .home-block .block5 {
    padding-top: 140px;
  }
  .home-block .block5 .mulberry1 {
    top: 100px;
    left: -15px;
    max-width: 90px;
  }
  .home-block .block5 .mulberry2 {
    top: 10%;
    max-width: 65px;
  }
  .home-block .block5 .tag {
    top: 0;
  }
  .home-block .block5 .img {
    margin: 60px 0 0;
  }
}
@media (max-width: 768px) {
  .home-block .wave {
    top: -40px;
    height: 40px;
  }
  .home-block .block1 {
    height: 650px;
  }
  .home-block .block1 .drawing1 {
    display: none;
  }
  .home-block .block1 .girl {
    top: 34%;
    left: 5%;
    max-width: 125px;
  }
  .home-block .block1 .tree {
    left: -80px;
    max-width: 550px;
  }
  .home-block .block1 .caption {
    width: 60%;
    margin-right: 30px;
  }
  .home-block .block2 .wave {
    top: -130px;
    height: 130px;
  }
  .home-block .block2 .leaf5,
.home-block .block2 .leaf6 {
    display: none;
  }
  .home-block .block2 .fresh-mulberry {
    max-width: 200px;
    top: -20%;
    right: 5%;
  }
  .home-block .block2 .home-block .block2 .plum5 {
    bottom: 5px;
    left: 48%;
    max-width: 45px;
  }
  .home-block .block2 .img {
    margin-top: 50px;
    max-width: 105%;
  }
  .home-block .block3 .leaf {
    top: 10%;
    left: -50%;
  }
  .home-block .block3 .bubble1 {
    top: 20%;
    max-width: 50px;
  }
  .home-block .block4 .greenlemon1 {
    top: -7%;
  }
  .home-block .block4 .bubble1 {
    left: 40%;
    max-width: 60px;
  }
  .home-block .block4 .plum {
    display: none;
  }
  .home-block .block5 .bubble {
    display: none;
  }
}
@media (max-width: 650px) {
  .home-block .wave {
    position: relative;
  }
  .home-block .col2 {
    flex-direction: column;
  }
  .home-block .col2 .left,
.home-block .col2 .right {
    width: auto;
  }
  .home-block .block1 {
    height: auto;
    padding-top: 0;
  }
  .home-block .block1 .tree {
    position: relative;
    left: 10%;
  }
  .home-block .block1 .grassland {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: -260px;
    height: 700px;
  }
  .home-block .block1 .girl {
    top: 30%;
    left: 20%;
    max-width: 160px;
  }
  .home-block .block1 .drawing2 {
    top: 57%;
  }
  .home-block .block1 .plum,
.home-block .block1 .plum1,
.home-block .block1 .plum2 {
    display: none;
  }
  .home-block .block1 .caption {
    width: auto;
    margin: -380px 30px 140px;
  }
  .home-block .block1 .caption .text {
    text-shadow: 1px 0 0px white, -1px 0 0px white, 0 1px 0px white, 0 -1px 0px white;
    font-weight: bold;
  }
  .home-block .block2 {
    padding: 0 0 30px;
  }
  .home-block .block2 .wave {
    top: -90px;
    height: 90px;
  }
  .home-block .block2 .leaf1,
.home-block .block2 .leaf2,
.home-block .block2 .bubble,
.home-block .block2 .fresh-mulberry {
    display: none;
  }
  .home-block .block2 .mulberry1 {
    top: -5%;
    left: 0;
    max-width: 60px;
  }
  .home-block .block2 .plum5 {
    top: 40%;
    right: 50%;
    max-width: 45px;
  }
  .home-block .block2 .tag {
    top: 10%;
    left: 5%;
  }
  .home-block .block2 .col2 {
    margin-top: -110px;
    margin-bottom: 30px;
  }
  .home-block .block2 .col2 .left {
    order: 2;
  }
  .home-block .block2 .col2 .right {
    order: 1;
  }
  .home-block .block2 .img {
    margin-top: 30px;
    max-width: 100%;
  }
  .home-block .block3 {
    padding: 0 0 30px;
  }
  .home-block .block3 .col2 .left,
.home-block .block3 .col2 .right {
    width: auto;
  }
  .home-block .block3 .img {
    margin: 0 auto;
    max-width: 65%;
  }
  .home-block .block3 .bubble1 {
    display: none;
  }
  .home-block .block3 .leaf {
    top: 15%;
  }
  .home-block .block3 .story2-plum {
    right: 19%;
    bottom: -15%;
    max-width: 140px;
  }
  .home-block .block3 .caption {
    margin-top: 45px;
  }
  .home-block .block3 .tag {
    top: 5%;
    right: 5%;
  }
  .home-block .block4 {
    padding: 0 0 30px;
  }
  .home-block .block4 .col2 {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .home-block .block4 .col2 .left,
.home-block .block4 .col2 .right {
    width: auto;
  }
  .home-block .block4 .col2 .left {
    order: 2;
    margin-top: 20px;
  }
  .home-block .block4 .col2 .right {
    order: 1;
  }
  .home-block .block4 .greenlemon1 {
    display: none;
  }
  .home-block .block4 .greenlemon2 {
    bottom: 2%;
    right: 50%;
  }
  .home-block .block4 .greenlemon3 {
    bottom: 15%;
    right: 5%;
    max-width: 50px;
  }
  .home-block .block4 .bubble1 {
    top: 13%;
    left: 2%;
  }
  .home-block .block4 .img {
    margin: 30px 0 0;
    max-width: 100%;
  }
  .home-block .block5 {
    padding-top: 0;
  }
  .home-block .block5 .col2 {
    margin-top: 60px;
  }
  .home-block .block5 .col2 .left,
.home-block .block5 .col2 .right {
    width: auto;
  }
  .home-block .block5 .mulberry1,
.home-block .block5 .mulberry2 {
    display: none;
  }
  .home-block .products {
    padding-top: 0;
  }
  .home-block .products .category-wrap {
    margin: 60px 20px 0;
  }
  .home-block .products .caption {
    margin: 40px 20px 0;
    text-align: left;
  }
}
@media (max-width: 550px) {
  .home-block {
    margin-top: 20px;
  }
  .home-block .col2 {
    margin: 0 10px;
  }
  .home-block .tag {
    font-size: 15px;
  }
  .home-block .block1 .girl {
    top: 32%;
  }
  .home-block .block1 .caption {
    margin-left: 10px;
    margin-right: 10px;
  }
  .home-block .block1 .caption .text {
    font-size: 14px;
    line-height: 1.7em;
  }
  .home-block .block2 .plum5 {
    display: none;
  }
  .home-block .block3 .leaf {
    display: none;
  }
  .home-block .block3 .img {
    max-width: 280px;
    margin: 0 0 0 30px;
  }
  .home-block .block3 .story2-plum {
    right: 10%;
    max-width: 100px;
  }
  .home-block .block4 .col2 {
    margin-top: 0;
  }
  .home-block .block4 .bubble1 {
    top: 3%;
  }
  .home-block .products .category-wrap {
    margin: 60px 20px 0;
  }
  .home-block .products .caption {
    margin: 40px 20px 0;
    text-align: left;
  }
  .home-block .products .recommend-list .swiper-container {
    margin: 0 20px;
  }
}
@media (max-width: 425px) {
  .home-block {
    margin-top: 0;
  }
  .home-block .block1 .drawing2 {
    top: 50%;
  }
  .home-block .block1 .tree {
    left: 5%;
    max-width: 450px;
    margin-top: -50px;
  }
  .home-block .block1 .girl {
    top: 24%;
    left: 20%;
    max-width: 140px;
  }
  .home-block .block1 .caption {
    margin: -400px 10px 160px;
  }
  .home-block .block1 .scroll {
    display: block;
  }
  .home-block .block3 .img {
    margin: 0;
  }
  .home-block .block3 .story2-plum {
    right: 5%;
  }
  .home-block .block4 .greenlemon2 {
    max-width: 20px;
  }
  .home-block .block4 .greenlemon3 {
    max-width: 35px;
  }
  .home-block .block5 .col2 {
    margin-top: 0px;
  }
  .home-block .block5 .img {
    margin-top: 30px;
  }
  .home-block .block5 .tag {
    top: 20px;
  }
  .home-block .products .recommend-list .item {
    max-width: none;
  }
}

@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.common-bg {
  clear: both;
  position: relative;
  display: block;
  box-sizing: border-box;
  background-image: url(/upload/innerBg.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  box-sizing: border-box;
  background-color: #514642;
  height: 400px;
  top: -10px;
  position: absolute;
  width: 100%;
}
@media (max-width: 1040px) {
  .common-bg {
    top: -30px;
  }
}
@media (max-width: 480px) {
  .common-bg {
    top: -40px;
  }
}

.common-banner {
  position: relative;
  z-index: 5;
  display: flex;
  height: 350px;
  max-width: 1640px;
  margin: 50px auto;
}
.common-banner .titleblock {
  background-repeat: no-repeat;
  background-position: top -20px left 0;
  background-color: #ED6B28;
  height: 350px;
  width: 31.708%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 75px 0 75px;
  min-width: 400px;
}
.common-banner .titleblock.b1 {
  background-image: url(/upload/news/number1.png);
}
.common-banner .titleblock.b2 {
  background-image: url(/upload/news/number2.png);
}
.common-banner .titleblock.b3 {
  background-image: url(/upload/news/number3.png);
}
.common-banner .titleblock.b4 {
  background-image: url(/upload/news/number4.png);
}
.common-banner .titleblock .title {
  margin-top: 16px;
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0;
}
.common-banner .titleblock .title2 {
  color: #fff;
  font-size: 24px;
  line-height: 1.6;
  font-weight: bold;
  margin-top: 6px;
}
.common-banner .titleblock .breadcrumb {
  margin-top: 36px;
}
.common-banner .titleblock .breadcrumb a:hover {
  color: #fff;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.common-banner .banner {
  clear: both;
  position: relative;
  display: block;
  box-sizing: border-box;
  /*background-image: url(/upload/news/innerBanner4.jpg);*/
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 350px;
  width: 68.292%;
}
@media (max-width: 1280px) {
  .common-banner .titleblock .title {
    font-size: 48px;
  }
  .common-banner .titleblock .title2 {
    font-size: 24px;
  }
}
@media (max-width: 1040px) {
  .common-banner {
    position: relative;
    z-index: 5;
    display: flex;
    height: 430px;
    max-width: 1640px;
    margin: 50px auto;
  }
  .common-banner .titleblock {
    background-position: top 20px left -20px;
    padding: 20px 10px 20px 125px;
    min-width: 350px;
    position: absolute;
    bottom: 0px;
    z-index: 6;
    background-size: 60%;
    height: auto;
  }
  .common-banner .titleblock .title {
    margin-top: 0;
    font-size: 28px;
  }
  .common-banner .titleblock .title2 {
    font-size: 20px;
  }
  .common-banner .titleblock .breadcrumb {
    margin-top: 6px;
  }
}

.article-wrap {
  position: relative;
  z-index: 1;
}
.article-wrap .swiper-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.article-wrap .swiper-wrapper .swiper-slide {
  width: auto;
  margin: 5px 20px;
}
.article-wrap .swiper-wrapper .swiper-slide .link {
  padding: 5px 15px;
  transition: all 0.3s;
}
.article-wrap .swiper-wrapper .swiper-slide .link:hover {
  background-color: #ED6B28;
  color: #fff;
  border-radius: 20px;
}
.article-wrap .swiper-wrapper .swiper-slide .link.selected {
  background-color: #ED6B28;
  color: #fff;
  border-radius: 20px;
}
@media (max-width: 750px) {
  .article-wrap .swiper-wrapper .swiper-slide {
    margin: 5px 10px;
  }
}
.article-wrap .article-block {
  padding: 60px 0 30px;
}
@media (max-width: 1040px) {
  .article-wrap .article-block {
    padding: 50px 0 30px;
  }
}
@media (max-width: 750px) {
  .article-wrap .article-block {
    padding-top: 30px;
  }
}
.article-wrap .article-list {
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.article-wrap .article-list .item {
  position: relative;
  display: flex;
  margin-bottom: 60px;
  width: calc(50% - 40px);
  transition: all 0.3s;
  z-index: 2;
  top: 0;
  left: 0;
}
.article-wrap .article-list .item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  z-index: -1;
  transition: all 0.3s;
}
.article-wrap .article-list .item .cover {
  position: relative;
  flex: none;
  width: 300px;
  height: 300px;
}
.article-wrap .article-list .item .cover .image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.article-wrap .article-list .item .info {
  flex: auto;
  background-color: #fff;
  padding: 24px 32px;
  border: 1px solid #eee;
  border-left: none;
}
.article-wrap .article-list .item .dateline {
  position: relative;
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
}
.article-wrap .article-list .item .dateline .cname {
  order: 1;
  color: #ED6B28;
  white-space: nowrap;
  font-weight: bold;
}
.article-wrap .article-list .item .dateline .date {
  order: 3;
  white-space: nowrap;
  color: #524643;
}
.article-wrap .article-list .item .dateline::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #D1C8C6;
  order: 2;
  margin: 0 10px;
}
.article-wrap .article-list .item .title {
  color: #524643;
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
}
.article-wrap .article-list .item .note {
  margin-top: 15px;
  line-height: 2em;
}
.article-wrap .article-list .item .more {
  display: inline-block;
}
.article-wrap .article-list .item .more a, .article-wrap .article-list .item .more span {
  color: #524643;
  margin-top: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: 1px #524643 solid;
  padding: 10px 16px;
  letter-spacing: 0;
}
.article-wrap .article-list .item .more a svg, .article-wrap .article-list .item .more span svg {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}
@media (max-width: 1280px) {
  .article-wrap .article-list .item {
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 1040px) {
  .article-wrap .article-list .item {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .article-wrap .article-list .item {
    padding-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .article-wrap .article-list .item {
    flex-direction: column;
  }
  .article-wrap .article-list .item .cover {
    width: 100%;
    order: 1;
    margin: 0;
  }
  .article-wrap .article-list .item .cover .image {
    position: absolute;
  }
  .article-wrap .article-list .item .cover::after {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
  .article-wrap .article-list .item .info {
    order: 2;
    padding: 32px 20px;
  }
  .article-wrap .article-list .item.first {
    padding-bottom: 30px;
  }
  .article-wrap .article-list .item.first .cover {
    width: 100%;
    height: auto;
    order: 1;
    margin: 0 0 20px;
  }
  .article-wrap .article-list .item.first .cover .image {
    position: absolute;
  }
  .article-wrap .article-list .item.first .info {
    order: 2;
  }
  .article-wrap .article-list .item.first .info .share-list {
    margin-top: 20px;
  }
}
.article-wrap .article-list .item:hover {
  left: -5px;
  top: -5px;
}
.article-wrap .article-list .item:hover::after {
  left: 10px;
  top: 10px;
}
.article-wrap .article-list .item:hover .title {
  color: #ED6B28;
}
.article-wrap .article-list .item:hover .note {
  color: #ED6B28;
}
.article-wrap .article-list .item:hover .more span {
  color: #ED6B28;
  border: 1px #ED6B28 solid;
}
.article-wrap .article-info .top-block {
  display: flex;
  justify-content: space-between;
}
.article-wrap .article-info .top-block .dateline {
  position: relative;
  display: flex;
  align-items: center;
  color: #666;
  font-size: 18px;
  width: 350px;
}
.article-wrap .article-info .top-block .dateline .cname {
  order: 1;
  color: #ED6B28;
  white-space: nowrap;
  font-weight: bold;
}
.article-wrap .article-info .top-block .dateline .date {
  order: 3;
  white-space: nowrap;
  color: #524643;
}
.article-wrap .article-info .top-block .dateline::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #D1C8C6;
  order: 2;
  margin: 0 10px;
}
@media (max-width: 680px) {
  .article-wrap .article-info .top-block {
    flex-direction: column;
  }
  .article-wrap .article-info .top-block .dateline {
    width: 100%;
    order: 2;
  }
  .article-wrap .article-info .top-block .share-list {
    justify-content: flex-end;
    order: 1;
  }
}
.article-wrap .article-info .title-block {
  display: flex;
  margin-top: 22px;
}
.article-wrap .article-info .title-block h1 {
  font-size: 32px;
  font-weight: bold;
  color: #482e23;
}
.article-wrap .article-info .info-desc {
  margin: 22px 0;
  padding: 25px 0;
  line-height: 2em;
}
@media (max-width: 750px) {
  .article-wrap .article-info .title-block {
    flex-wrap: wrap;
  }
  .article-wrap .article-info .title-block .date {
    margin: 0 0 15px;
    width: 100%;
  }
  .article-wrap .article-info .title-block h1 {
    font-size: 26px;
  }
  .article-wrap .article-info .info-desc {
    padding: 25px 0;
  }
}
.article-wrap .art-around {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.article-wrap .art-around a {
  display: block;
  width: calc(33.3333333333% - 20px);
  border: 1px solid transparent;
}
.article-wrap .art-around a:hover {
  border: 1px solid #333;
}
.article-wrap .art-around img {
  max-width: 100%;
  vertical-align: middle;
}
@media (max-width: 768px) {
  .article-wrap .art-around {
    flex-wrap: wrap;
  }
  .article-wrap .art-around a {
    width: calc(50% - 10px);
    margin-top: 20px;
  }
  .article-wrap .art-around a:nth-child(2) {
    margin: 20px auto 0;
    order: 3;
  }
}
@media (max-width: 480px) {
  .article-wrap .art-around {
    flex-wrap: wrap;
  }
  .article-wrap .art-around a {
    width: 100%;
    margin: 20px auto 0;
  }
  .article-wrap .art-around a:nth-child(2) {
    margin: 20px auto 0;
    order: 3;
  }
}
@media (max-width: 550px) {
  .article-wrap.contact .title-wrap, .article-wrap.sitemap .title-wrap {
    top: 230px;
  }
  .article-wrap.contact .title-wrap .title, .article-wrap.sitemap .title-wrap .title {
    font-size: 32px;
  }
}
@media (max-width: 425px) {
  .article-wrap.contact .title-wrap, .article-wrap.sitemap .title-wrap {
    top: 180px;
  }
  .article-wrap.contact .title-wrap .title, .article-wrap.sitemap .title-wrap .title {
    font-size: 28px;
  }
}
.article-wrap .contact-form {
  position: relative;
  display: flex;
  background-image: url("../image/vein1-green.png"), url("../image/vein3-green.png"), url("../image/contactForm.png");
  background-size: 344px 259px, 314px 205px, 700px 69px;
  background-position: -5% 110%, 105% -5%, 60px 70px;
  background-repeat: no-repeat;
  padding-top: 120px;
}
.article-wrap .contact-form .slogan {
  position: absolute;
}
.article-wrap .contact-form .left {
  flex: none;
  width: 250px;
  padding-top: 120px;
}
.article-wrap .contact-form .left .name {
  border-top: 5px solid #ED6B28;
  padding: 15px 0 65px;
  font-size: 32px;
  color: #ED6B28;
}
.article-wrap .contact-form .left .note {
  line-height: 1.7em;
}
.article-wrap .contact-form .left .required {
  margin-top: 15px;
  color: #ED6B28;
}
.article-wrap .contact-form .left .share-list {
  margin-top: 60px;
}
.article-wrap .contact-form .left .share-list .share-text {
  color: #482e23;
  font-size: 19px;
  font-weight: bold;
}
.article-wrap .contact-form .left .share-list .shareBar {
  height: 45px;
  border-radius: 22.5px;
  padding-left: 10px;
  margin-top: 15px;
  min-width: 170px;
}
.article-wrap .contact-form .right {
  margin-left: 110px;
  flex: auto;
}
.article-wrap .contact-form form .item {
  position: relative;
  background-color: #f0f5ee;
}
.article-wrap .contact-form form .item input[type=text], .article-wrap .contact-form form .item input[type=password], .article-wrap .contact-form form .item input[type=date], .article-wrap .contact-form form .item input[type=email], .article-wrap .contact-form form .item input[type=number] {
  width: 100%;
  height: 90px;
  padding: 25px 25px 25px 100px;
  border: 1px solid transparent;
  background-color: #f0f5ee;
}
.article-wrap .contact-form form .item input[type=text]:hover, .article-wrap .contact-form form .item input[type=password]:hover, .article-wrap .contact-form form .item input[type=date]:hover, .article-wrap .contact-form form .item input[type=email]:hover, .article-wrap .contact-form form .item input[type=number]:hover {
  border-color: #ED6B28;
}
.article-wrap .contact-form form .item textarea {
  margin-left: 100px;
  width: calc(100% - 100px);
  border: none;
  background-color: transparent;
  min-height: 180px;
}
.article-wrap .contact-form form .item input.rb {
  border-color: #e80009;
}
.article-wrap .contact-form form .item .label {
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.article-wrap .contact-form form .item .label.required::after {
  content: "*";
  color: #ED6B28;
  margin-left: 5px;
}
.article-wrap .contact-form form .item .gender {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.article-wrap .contact-form form .item .gender label {
  margin-left: 5px;
  font-size: 13px;
}
.article-wrap .contact-form form .item .gender input {
  width: 17px;
  height: 17px;
}
.article-wrap .contact-form form .item .gender label + input {
  margin-left: 20px;
}
.article-wrap .contact-form form .item.form-name input {
  padding-right: 150px;
}
.article-wrap .contact-form form .item.select {
  display: flex;
  width: 100%;
  height: 90px;
  border: 1px solid transparent;
  background-color: #f0f5ee;
}
.article-wrap .contact-form form .item.select select {
  flex: auto;
  background-color: transparent;
  border: none;
  margin-left: 30px;
  font-size: 17px;
}
.article-wrap .contact-form form .item.select .label {
  flex: none;
  position: relative;
}
.article-wrap .contact-form form .item.select:hover {
  border-color: #ED6B28;
}
.article-wrap .contact-form form .item.textarea {
  border: 1px solid transparent;
}
.article-wrap .contact-form form .item.textarea .label {
  display: inline-block;
  margin-top: 20px;
}
.article-wrap .contact-form form .item.textarea textarea {
  margin-top: 17px;
}
.article-wrap .contact-form form .item.textarea:hover {
  border-color: #ED6B28;
}
.article-wrap .contact-form form .item.captcha {
  display: flex;
  align-items: center;
  width: 50%;
}
.article-wrap .contact-form form .item.captcha .input {
  flex: none;
  width: 100%;
}
.article-wrap .contact-form form .item.captcha .input .label {
  height: 50px;
}
.article-wrap .contact-form form .item.captcha .input input {
  height: 50px;
  padding: 5px 5px 5px 100px;
}
.article-wrap .contact-form form .item.captcha .refresh_captcha {
  flex: none;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.article-wrap .contact-form form .item.captcha .refresh_captcha svg {
  margin-left: 10px;
  color: #ED6B28;
}
.article-wrap .contact-form form .item.rb {
  border-color: #e80009;
}
.article-wrap .contact-form form .item + .item {
  margin-top: 20px;
}
.article-wrap .contact-form form .action {
  margin-top: 40px;
}
.article-wrap .contact-form form .action .form-reset {
  padding: 14px 40px;
}
.article-wrap .contact-form form .action .form-send {
  margin-left: 20px;
  padding: 14px 50px;
}
.article-wrap .contact-form form .col2 {
  display: flex;
  justify-content: space-between;
}
.article-wrap .contact-form form .col2 .item {
  margin-top: 0;
  width: calc(50% - 20px);
}
.article-wrap .contact-form form .col2 + .col2,
.article-wrap .contact-form form .col2 + .item {
  margin-top: 20px;
}
@media (max-width: 1680px) {
  .article-wrap .contact-form .right {
    margin-left: 60px;
  }
  .article-wrap .contact-form form .item input[type=text], .article-wrap .contact-form form .item input[type=password], .article-wrap .contact-form form .item input[type=date], .article-wrap .contact-form form .item input[type=email], .article-wrap .contact-form form .item input[type=number] {
    height: 70px;
  }
  .article-wrap .contact-form form .item.select {
    height: 70px;
  }
}
@media (max-width: 1280px) {
  .article-wrap .contact-form form .col2 {
    flex-direction: column;
  }
  .article-wrap .contact-form form .col2 .item {
    width: 100%;
  }
  .article-wrap .contact-form form .col2 .item + .item {
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .article-wrap .contact-form {
    padding-top: 120px;
    background-size: 344px 259px, 314px 205px, 609px 60px;
    background-position: -5% 110%, 105% -5%, 30px 70px;
  }
}
@media (max-width: 900px) {
  .article-wrap .contact-form form .action {
    flex-direction: column;
    margin: 20px 0 0;
  }
  .article-wrap .contact-form form .action .button {
    width: 100%;
    margin: 0;
  }
  .article-wrap .contact-form form .action .button + .button {
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .article-wrap .contact-form .right {
    margin-left: 40px;
  }
}
@media (max-width: 750px) {
  .article-wrap .contact-form {
    background-image: none;
    flex-direction: column;
  }
  .article-wrap .contact-form .left {
    width: 100%;
    order: 2;
    margin-top: 50px;
    padding-top: 0;
  }
  .article-wrap .contact-form .left .name {
    padding-bottom: 50px;
  }
  .article-wrap .contact-form .left .share-list {
    margin-top: 30px;
  }
  .article-wrap .contact-form .right {
    order: 1;
    margin: 0;
  }
}
@media (max-width: 550px) {
  .article-wrap .contact-form {
    padding-top: 160px;
  }
  .article-wrap .contact-form form .item.textarea textarea {
    min-height: 320px;
    margin: 50px 15px 10px;
  }
  .article-wrap .contact-form form .item.form-name {
    background-color: transparent;
  }
  .article-wrap .contact-form form .item.form-name .label {
    bottom: auto;
    height: 70px;
  }
  .article-wrap .contact-form form .item.form-name input {
    background-color: #f0f5ee;
    padding-right: 10px;
  }
  .article-wrap .contact-form form .item .gender {
    position: static;
    margin-top: 20px;
  }
  .article-wrap .contact-form form .item.captcha {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
  }
  .article-wrap .contact-form form .item.captcha .input {
    background-color: #f0f5ee;
  }
  .article-wrap .contact-form form .item.captcha .refresh_captcha {
    margin: 20px 0 0;
  }
}
@media (max-width: 425px) {
  .article-wrap .contact-form {
    padding-top: 145px;
  }
  .article-wrap .contact-form .left .share-list {
    justify-content: space-between;
  }
  .article-wrap .contact-form .left .share-list .shareBar {
    width: calc(50% - 10px);
    min-width: auto;
    margin-left: 0;
  }
}
.article-wrap .sitemap-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-image: url("../image/leaf5.png"), url("../image/leaf4.png");
  background-size: 314px 206px, 344px 259px;
  background-position: 4% 100%, 105% 0%;
  background-repeat: no-repeat;
  padding: 70px 60px 60px;
}
.article-wrap .sitemap-wrap .left {
  position: relative;
  width: 52.4%;
}
.article-wrap .sitemap-wrap .left::after {
  content: "";
  display: block;
  padding-top: 115%;
}
.article-wrap .sitemap-wrap .right {
  position: relative;
  flex: none;
  width: 485px;
}
.article-wrap .sitemap-wrap .color-block {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ead6bf;
  margin: 0 60px 40px 0;
}
.article-wrap .sitemap-wrap .maps {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 40px 0 0 60px;
  border: 1px solid #ED6B28;
}
.article-wrap .sitemap-wrap .slogan {
  position: absolute;
  z-index: 2;
  top: -25px;
  right: 0;
  background: url("../image/location.png") no-repeat;
  background-size: cover;
  width: 566px;
}
.article-wrap .sitemap-wrap .slogan::after {
  content: "";
  display: block;
  padding-top: 12%;
}
.article-wrap .sitemap-wrap .juicy {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background: url("../image/juicy2.png") no-repeat;
  background-size: cover;
  width: 217px;
}
.article-wrap .sitemap-wrap .juicy::after {
  content: "";
  display: block;
  padding-top: 139%;
}
.article-wrap .sitemap-wrap .title {
  margin-top: 110px;
  border-top: 5px solid #ED6B28;
  padding: 15px 0 0;
  font-size: 32px;
  color: #ED6B28;
  max-width: 250px;
}
.article-wrap .sitemap-wrap .note {
  margin-top: 70px;
}
.article-wrap .sitemap-wrap .note .note-title {
  font-size: 18px;
  font-weight: bold;
  color: #ED6B28;
}
.article-wrap .sitemap-wrap .note .note-text {
  margin-top: 8px;
  font-weight: bold;
  color: #524643;
}
.article-wrap .sitemap-wrap .map-list {
  margin-top: 70px;
  border-top: 1px solid #cccccc;
}
.article-wrap .sitemap-wrap .map-list .item {
  border-bottom: 1px solid #cccccc;
  display: flex;
  padding: 28px 0;
  line-height: 1.5em;
  font-size: 14px;
}
.article-wrap .sitemap-wrap .map-list .item .name {
  flex: none;
  width: 65px;
}
.article-wrap .sitemap-wrap .map-list .item .text {
  margin-left: 15px;
}
.article-wrap .sitemap-wrap .share-list {
  margin-top: 40px;
  max-width: 360px;
}
.article-wrap .sitemap-wrap .share-list .share-text {
  color: #482e23;
  font-size: 19px;
  font-weight: bold;
}
.article-wrap .sitemap-wrap .share-list .shareBar {
  height: 45px;
  border-radius: 22.5px;
  padding-left: 10px;
  margin-top: 15px;
  width: 170px;
}
@media (max-width: 1580px) {
  .article-wrap .sitemap-wrap {
    flex-direction: column;
  }
  .article-wrap .sitemap-wrap .left {
    width: 100%;
  }
  .article-wrap .sitemap-wrap .left::after {
    padding-top: 56.25%;
  }
  .article-wrap .sitemap-wrap .right {
    width: 768px;
    margin: 30px auto 0;
  }
  .article-wrap .sitemap-wrap .share-list {
    max-width: none;
  }
}
@media (max-width: 1024px) {
  .article-wrap .sitemap-wrap .left {
    width: 660px;
    margin: 0 auto;
  }
  .article-wrap .sitemap-wrap .left::after {
    padding-top: 115%;
  }
  .article-wrap .sitemap-wrap .right {
    width: 660px;
  }
}
@media (max-width: 800px) {
  .article-wrap .sitemap-wrap .left {
    width: 100%;
    margin: 0;
  }
  .article-wrap .sitemap-wrap .right {
    width: 100%;
  }
  .article-wrap .sitemap-wrap .slogan {
    top: -7px;
    right: auto;
    left: 70px;
    width: 410px;
  }
}
@media (max-width: 680px) {
  .article-wrap .sitemap-wrap {
    background-image: none;
  }
  .article-wrap .sitemap-wrap .right {
    margin-top: 60px;
  }
  .article-wrap .sitemap-wrap .slogan {
    left: 40px;
  }
  .article-wrap .sitemap-wrap .maps {
    margin-left: 30px;
  }
  .article-wrap .sitemap-wrap .juicy {
    width: 160px;
  }
  .article-wrap .sitemap-wrap .title {
    margin-top: 0;
  }
}
@media (max-width: 550px) {
  .article-wrap .sitemap-wrap {
    padding-top: 170px;
  }
  .article-wrap .sitemap-wrap .color-block {
    margin-right: 5px;
  }
  .article-wrap .sitemap-wrap .maps {
    margin: 15px 0 0 5px;
  }
  .article-wrap .sitemap-wrap .slogan {
    top: -20px;
    width: 300px;
    left: auto;
    right: 10px;
  }
}
@media (max-width: 425px) {
  .article-wrap .sitemap-wrap .slogan {
    top: -15px;
    width: 260px;
  }
  .article-wrap .sitemap-wrap .share-list .bars-list {
    justify-content: space-between;
    margin: -20px 0 0;
  }
  .article-wrap .sitemap-wrap .share-list .shareBar {
    width: calc(50% - 10px);
    min-width: auto;
    margin-left: 0;
  }
  .article-wrap .sitemap-wrap .map-list .item {
    flex-direction: column;
    padding: 10px 0;
    line-height: 1.2em;
  }
  .article-wrap .sitemap-wrap .map-list .item .text {
    margin-left: 0;
  }
}

.around-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0;
}
.around-page .page-back {
  flex: none;
  margin: 0 10px;
}
.around-page .page-back a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  width: 140px;
  height: 38px;
  font-size: 16px;
  transition: all 0.3s;
  left: 0;
  top: 0;
}
.around-page .page-back a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.3s;
}
.around-page .page-back a:hover {
  color: #fff;
  background-color: #ED6B28;
  border-color: #ED6B28;
  left: -2px;
  top: -2px;
}
.around-page .page-back a:hover::after {
  background-color: #ddd;
  left: 5px;
  top: 5px;
}
.around-page .page-back a svg {
  margin-left: 15px;
}
.around-page .page-prev,
.around-page .page-next {
  width: calc(50% - 170px);
  flex: none;
}
.around-page .page-prev a,
.around-page .page-next a {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.8;
}
.around-page .page-prev a:hover,
.around-page .page-next a:hover {
  color: #ED6B28;
}
.around-page .page-prev .wrap-left,
.around-page .page-prev .wrap-right,
.around-page .page-next .wrap-left,
.around-page .page-next .wrap-right {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.around-page .page-prev {
  justify-content: flex-start;
  text-align-last: left;
}
.around-page .page-prev .wrap-left {
  margin-right: 2px;
}
.around-page .page-prev .wrap-right {
  width: calc(100% - 0px);
}
.around-page .page-prev .wrap-right span:nth-child(1) {
  display: flex;
  align-items: center;
}
.around-page .page-prev .wrap-right span:nth-child(2) {
  font-size: 16px;
  font-weight: bold;
}
.around-page .page-next {
  justify-content: flex-end;
  text-align: right;
}
.around-page .page-next .wrap-left {
  width: calc(100% - 0px);
}
.around-page .page-next .wrap-left span:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.around-page .page-next .wrap-left span:nth-child(2) {
  font-size: 16px;
  font-weight: bold;
}
.around-page .page-next .wrap-right {
  margin-left: 2px;
}
@media (max-width: 1024px) {
  .around-page .page-prev,
.around-page .page-next {
    width: calc(50% - 100px);
  }
}
@media (max-width: 768px) {
  .around-page {
    flex-wrap: wrap;
  }
  .around-page .page-back {
    width: 100%;
    margin: 0 0 40px;
    order: 1;
    display: flex;
    justify-content: center;
  }
  .around-page .page-prev,
.around-page .page-next {
    order: 2;
    width: calc(50% - 10px);
  }
}

.exhibition {
  font-size: 15px;
  line-height: 1.4;
}
.exhibition iframe {
  width: 100%;
  height: 525px;
}
.exhibition .img {
  margin-top: 10px;
}
.exhibition .desc {
  margin-top: 30px;
}
.exhibition .column {
  text-align: center;
  margin-top: 60px;
}
.exhibition .column2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.exhibition .column2 .colitem {
  width: calc(50% - 40px);
}
.exhibition .column2 .colitem .txt.black {
  color: #333;
}
.exhibition .column2 .colitem .name {
  margin: 22px 0px 0px;
  font-size: 29px;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #ED6B28;
}
.exhibition .column2 .colitem .txt {
  margin-top: 5px;
  font-weight: normal;
}
.exhibition .column2 .colitem.img {
  font-size: 0px;
}
.exhibition .column2.type2 .colitem {
  width: calc(50% - 20px);
}
.exhibition .column2.type3 .colitem {
  width: 50%;
}
.exhibition .column2 .column3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 40px;
}
.exhibition .column2 .column3 .colitem {
  width: calc(33.33% - 27px);
}
.exhibition .column2 .column3 .colitem img {
  max-width: 100%;
}
.exhibition .column2 .column3 .txt {
  color: #ed6b28;
  margin-top: 19px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1.5px;
}
.exhibition .block1 {
  position: relative;
  z-index: 1;
  color: white;
}
.exhibition .block1 .title {
  display: block;
  font-size: 46px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.1;
  padding: 0 20px;
}
.exhibition .block1 .title img {
  margin: 0 15px 0 0;
  max-height: 40px;
}
.exhibition .block1 .title.blue {
  color: #ed6b28;
}
.exhibition .block1 .subname {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  line-height: 32px;
  height: 32px;
}
.exhibition .block1 .subname.blue {
  color: #ed6b28;
}
.exhibition .block1 .section {
  position: relative;
  z-index: 2;
  padding: 74px 0px 40px;
  box-sizing: border-box;
}
.exhibition .block1 .bg {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  background: center no-repeat;
  background-size: cover;
}
.exhibition .block1 .column2 {
  position: relative;
  margin-top: 70px;
}
.exhibition .block1 .column2 .colitem {
  width: 50%;
}
.exhibition .block1 .purple {
  font-size: 18px;
}
.exhibition .block2 .hr {
  margin-top: 15px;
}
.exhibition .block2 .hr::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #ccc;
  margin-top: 15px;
  margin-bottom: 10px;
}
.exhibition .block2 .ds_link {
  margin-top: 30px;
  text-align: right;
  display: inline-block;
}
.exhibition .block2 .ds_link .ds_button {
  text-decoration: none;
  background-color: #ed6b28;
  color: #fff;
  padding: 11px 35px;
  font-size: 16px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}
.exhibition .block2 .ds_link .ds_button svg {
  margin-left: 15px;
}
.exhibition .block3 .txt p {
  font-size: 18px;
  line-height: 1.4;
}
.exhibition img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
.exhibition .section {
  width: 100%;
  max-width: 1640px;
  margin: 0px auto;
}

.exhibition .block1 .column2 .colitem.portal {
  flex: none;
  width: calc(50% - 20px);
  box-sizing: border-box;
  padding-right: 10px;
  padding-left: 30px;
}

.exhibition .block1 .column2 .colitem.img {
  position: absolute;
  right: 20px;
  top: 0;
  flex: auto;
  align-self: flex-start;
}

.exhibition .block1 .column2 .colitem.img img {
  position: relative;
  z-index: 2;
}

.exhibition .block1 .portal {
  width: 100%;
  max-width: 740px;
}

.exhibition .block1 .portal .subtitle {
  position: relative;
  margin: 0px 0px 10px;
  font-size: 29px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 32px;
}

.exhibition .block1 .portal .subtitle::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 52px;
  right: -100px;
  width: 200px;
  height: 1px;
  background-color: white;
  transform: rotate(-45deg);
}

.exhibition .block1 .portal .year {
  background-color: white;
  color: #ed6b28;
  font-size: 23px;
  font-weight: bold;
  padding: 0px 4px;
}

.exhibition .block1 .portal .year.purple {
  color: #662d91;
}

.exhibition .block1 .portal .info {
  margin-top: 42px;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 1px;
  color: #fff;
}

.exhibition .block2 {
  position: relative;
  z-index: 1;
  margin-top: 90px;
  padding: 55px 0px 10px;
}

.exhibition .block2 .waveL1, .exhibition .block2 .waveL2, .exhibition .block2 .waveR {
  position: absolute;
  z-index: 1;
  height: 667px;
  background: 0 0 no-repeat;
}

.exhibition .block2 .waveL1 {
  top: 30px;
  width: 100%;
  max-width: 880px;
}

.exhibition .block2 .waveL2 {
  top: 1047px;
  width: 100%;
  max-width: 670px;
}

.exhibition .block2 .waveR {
  top: 400px;
  right: 0px;
  width: 100%;
  max-width: 786px;
  background-position: right 0;
}

.exhibition .block2 .section {
  position: relative;
  z-index: 2;
}

.exhibition .block2 .subtitle {
  margin: 0px;
  position: absolute;
  top: -85px;
  font-size: 32px;
  color: #ed6b28;
  font-weight: bold;
  letter-spacing: 1.1px;
}

.exhibition .block2 .subname {
  margin: 30px 0;
  font-size: 32px;
  color: #ed6b28;
  font-weight: bold;
  letter-spacing: 1.1px;
  line-height: 1.2;
}

.exhibition .block2 .txt {
  color: #333;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.8;
  margin-top: 20px;
}

.exhibition .block2 .txt p {
  margin: 0px;
  font-size: 18px;
  line-height: 1.8;
  font-weight: bold;
}

.exhibition .block2 .txt p + p {
  margin-top: 19px;
}

.exhibition .block2 .txt a {
  text-shadow: 0px 0px 1px #ed6b28;
}

.exhibition .block2 .txt .blue {
  color: #ed6b28;
}

.exhibition .block2 .list-item {
  padding-left: 20px;
  color: #ed6b28;
  font-size: 17px;
  font-weight: bold;
  line-height: 32px;
}

.exhibition .block2 .column2 {
  margin-top: 60px;
}

.exhibition .block2 .column2 .colitem.img {
  position: relative;
}

.exhibition .block2 .column2 .img {
  max-width: 100%;
  height: auto;
}

.exhibition .block2 .column2 .imgA1 {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  opacity: 1;
  animation: ani-img-out 14s ease-in-out infinite;
}

.exhibition .block2 .column2 .imgA2 {
  position: relative;
  z-index: 1;
  top: 0px;
  left: 0px;
}

.exhibition .block2 .column2 .name {
  margin: 22px 0px 0px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.7px;
}

.exhibition .block2 .column2 .name a {
  text-shadow: 0px 0px 1px #ed6b28;
}

.exhibition .block2 .column2 ul {
  margin: 27px 0px 0px;
  font-size: 17px;
  font-weight: bold;
  padding-left: 18px;
  letter-spacing: 0.7px;
  line-height: 32px;
}

.exhibition .block2 .column2 ul li a {
  text-shadow: 0px 0px 1px #ed6b28;
}

.exhibition .block2 .column2 ul.normal {
  font-weight: normal;
}

.exhibition .block2 .column2 ul.normal.type2 li {
  color: #000;
}

.exhibition .block2 .column2 .txt {
  color: #333;
  margin-top: 5px;
  font-weight: normal;
}

.exhibition .block2 .item {
  position: relative;
  margin-top: 130px;
}

.exhibition .block2 .item .name {
  color: #ed6b28;
  font-size: 17px;
  font-weight: bold;
  padding: 0px 20px 0px 15px;
  background-color: white;
  margin: 0px;
  position: absolute;
  z-index: 2;
  top: -60px;
  letter-spacing: 2.8px;
  line-height: 18px;
  height: 80px;
  box-shadow: 5px 5px 15px -5px rgba(111, 111, 111, 0.5);
}

.exhibition .block2 .item .name sup {
  font-size: 52px;
  margin-right: 10px;
  font-weight: bold;
  vertical-align: 15px;
}

.exhibition .block2 .item .name sup.one::after {
  content: "1";
}

.exhibition .block2 .item .name sup.two::after {
  content: "2";
}

.exhibition .block2 .item .image-wrap {
  position: relative;
  z-index: 1;
  display: flex;
  max-width: 1080px;
  margin-left: 60px;
  font-size: 0px;
}

.exhibition .block2 .item .image-wrap .wrap-left, .exhibition .block2 .item .image-wrap .wrap-right {
  position: relative;
  z-index: 2;
  flex: none;
  width: 50%;
}

.exhibition .block2 .item .image-wrap::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  display: block;
  border: 2px dashed #9faec1;
}

.exhibition .block2 .item + .item {
  margin-top: 120px;
}

.exhibition .block2 .item + .subname {
  margin-top: 60px;
}

.exhibition .block3 {
  color: #ed6b28;
}

.exhibition .block3 .subname {
  margin: 49px 0px 0px;
  font-size: 29px;
  letter-spacing: 0.5px;
}

.exhibition .block3 .txt {
  font-size: 17px;
  font-weight: bold;
  margin-top: 37px;
  line-height: 35px;
}

.exhibition .block3 .txt .blue {
  color: #ed6b28;
}

.exhibition .block3 .column-box {
  padding-top: 134px;
  display: flex;
  justify-content: space-between;
}

.exhibition .block3 .column-box .colitem {
  width: calc(50% - 40px);
}

.exhibition .block3 .column-box .name {
  margin: 72px 0px 0px;
  font-size: 29px;
  font-weight: bold;
  letter-spacing: 0.7px;
}

.exhibition .block3 .column-box .name a {
  text-shadow: 0px 0px 1px #ed6b28;
}

.exhibition .block3 .column-box .info {
  margin: 37px 0px 0px;
  font-size: 17px;
  font-weight: bold;
  padding-left: 0px;
  letter-spacing: 0.7px;
  line-height: 32px;
}

.exhibition .block3 .column-box.type2 .name {
  margin: 0;
}

.exhibition .block3 .column3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 53px;
  padding-bottom: 52px;
}

.exhibition .block3 .column3 .colitem {
  width: calc(33.33% - 27px);
}

.exhibition .block3 .column3 .txt {
  color: #ed6b28;
  margin-top: 19px;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1.5px;
}

.exhibition .block3.type2 {
  padding: 0px 0 60px;
  font-size: 19px;
  font-weight: bold;
}

.exhibition .block3.type2 .popup {
  color: #333333;
}

.exhibition .block3.type2 .popup svg {
  width: 24px;
  height: 24px;
  vertical-align: 0px;
}

.exhibition .block3.type2 .popup:hover {
  color: #ed6b28;
}

.exhibition .block3.type2 .hr {
  margin-top: 15px;
  color: #333333;
}

.exhibition .block3.type2 .hr svg {
  color: #ed6b28;
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.exhibition .block3.type2 .hr::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #ccc;
  margin-top: 10px;
}

.exhibition .block3.type2 .txt {
  margin: 18px 0 30px;
  color: #333;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.7em;
}

.exhibition .block4 {
  position: relative;
  max-height: 750px;
}

.exhibition .block4 .bg {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  background-size: cover;
}

.exhibition .block4 .section {
  position: relative;
  z-index: 2;
}

.exhibition .block4 .subname {
  height: 180px;
  line-height: 180px;
  margin: 0px;
  color: #ed6b28;
  font-size: 52px;
  font-weight: bold;
  letter-spacing: 3.1px;
}

.exhibition .block4 .visit {
  position: relative;
}

.exhibition .block4 .hallMap {
  width: 100%;
}

.exhibition .block4 .fairgroundMap {
  position: absolute;
  top: -110px;
  right: 40px;
  width: 417px;
  box-shadow: -10px 10px 30px -10px rgba(0, 0, 0, 0.3);
}

.exhibition .block4 .info {
  position: absolute;
  left: 39px;
  bottom: 40px;
}

.exhibition .block4 .info .logo {
  max-width: 130px;
}

.exhibition .block4 .info .logo-w {
  align-self: center;
  display: none;
  max-width: 130px;
}

.exhibition .block4 .info h3, .exhibition .block4 .info h4 {
  margin: 0px;
}

.exhibition .block4 .info h4 {
  font-size: 37px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #ed6b28;
  margin-top: 7px;
}

.exhibition .block4 .info h3 {
  font-size: 23px;
  font-weight: bold;
  color: #ed6b28;
  margin-top: 5px;
}

.exhibition .block4 .info .txt {
  font-size: 14px;
  line-height: 30px;
  margin-top: 10px;
  font-weight: bold;
}

.exhibition .block4 .info.top {
  top: 0px;
  bottom: auto;
}

.exhibition .block4 .boothMap {
  margin: 20px auto 0;
  background: left bottom no-repeat;
  background-size: cover;
}

.exhibition .block4 .boothMap::before {
  content: "";
  display: block;
  padding-top: 49%;
}

.exhibition .block4.type2 {
  max-height: none;
}

.exhibition .block4.type2 .bg {
  background: #f7f7f7 center top no-repeat;
  background-size: contain;
}

.exhibition .block4.gMap {
  max-height: none;
}

.exhibition .block4.gMap .bg {
  background: #f7f7f7 center top no-repeat;
  background-size: contain;
}

.exhibition .block4.gMap .visit {
  background-color: #fff;
  padding: 20px;
}

.exhibition .block4.gMap .Map {
  position: relative;
  min-height: 480px;
}

.exhibition .block3 iframe {
  width: 100%;
  height: 500px;
}

.exhibition .block4.gMap .Map:before {
  content: "";
  display: block;
  padding-top: 46.42%;
}

.exhibition .block4.gMap .info {
  position: static;
}

.exhibition .block4.gMap .info h3, .exhibition .block4.gMap .info h4, .exhibition .block4.gMap .info .text {
  line-height: 1.2em;
}

.exhibition .block4.gMap .info .position {
  margin-top: 0px;
}

.exhibition .block4.gMap .info .text {
  margin-top: 5px;
}

.exhibition .block4.gMap .Maplogo {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.exhibition .block5 {
  background-color: #f7f7f7;
  padding-top: 190px;
  padding-bottom: 55px;
}

.exhibition .block5 .subname {
  position: relative;
  z-index: 2;
  margin: 0px;
  font-size: 52px;
  font-weight: bold;
  color: #ed6b28;
}

.exhibition .block5 .subname::after {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  left: 120px;
  width: 200px;
  height: 1px;
  background-color: #ed6b28;
  transform: rotate(-45deg);
}

.exhibition .block5 .photo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 18px;
}

.exhibition .block5 .photo .pleft, .exhibition .block5 .photo .pright {
  flex: none;
  width: calc(50% - 5px);
}

.exhibition .block5 .photo .pright {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -10px;
}

.exhibition .block5 .photo .pright .item {
  width: calc(50% - 5px);
  margin-top: 10px;
}

.exhibition .block5 .photo .pright .item:first-child {
  display: none;
}

.exhibition .block5.type2 {
  padding-top: 0px;
}

.exhibition .around {
  background-color: #f7f7f7;
  padding-bottom: 60px;
}

.exhibition .around-block {
  margin-top: 0px;
}

@media (max-width: 1200px) {
  .exhibition .section {
    width: auto;
    margin: 0px 40px;
  }

  .exhibition .block2 .item {
    margin-right: 20px;
  }
}
@media (max-width: 1100px) {
  .exhibition .block2 {
    margin-top: 80px;
  }

  .exhibition .block2 .waveL1, .exhibition .block2 .waveL2, .exhibition .block2 .waveR {
    opacity: 0.4;
  }

  .exhibition .block3.type2 {
    padding: 0 0 20px;
  }

  .exhibition .block4 {
    max-height: none;
  }

  .exhibition .block4 .subname {
    height: 150px;
    line-height: 150px;
  }

  .exhibition .block4 .visit {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .exhibition .block4 .hallMap {
    width: calc(65% - 10px);
  }

  .exhibition .block4 .fairgroundMap {
    position: relative;
    top: 0px;
    left: 0px;
    right: auto;
    width: calc(35% - 10px);
  }

  .exhibition .block4 .info {
    position: static;
    display: flex;
    flex-wrap: wrap;
    color: white;
    margin: 30px auto;
    max-width: 705px;
  }

  .exhibition .block4 .info h3, .exhibition .block4 .info h4 {
    color: white;
  }

  .exhibition .block4 .info .logo {
    display: none;
  }

  .exhibition .block4 .info .logo-w {
    display: block;
  }

  .exhibition .block4 .info .subtitle {
    margin-left: 20px;
    width: calc(100% - 150px);
  }

  .exhibition .block4 .info .txt {
    margin-top: 20px;
    padding-left: 152px;
  }

  .exhibition .block4.type2 .visit .info {
    color: #ed6b28;
    max-width: none;
    margin: 10px 0;
  }

  .exhibition .block4.type2 .visit .info h3, .exhibition .block4.type2 .visit .info h4 {
    color: #ed6b28;
  }

  .exhibition .block4.type2 .visit .info .subtitle {
    margin-left: 0;
    width: 100%;
  }

  .exhibition .block4.type2 .visit .info .txt {
    padding-left: 0;
    margin-top: 0;
  }

  .exhibition .block4.gMap .visit {
    position: relative;
    display: block;
  }

  .exhibition .block4.gMap .info {
    color: #ed6b28;
    margin: 0;
  }

  .exhibition .block4.gMap .info h3, .exhibition .block4.gMap .info h4 {
    color: #ed6b28;
  }

  .exhibition .block4.gMap .info .subtitle {
    width: auto;
    margin: 0;
  }

  .exhibition .block4.gMap .info .txt {
    margin-top: 5px;
    padding: 0;
  }

  .exhibition .block5 {
    padding-top: 50px;
  }
}
@media (max-width: 1023px) {
  .exhibition .block1 .section {
    padding-top: 50px;
    height: auto;
  }

  .exhibition .block1 .portal .subtitle {
    margin-top: 20px;
  }

  .exhibition .block1 .column2 .colitem.portal {
    padding-right: 20px;
    padding-left: 20px;
  }

  .exhibition .block1 .portal .subtitle::after {
    display: none;
  }

  .exhibition .block1 .column2 {
    margin: 40px auto 0px;
    padding-right: 0px;
    flex-direction: column;
  }

  .exhibition .block1 .column2 .colitem {
    width: 100%;
  }

  .exhibition .block1 .column2 .colitem.portal {
    order: 2;
    width: 100%;
    max-width: none;
  }

  .exhibition .block1 .column2 .colitem.portal .info {
    margin-bottom: 0px;
  }

  .exhibition .block1 .column2 .colitem.img {
    position: relative;
    right: 0;
    order: 1;
  }

  .exhibition .block1 .column2 .colitem.img::after {
    display: none;
  }

  .exhibition .block2 {
    margin-top: 0px;
    padding: 0 0 80px;
    height: auto;
  }

  .exhibition .block2 .subtitle {
    position: static;
  }

  .exhibition .block2 .subtitle::after {
    display: none;
  }

  .exhibition .block2 .column2 .colitem {
    width: calc(50% - 10px);
  }

  .exhibition .block2 .column2 .name {
    margin: 0px;
  }

  .exhibition .block2 .column2 ul {
    line-height: 30px;
  }

  .exhibition .block2 .item .image-wrap {
    margin-left: 10px;
  }

  .exhibition .block3 .column-box {
    padding-top: 30px;
  }

  .exhibition .block3 .column-box .name {
    margin-top: 0px;
  }

  .exhibition .block3 .subname {
    margin-top: 30px;
  }

  .exhibition .block3 .txt {
    line-height: 26px;
  }

  .exhibition .block4.gMap .Maplogo {
    display: none;
  }

  .exhibition .block5 .subname::after {
    display: none;
  }
}
@media (max-width: 800px) {
  .exhibition .block1 .title {
    font-size: 32px;
  }

  .exhibition .block2 .column {
    text-align: left;
  }

  .exhibition .block2 .column2 {
    flex-direction: column;
  }

  .exhibition .block2 .column2 .colitem {
    width: 100%;
    order: 2;
  }

  .exhibition .block2 .column2 .colitem.img {
    text-align: center;
    order: 1;
  }

  .exhibition .block2 .column2 .name {
    margin-top: 30px;
  }

  .exhibition .block2 .item {
    margin-right: 0px;
  }

  .exhibition .block2 .item .image-wrap {
    margin-left: 0px;
  }

  .exhibition .block2 .item .image-wrap::after {
    display: none;
  }

  .exhibition .block3 .column-box {
    flex-direction: column;
  }

  .exhibition .block3 .column-box .colitem {
    width: 100%;
  }

  .exhibition .block3 .column-box .colitem.img {
    margin-top: 20px;
  }

  .exhibition .block3 .column3 .colitem {
    width: calc(33.33% - 10px);
  }

  .exhibition .block4 .visit {
    flex-direction: column;
  }

  .exhibition .block4 .hallMap {
    order: 2;
    width: 100%;
    margin-top: 20px;
  }

  .exhibition .block4 .fairgroundMap {
    order: 1;
    width: 100%;
  }

  .exhibition .block4 .fairgroundMap.turkey {
    max-width: 417px;
  }

  .exhibition .block4 .info {
    order: 3;
  }

  .exhibition .block4 .info h4 {
    margin-top: 0px;
  }
}
@media (max-width: 620px) {
  .exhibition .column3 {
    flex-direction: column;
  }

  .exhibition .column3 .colitem {
    width: 100%;
    text-align: center;
  }

  .exhibition .column3 .colitem + .colitem {
    margin-top: 20px;
  }

  .exhibition .block2 {
    padding-bottom: 40px;
  }

  .exhibition .block2 .item .name {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
  }

  .exhibition .block2 .item .name sup {
    vertical-align: 0px;
  }

  .exhibition .block2 .item .image-wrap {
    flex-direction: column;
  }

  .exhibition .block2 .item .image-wrap .wrap-left, .exhibition .block2 .item .image-wrap .wrap-right {
    width: 100%;
  }

  .exhibition .block3 .column3 {
    flex-direction: column;
  }

  .exhibition .block3 .column3 .colitem {
    width: 100%;
    text-align: center;
  }

  .exhibition .block3 .column3 .colitem + .colitem {
    margin-top: 20px;
  }

  .exhibition .block3 .column3 .txt {
    margin-top: 10px;
  }

  .exhibition .block4 .info .txt {
    padding-left: 0px;
  }

  .exhibition .block4 .boothMap::before {
    padding-top: 100%;
  }

  .exhibition .block4.gMap .info h3 {
    font-size: 19px;
  }

  .exhibition .block4.gMap .info h4 {
    font-size: 32PX;
  }

  .exhibition .block5 .photo .pleft {
    display: none;
  }

  .exhibition .block5 .photo .pright {
    width: 100%;
  }

  .exhibition .block5 .photo .pright .item:first-child {
    display: block;
  }
}
@media (max-width: 550px) {
  .exhibition .section {
    margin: 0;
  }
}
@media (max-width: 500px) {
  .exhibition .block2 .txt {
    letter-spacing: 1px;
  }

  .exhibition .block2 .item {
    margin-top: 50px;
  }

  .exhibition .block2 .item .name {
    position: static;
    height: auto;
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .exhibition .block2 .item .name sup {
    vertical-align: 0px;
    position: static;
  }

  .exhibition .block2 .item + .item {
    margin-top: 50px;
  }

  .exhibition .block4 .subname {
    height: auto;
    line-height: 40px;
    font-size: 28px;
    box-sizing: border-box;
    padding: 20px 0px;
  }

  .exhibition .block4 .info h4 {
    font-size: 26px;
  }

  .exhibition .block4 .info h3 {
    font-size: 30px;
  }

  .exhibition .block4.gMap .visit {
    padding: 0;
  }

  .exhibition .block4.gMap .Map {
    min-height: 320px;
  }

  .exhibition .block4.gMap .info {
    margin: 0 10px 10px;
  }

  .exhibition .block5 {
    padding-top: 0px;
  }

  .exhibition .block5 .subname {
    height: auto;
    line-height: 40px;
    font-size: 30px;
    box-sizing: border-box;
    padding: 20px 0px;
  }
}
@media (max-width: 425px) {
  .exhibition .block1 .title.long {
    font-size: 32px;
  }

  .exhibition .block3 .subname {
    font-size: 27px;
  }

  .exhibition .block4 .info .logo-w {
    display: none;
  }

  .exhibition .block4 .info .subtitle {
    margin-left: 0px;
    width: 100%;
  }
}
.product-list {
  position: relative;
  background: #fff url("../image/bubble.png") repeat-y;
  padding: 70px 100px;
}
.product-list img {
  max-width: 100%;
}
.product-list .list-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 60px;
}
.product-list .list-wrap .col2 {
  grid-column: 1/3;
}
.product-list .item {
  display: flex;
  flex-direction: column;
}
.product-list .item .image {
  max-height: 250px;
}
.product-list .item .info {
  text-align: center;
}
.product-list .item .info .name {
  color: #482e23;
  font-weight: bold;
  font-size: 19px;
  margin-top: 23px;
}
.product-list .item .info .subname {
  color: #524643;
  font-size: 13px;
  margin-top: 11px;
  min-height: 34px;
}
.product-list .item .info .price-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}
.product-list .item .info .price-info .special {
  background-color: #ffa200;
  padding: 0 2px;
  color: #fff;
  margin-right: 6px;
}
.product-list .item .info .price-info .price {
  color: #ffa200;
  font-size: 21px;
  font-weight: bold;
}
.product-list .item:hover .info .name {
  color: #ED6B28;
}
@media (max-width: 1200px) {
  .product-list {
    padding: 60px;
  }
  .product-list .list-wrap {
    grid-gap: 50px;
  }
}
@media (max-width: 1024px) {
  .product-list {
    padding: 50px 30px 30px;
  }
  .product-list .list-wrap {
    grid-gap: 30px;
  }
}
@media (max-width: 900px) {
  .product-list .list-wrap {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  .product-list .list-wrap .col2 {
    grid-column: 2/4;
  }
}
@media (max-width: 750px) {
  .product-list {
    padding-top: 200px;
  }
}
@media (max-width: 670px) {
  .product-list .list-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
  .product-list .list-wrap .col2 {
    grid-column: 1/3;
  }
}
@media (max-width: 480px) {
  .product-list .item .info .name {
    font-size: 16px;
  }
  .product-list .item .info .price-info .price {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .product-list {
    padding: 180px 15px 30px;
  }
  .product-list .list-wrap {
    grid-gap: 15px;
  }
}

.product-info {
  position: relative;
  background: #fff no-repeat;
  background-image: url("../image/vein3-green.png"), url("../image/vein2-green.png");
  background-position: 99% -1%, 112% 20%;
  padding: 70px 60px;
}
.product-info .info-wrap {
  display: flex;
  justify-content: space-between;
}
.product-info .info-wrap .ileft,
.product-info .info-wrap .iright {
  position: relative;
  width: calc(50% - 20px);
}
.product-info .info-wrap .swiper-container {
  position: relative;
  height: 260px;
}
.product-info .info-wrap .product-append {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 340px;
  padding: 40px 0;
}
.product-info .info-wrap .swiper-slide {
  width: 80px;
  height: 80px;
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.product-info .info-wrap .swiper-slide img {
  max-width: 100%;
}
.product-info .info-wrap .swiper-slide:hover, .product-info .info-wrap .swiper-slide.selected {
  border-color: #ED6B28;
}
.product-info .info-wrap .swiper-button-prev,
.product-info .info-wrap .swiper-button-next {
  position: absolute;
  z-index: 2px;
  top: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-image: none;
  color: #ED6B28;
  margin: 0;
}
.product-info .info-wrap .swiper-button-prev svg,
.product-info .info-wrap .swiper-button-next svg {
  width: 30px;
  height: 30px;
}
.product-info .info-wrap .swiper-button-prev.swiper-button-disabled,
.product-info .info-wrap .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}
.product-info .info-wrap .swiper-button-prev {
  top: 0;
}
.product-info .info-wrap .swiper-button-next {
  bottom: 0;
}
.product-info .info-wrap .cover {
  width: 100%;
  max-height: 650px;
}
.product-info .info-wrap .cover img {
  max-width: 100%;
  max-height: 100%;
}
.product-info .info-wrap .subname {
  display: inline-block;
  color: #ED6B28;
  background-color: #f0f5ee;
  padding: 2px 5px;
}
.product-info .info-wrap .name {
  font-size: 30px;
  font-weight: bold;
  color: #482e23;
  margin: 10px 0;
}
.product-info .info-wrap .price-wrap {
  display: flex;
  align-items: baseline;
}
.product-info .info-wrap .price-wrap .price,
.product-info .info-wrap .price-wrap .special {
  color: #ffa200;
  font-size: 30px;
  font-weight: bold;
}
.product-info .info-wrap .price-wrap .special + .price {
  color: #666666;
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
}
.product-info .info-wrap .attribs {
  margin-top: 20px;
  min-height: 250px;
}
.product-info .info-wrap .attrib-title {
  font-size: 21px;
  font-weight: bold;
  color: #ED6B28;
}
.product-info .info-wrap .attrib {
  line-height: 1.2em;
  margin: 15px 0 0;
}
.product-info .info-wrap .attrib .item + .item {
  margin-top: 15px;
}
.product-info .info-wrap .quantity {
  display: flex;
  align-items: center;
  height: 45px;
  margin-top: 30px;
}
.product-info .info-wrap .quantity select {
  height: 45px;
  width: 200px;
}
.product-info .info-wrap .quantity .stock_num {
  color: #ED6B28;
}
.product-info .info-wrap .buttons {
  margin-top: 30px;
}
.product-info .info-wrap .buttons .button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-info .info-wrap .buttons svg {
  width: 24px;
  height: 24px;
}
.product-info .gline {
  width: 100%;
  height: 6px;
  margin: 5px 0;
  fill: #ED6B28;
}
.product-info .desc-title {
  position: relative;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-info .desc-title img {
  height: 35px;
}
.product-info .desc-title .juicy {
  position: absolute;
  top: -80px;
  left: 30px;
  width: 135px;
  height: 196px;
}
.product-info .desc-block {
  margin-top: 50px;
  color: #482e23;
}
.product-info .desc-block img {
  max-width: 100%;
  object-fit: contain;
}
.product-info .desc-block img.txt1 {
  height: 60px;
}
.product-info .desc-block img.txt2 {
  height: 50px;
}
.product-info .desc-block .tag {
  color: #fff;
  background-color: #ED6B28;
  display: inline-block;
}
.product-info .desc-block .subtitle {
  margin-top: 20px;
  font-size: 23px;
  font-weight: bold;
}
.product-info .desc-block .block1 {
  display: flex;
  flex-direction: column;
}
.product-info .desc-block .block1 .image {
  position: relative;
  z-index: 1;
}
.product-info .desc-block .block1 .image .tag {
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 80px;
  writing-mode: vertical-lr;
  padding: 10px 3px;
}
.product-info .desc-block .block1 .image img {
  position: relative;
  z-index: 1;
}
.product-info .desc-block .block1 .wrap {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  margin-top: -200px;
  margin-left: 100px;
}
.product-info .desc-block .block1 .wrap .wrap2 {
  align-self: end;
  padding: 0 10px;
  margin: 0 0 130px;
}
.product-info .desc-block .block2 {
  margin-top: 50px;
}
.product-info .desc-block .block2 .tag {
  padding: 3px 10px;
  margin-bottom: 20px;
}
.product-info .desc-block .block2 .col2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-info .desc-block .block2 .col2 .item {
  width: calc(50% - 20px);
}
.product-info .desc-block .block2 .col2 .text {
  margin-top: 20px;
  line-height: 1.7em;
  max-width: 70%;
}
.product-info .desc-block .block2 .col3 {
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.product-info .desc-block .block2 .col3 .item {
  width: calc(33.33% - 50px);
}
.product-info .desc-block .block2 .col3 .image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.product-info .desc-block .block2 .col3 .image img {
  position: relative;
  z-index: 3;
  width: 100%;
  border-radius: 50%;
}
.product-info .desc-block .block2 .col3 .image::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  background-color: #f0f5ee;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.product-info .desc-block .block2 .col3 .image::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  background-color: #ED6B28;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.product-info .desc-block .block2 .col3 .image.t1::before {
  top: -10px;
  left: -10px;
}
.product-info .desc-block .block2 .col3 .image.t1::after {
  bottom: -10px;
  left: 0;
}
.product-info .desc-block .block2 .col3 .image.t2::before {
  bottom: -15px;
  left: -20px;
}
.product-info .desc-block .block2 .col3 .image.t2::after {
  bottom: -10px;
  left: 0x;
}
.product-info .desc-block .block2 .col3 .image.t3::before {
  z-index: 3;
  bottom: -10px;
  right: -5px;
}
.product-info .desc-block .block2 .col3 .image.t3::after {
  z-index: 2;
  top: -13px;
  left: -15px;
}
.product-info .desc-block .block2 .col3 .subtitle {
  margin-top: 40px;
  text-align: center;
}
.product-info .desc-block .block3 {
  margin-top: 50px;
}
.product-info .desc-block .block3 .tag {
  padding: 3px 10px;
  margin-bottom: 20px;
}
.product-info .desc-block .block3 .col2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-info .desc-block .block3 .col2 .item {
  position: relative;
  width: calc(50% - 40px);
}
.product-info .desc-block .block3 .col2 .wrap {
  position: absolute;
  top: -45px;
  right: 0;
}
.product-info .desc-block .block3 .col2 .txt2 {
  display: block;
}
.product-info .desc-block .block3 .col2 .text {
  margin-top: 20px;
  line-height: 1.7em;
  max-width: 70%;
}
.product-info .desc-block .block4 {
  margin-top: 50px;
}
.product-info .desc-block .block4 .center {
  text-align: center;
  margin-bottom: 50px;
}
.product-info .desc-block .block4 .col2 {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.product-info .desc-block .block4 .col2 .item {
  width: calc(50% - 20px);
}
.product-info .desc-block .block4 .icon {
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f5ee;
  border-radius: 50%;
}
.product-info .desc-block .block4 .icon img {
  width: 50%;
  height: 50%;
}
.product-info .desc-block .block4 .gtxt {
  font-size: 19px;
  font-weight: bold;
  color: #ED6B28;
}
.product-info .desc-block .block4 .text {
  font-size: 28px;
  font-weight: bold;
}
.product-info .desc-block .block5 {
  margin-top: 50px;
  text-align: center;
}
.product-info .desc-block .block5 .center {
  margin-bottom: 50px;
}
.product-info .desc-block .block5 .wrap {
  display: inline-flex;
}
.product-info .desc-block .block5 .wrap .wrapinfo {
  margin-left: 40px;
}
.product-info .desc-block .block5 .wrap .slogan {
  display: flex;
  justify-content: space-between;
}
.product-info .desc-block .block5 .wrap .slogan .item {
  width: 167px;
  height: 163px;
  font-size: 32px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
}
.product-info .desc-block .block5 .wrap .slogan .item + .item {
  margin-left: 20px;
}
.product-info .desc-block .block5 .wrap .txt-wrap {
  display: inline-flex;
  justify-content: center;
  text-align-last: left;
  margin-top: 30px;
}
.product-info .desc-block .block5 .wrap .txt-wrap .text {
  line-height: 1.5em;
  font-weight: bold;
  font-size: 19px;
}
.product-info .desc-block .block5 .photo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.product-info .desc-block .block5 .photo img {
  border: 1px solid #b3b3b3;
}
.product-info .desc-block .block5 .photo .pleft,
.product-info .desc-block .block5 .photo .pright {
  width: calc(50% - 40px);
}
.product-info .desc-block .block5 .photo .pleft {
  padding: 0 20px;
}
.product-info .desc-block .block5 .photo .pleft img {
  border-color: #ED6B28;
}
.product-info .desc-block .block5 .photo .caption {
  margin-top: 15px;
  font-size: 17px;
  font-weight: bold;
  color: #ED6B28;
}
.product-info .desc-block .block5 .photo .caption2 {
  color: #524643;
}
.product-info .desc-block .block5 .photo .col2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: -40px 0 0 -40px;
}
.product-info .desc-block .block5 .photo .col2 .item {
  width: calc(50% - 40px);
  margin: 40px 0 0 40px;
}
.product-info .desc-block .block6 {
  margin-top: 50px;
  text-align: center;
}
.product-info .desc-block .block6 .center {
  margin-bottom: 50px;
}
.product-info .desc-block .block6 .col3 {
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.product-info .desc-block .block6 .col3 .item {
  width: calc(33.33% - 50px);
}
.product-info .desc-block .block6 .col3 .image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.product-info .desc-block .block6 .col3 .image img {
  position: relative;
  z-index: 3;
  width: 100%;
  border-radius: 50%;
}
.product-info .desc-block .block6 .col3 .image::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  background-color: #f0f5ee;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.product-info .desc-block .block6 .col3 .image::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  background-color: #ED6B28;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.product-info .desc-block .block6 .col3 .image.t1::before {
  top: -10px;
  left: -10px;
}
.product-info .desc-block .block6 .col3 .image.t1::after {
  bottom: -10px;
  left: 0;
}
.product-info .desc-block .block6 .col3 .image.t2::before {
  bottom: -15px;
  left: -20px;
}
.product-info .desc-block .block6 .col3 .image.t2::after {
  bottom: -10px;
  left: 0x;
}
.product-info .desc-block .block6 .col3 .image.t3::before {
  z-index: 3;
  bottom: -10px;
  right: -5px;
}
.product-info .desc-block .block6 .col3 .image.t3::after {
  z-index: 2;
  top: -13px;
  left: -15px;
}
.product-info .desc-block .block6 .col3 .gtxt {
  color: #ED6B28;
  margin: 60px 0 20px;
  font-size: 24px;
  font-weight: bold;
}
.product-info .desc-block .block6 .col3 .text {
  line-height: 1.7em;
  font-size: 17px;
  font-weight: bold;
}
.product-info .desc-block .block7 {
  margin-top: 50px;
  text-align: center;
}
.product-info .desc-block .block7 .center {
  margin-bottom: 50px;
}
.product-info .desc-block .block7 .qalist {
  position: relative;
  z-index: 1;
  width: 90%;
  margin: 0 auto;
}
.product-info .desc-block .block7 .qalist::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  top: -60px;
  left: -45px;
  width: 122px;
  height: 81px;
  background: url("../image/drawing2.png") no-repeat;
  background-size: contain;
}
.product-info .desc-block .block7 .qalist::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  bottom: -45px;
  right: -50px;
  width: 103px;
  height: 104px;
  background: url("../image/plum2.png") no-repeat;
  background-size: contain;
}
.product-info .desc-block .block7 .qalist .item {
  border: 2px solid #ED6B28;
  border-radius: 20px;
  padding: 30px 0;
  text-align: left;
}
.product-info .desc-block .block7 .qalist .item + .item {
  margin-top: 30px;
}
.product-info .desc-block .block7 .qalist .question {
  position: relative;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  padding: 2px 70px;
  line-height: 1.5em;
  background-color: #ED6B28;
}
.product-info .desc-block .block7 .qalist .question::before {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 30px;
  width: 29px;
  height: 39px;
  background: url("../image/Q.png") no-repeat;
}
.product-info .desc-block .block7 .qalist .answer {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  padding: 0 70px;
  margin-top: 20px;
  line-height: 1.6em;
}
.product-info .desc-block .block7 .qalist .answer::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 30px;
  width: 28px;
  height: 31px;
  background: url("../image/A.png") no-repeat;
}
.product-info .desc-block .block8 {
  position: relative;
  z-index: 1;
  margin-top: 90px;
  background-color: #f0f5ee;
  border-radius: 10px;
  padding: 30px;
}
.product-info .desc-block .block8 .table {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.product-info .desc-block .block8 table {
  background-color: transparent;
}
.product-info .desc-block .block8 table.list th,
.product-info .desc-block .block8 table.list td {
  white-space: nowrap;
}
.product-info .desc-block .block8 table.list tr {
  border-bottom: 1px solid #ED6B28;
}
.product-info .desc-block .block8 table.list th {
  padding: 10px;
  font-size: 21px;
  font-weight: bold;
}
.product-info .desc-block .block8 table.list tbody td {
  border: none;
  padding: 10px;
  font-size: 21px;
  font-weight: bold;
}
.product-info .desc-block .block8 .gline {
  margin-top: 10px;
}
.product-info .desc-block .block8 .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-info .desc-block .block8 .head .txt2 {
  height: 40px;
}
.product-info .desc-block .block8 .head .note {
  font-size: 17px;
  font-weight: bold;
}
.product-info .desc-block .block8 .foot {
  margin: 10px 285px 0 0;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.7em;
}
.product-info .desc-block .block8 .wrap {
  position: absolute;
  z-index: 2;
  bottom: -240px;
  right: 15px;
  max-width: 276px;
}
.product-info .desc-block .block9 {
  margin-top: 50px;
  text-align: center;
}
.product-info .desc-block .block9 .center {
  margin-bottom: 50px;
}
.product-info .desc-block .block9 .wrap {
  width: 80%;
  margin: 0 auto;
}
.product-info .desc-block .block9 .col2 {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.product-info .desc-block .block9 .col2 .item {
  width: calc(50% - 20px);
}
.product-info .desc-block .block9 .icon {
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f5ee;
  border-radius: 50%;
}
.product-info .desc-block .block9 .icon img {
  width: 50%;
  height: 50%;
}
.product-info .desc-block .block9 .gtxt {
  font-size: 21px;
  font-weight: bold;
  color: #ED6B28;
}
.product-info .desc-block .block9 .text {
  font-size: 17px;
  font-weight: bold;
}
.product-info .desc-block .block9 .caption {
  width: 80%;
  margin: 50px auto 0;
  text-align: left;
}
.product-info .desc-block .block9 .caption .gtxt {
  font-size: 17px;
}
.product-info .desc-block .block9 .caption .txt {
  line-height: 1.8em;
  font-weight: bold;
}
.product-info .desc-block .block10 {
  margin-top: 50px;
  background-color: #f0f5ee;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}
.product-info .desc-block .block10 .title {
  max-width: 100%;
  max-height: 35px;
}
.product-info .desc-block .block10 .note {
  font-size: 14px;
  color: #482e23;
  line-height: 2em;
}
.product-info .related .related-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-info .related .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product-info .related .title .text {
  font-size: 36px;
  font-weight: bold;
  color: #482e23;
  padding-bottom: 5px;
  background: url("../image/wavypattern.png") bottom repeat-x;
}
.product-info .related .related-list {
  position: relative;
  margin-top: 30px;
}
.product-info .related .related-list .item {
  display: flex;
  flex-direction: column;
  max-width: 260px;
}
.product-info .related .related-list .item .info {
  text-align: center;
}
.product-info .related .related-list .item .info .name {
  color: #482e23;
  font-weight: bold;
  font-size: 19px;
  margin-top: 23px;
}
.product-info .related .related-list .item .info .subname {
  color: #524643;
  font-size: 13px;
  margin-top: 11px;
}
.product-info .related .related-list .item .info .price-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}
.product-info .related .related-list .item .info .price-info .special {
  background-color: #ffa200;
  padding: 0 2px;
  color: #fff;
  margin-right: 6px;
}
.product-info .related .related-list .item .info .price-info .price {
  color: #ffa200;
  font-size: 21px;
  font-weight: bold;
}
.product-info .related .related-list .item:hover .info .name {
  color: #ED6B28;
}
.product-info .related .related-list .swiper-container {
  max-width: 1160px;
}
.product-info .related .related-list .swiper-slide {
  overflow: hidden;
}
.product-info .related .related-list .swiper-slide:hover {
  border-color: #ED6B28;
}
.product-info .related .related-list .swiper-button-prev,
.product-info .related .related-list .swiper-button-next {
  position: absolute;
  z-index: 2px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  background-image: none;
  color: #ED6B28;
  margin: 0;
}
.product-info .related .related-list .swiper-button-prev svg,
.product-info .related .related-list .swiper-button-next svg {
  width: 40px;
  height: 40px;
}
.product-info .related .related-list .swiper-button-prev.swiper-button-disabled,
.product-info .related .related-list .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}
.product-info .related .related-list .swiper-button-prev {
  right: 0;
}
.product-info .related .related-list .swiper-button-next {
  left: auto;
}
@media (max-width: 1200px) {
  .product-info {
    padding: 60px;
  }
  .product-info .desc-block .block1 .wrap {
    align-items: center;
    margin: -50px 0 0;
  }
  .product-info .desc-block .block1 .wrap .wrap1 {
    max-width: 250px;
  }
  .product-info .desc-block .block1 .wrap .wrap2 {
    align-self: auto;
    margin: 0;
  }
  .product-info .desc-block .block1 .wrap .wrap3 {
    max-width: 200px;
  }
  .product-info .desc-block .block2 .col3,
.product-info .desc-block .block6 .col3 {
    width: 100%;
  }
  .product-info .desc-block .block5 .photo .pleft,
.product-info .desc-block .block5 .photo .pright {
    width: calc(50% - 20px);
  }
  .product-info .desc-block .block5 .photo .pleft {
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .product-info {
    padding: 50px 30px 30px;
  }
  .product-info .desc-block .block3 .col2 .item {
    width: calc(50% - 20px);
  }
  .product-info .desc-block .block9 .wrap {
    width: 100%;
  }
  .product-info .desc-block .block9 .caption {
    width: 90%;
  }
  .product-info .related .related-list .item .info .price-info .price {
    font-size: 17px;
  }
}
@media (max-width: 900px) {
  .product-info .info-wrap .buttons {
    flex-wrap: wrap;
  }
  .product-info .info-wrap .buttons .button {
    width: 100%;
  }
  .product-info .info-wrap .buttons .button + .button {
    margin: 20px 0 0;
  }
  .product-info .desc-block .block1 .wrap .wrap1 {
    max-width: 150px;
  }
  .product-info .desc-block .block1 .wrap .wrap2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .product-info .desc-block .block1 .wrap .wrap2 .txt1 {
    height: 40px;
  }
  .product-info .desc-block .block1 .wrap .wrap3 {
    max-width: 150px;
  }
  .product-info .desc-block .block2 .col2 {
    flex-wrap: wrap;
  }
  .product-info .desc-block .block2 .col2 .text {
    max-width: none;
  }
  .product-info .desc-block .block2 .col2 .item {
    width: 100%;
  }
  .product-info .desc-block .block2 .col2 .item.image {
    display: flex;
    justify-content: center;
  }
  .product-info .desc-block .block2 .col2 .item + .item {
    margin-top: 30px;
  }
  .product-info .desc-block .block2 .col3 .item {
    width: calc(33.33% - 30px);
  }
  .product-info .desc-block .block3 {
    margin-top: 80px;
  }
  .product-info .desc-block .block3 .col2 {
    flex-wrap: wrap;
  }
  .product-info .desc-block .block3 .col2 .text {
    max-width: none;
  }
  .product-info .desc-block .block3 .col2 .wrap {
    top: -85px;
  }
  .product-info .desc-block .block3 .col2 .item {
    width: 100%;
  }
  .product-info .desc-block .block3 .col2 .item.image {
    display: flex;
    justify-content: center;
  }
  .product-info .desc-block .block3 .col2 .item + .item {
    margin-top: 100px;
  }
  .product-info .desc-block .block5 .wrap {
    flex-direction: column;
  }
  .product-info .desc-block .block5 .wrap .wrapinfo {
    margin: 40px 0 0;
  }
  .product-info .desc-block .block6 .col3 .item {
    width: calc(33.33% - 30px);
  }
  .product-info .desc-block .block8 .foot {
    margin-right: 200px;
  }
  .product-info .desc-block .block8 .wrap {
    bottom: -130px;
    max-width: 200px;
  }
}
@media (max-width: 768px) {
  .product-info {
    background-position: 99% -0.5%, 112% 20%;
  }
  .product-info .info-wrap {
    flex-wrap: wrap;
  }
  .product-info .info-wrap .ileft,
.product-info .info-wrap .iright {
    width: 100%;
  }
  .product-info .info-wrap .iright {
    margin-top: 30px;
  }
  .product-info .desc-title {
    margin-top: 120px;
  }
  .product-info .desc-block .block1 .image .tag {
    top: 20px;
    right: 20px;
  }
  .product-info .desc-block .block2 .col3,
.product-info .desc-block .block6 .col3 {
    flex-direction: column;
    align-items: center;
  }
  .product-info .desc-block .block2 .col3 .item,
.product-info .desc-block .block6 .col3 .item {
    width: 100%;
  }
  .product-info .desc-block .block2 .col3 .item .image,
.product-info .desc-block .block6 .col3 .item .image {
    max-width: 320px;
    margin: 0 auto;
  }
  .product-info .desc-block .block2 .col3 .item + .item,
.product-info .desc-block .block6 .col3 .item + .item {
    margin-top: 50px;
  }
  .product-info .desc-block .block4 .icon,
.product-info .desc-block .block9 .icon {
    width: 230px;
    height: 230px;
  }
  .product-info .desc-block .block4 .col2,
.product-info .desc-block .block9 .col2 {
    flex-direction: column;
  }
  .product-info .desc-block .block4 .col2 .item,
.product-info .desc-block .block9 .col2 .item {
    width: 100%;
  }
  .product-info .desc-block .block4 .col2 .item + .item,
.product-info .desc-block .block9 .col2 .item + .item {
    margin-top: 30px;
  }
  .product-info .desc-block .block4 .col2 .col2,
.product-info .desc-block .block9 .col2 .col2 {
    flex-direction: row;
  }
  .product-info .desc-block .block4 .col2 .col2 .item,
.product-info .desc-block .block9 .col2 .col2 .item {
    width: calc(50% - 20px);
  }
  .product-info .desc-block .block4 .col2 .col2 .item + .item,
.product-info .desc-block .block9 .col2 .col2 .item + .item {
    margin-top: 0;
  }
  .product-info .desc-block .block5 .photo .pright .col2 {
    margin: -20px 0 0 -20px;
  }
  .product-info .desc-block .block5 .photo .pright .col2 .item {
    width: calc(50% - 20px);
    margin: 20px 0 0 20px;
  }
  .product-info .desc-block .block7 .qalist {
    width: 100%;
    margin: 0;
  }
  .product-info .desc-block .block7 .qalist::before {
    left: 10px;
  }
  .product-info .desc-block .block7 .qalist::after {
    width: 80px;
    height: 80px;
    right: 0;
  }
  .product-info .desc-block .block9 .caption {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .product-info {
    padding-top: 200px;
  }
  .product-info .desc-block .block8 .foot {
    margin-right: 0;
  }
  .product-info .desc-block .block8 .wrap {
    display: none;
  }
}
@media (max-width: 650px) {
  .product-info .desc-title .juicy {
    top: -27px;
    left: 10px;
    width: 81px;
    height: 118px;
  }
  .product-info .desc-block .block1 .image .tag,
.product-info .desc-block .block1 .wrap .wrap1,
.product-info .desc-block .block1 .wrap .wrap3 {
    display: none;
  }
  .product-info .desc-block .block1 .wrap {
    justify-content: center;
    margin-top: 20px;
  }
  .product-info .desc-block .block4 .center {
    text-align: left;
  }
  .product-info .desc-block .block5 .wrap .wrapinfo .slogan {
    flex-wrap: wrap;
    justify-content: center;
  }
  .product-info .desc-block .block5 .photo {
    flex-wrap: wrap;
  }
  .product-info .desc-block .block5 .photo .pleft,
.product-info .desc-block .block5 .photo .pright {
    width: 100%;
  }
  .product-info .desc-block .block5 .photo .pright {
    margin-top: 40px;
  }
  .product-info .desc-block .block4 .icon,
.product-info .desc-block .block9 .icon {
    width: 150px;
    height: 150px;
  }
  .product-info .desc-block .block7 .qalist .question,
.product-info .desc-block .block7 .qalist .answer {
    padding: 2px 20px 2px 70px;
  }
  .product-info .desc-block .block8 .head {
    flex-direction: column;
  }
  .product-info .desc-block .block8 table.list th {
    font-size: 19px;
  }
  .product-info .desc-block .block8 table.list tbody td {
    font-size: 17px;
  }
}
@media (max-width: 480px) {
  .product-info .desc-block .block4 .gtxt {
    font-size: 16px;
  }
  .product-info .desc-block .block4 .text {
    font-size: 19px;
  }
}
@media (max-width: 425px) {
  .product-info {
    padding: 180px 15px 30px;
  }
  .product-info .desc-block .block5 .wrap .slogan .item {
    width: 134px;
    height: 130px;
    font-size: 26px;
  }
  .product-info .desc-block .block10 {
    padding: 20px 10px;
  }
}

/* 會員登入與註冊 */
.login-block,
.register-block {
  display: flex;
}
.login-block .left-block,
.register-block .left-block {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  flex: none;
  width: 50%;
}
.login-block .left-block .login-bg,
.register-block .left-block .login-bg {
  position: relative;
  z-index: 1;
  background: center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.login-block .left-block .link,
.register-block .left-block .link {
  color: #ED6B28;
}
.login-block .left-block .wrap,
.register-block .left-block .wrap {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  transform: translateY(-30px);
}
.login-block .left-block .wrap svg,
.register-block .left-block .wrap svg {
  width: 90px;
  height: 90px;
  color: #ED6B28;
  margin-bottom: 15px;
}
.login-block .right-block,
.register-block .right-block {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  flex: none;
  width: 50%;
  padding: 60px 40px;
  overflow: hidden;
  overflow-y: auto;
}
.login-block .right-block .firstbuy,
.register-block .right-block .firstbuy {
  margin-top: 10px;
}
.login-block .right-block .title,
.register-block .right-block .title {
  color: #000;
  font-size: 19px;
  font-weight: bold;
}
.login-block .right-block .uiform,
.register-block .right-block .uiform {
  margin: 20px 0 60px;
}
.login-block .social-wrap,
.register-block .social-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0 -10px;
}
.login-block .social-wrap .item,
.register-block .social-wrap .item {
  margin: 10px 0 0 10px;
  width: 128px;
  height: 90px;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-block .social-wrap .item svg,
.register-block .social-wrap .item svg {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}
.login-block .social-wrap .item:hover,
.register-block .social-wrap .item:hover {
  color: #ED6B28;
  border-color: #ED6B28;
}
.login-block .social-wrap .item.facebook,
.register-block .social-wrap .item.facebook {
  color: #fff;
  background-color: #3b5997;
  border-color: #3b5997;
}
.login-block .social-wrap .item.facebook:hover,
.register-block .social-wrap .item.facebook:hover {
  color: #3b5997;
  background-color: #fff;
}
.login-block .social-wrap .item.line,
.register-block .social-wrap .item.line {
  color: #fff;
  background-color: #00ba01;
  border-color: #00ba01;
}
.login-block .social-wrap .item.line:hover,
.register-block .social-wrap .item.line:hover {
  color: #00ba01;
  background-color: #fff;
}
@media (max-width: 1024px) {
  .login-block .left-block,
.login-block .right-block,
.register-block .left-block,
.register-block .right-block {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .login-block,
.register-block {
    height: 100vh;
  }
  .login-block .right-block,
.register-block .right-block {
    width: 50%;
    padding: 30px 20px;
  }
}
@media (max-width: 650px) {
  .login-block .left-block,
.register-block .left-block {
    right: 0;
    bottom: auto;
    width: 100%;
    height: 240px;
  }
  .login-block .left-block .login-bg,
.register-block .left-block .login-bg {
    background-position: center 25%;
  }
  .login-block .left-block .wrap,
.register-block .left-block .wrap {
    transform: none;
  }
  .login-block .right-block,
.register-block .right-block {
    top: 240px;
    left: 0;
    width: 100%;
  }
}
@media (max-width: 500px) {
  .login-block .left-block .login-bg,
.register-block .left-block .login-bg {
    background-position: center 10%;
  }
  .login-block .left-block .wrap svg,
.register-block .left-block .wrap svg {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 375px) {
  .login-block .left-block .login-bg,
.register-block .left-block .login-bg {
    background-position: center 0%;
  }
}

/* 忘記密碼 */
.forgotten-block {
  padding: 40px;
}
.forgotten-block .title {
  color: #000;
  font-size: 19px;
  font-weight: bold;
}
.forgotten-block .note {
  color: #666666;
}
.forgotten-block .uiform {
  margin: 20px 0 0;
}
.forgotten-block .buttons {
  margin-top: 20px;
}
@media (max-width: 425px) {
  .forgotten-block {
    padding: 40px 20px;
  }
  .forgotten-block .buttons.col2 .button {
    width: 100%;
  }
  .forgotten-block .buttons.col2 .button + .button {
    margin-top: 10px;
  }
}

/* 會員專區 */
.account-block {
  background: url("../image/vein1-green.png"), url("../image/vein3-green.png"), url("../image/juicy2.png");
  background-repeat: no-repeat;
  background-position: left -50px bottom -90px, right -30px top -50px, left 35px top 20px;
  background-size: 344px 259px, 314px 205px, 195px 272px;
  padding: 50px;
  min-height: 400px;
}
.account-block .title {
  text-align: center;
}
.account-block .title .icon {
  color: #fff;
  background-color: #ED6B28;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 7px;
  margin: 0 auto;
}
.account-block .title .icon svg {
  width: 26px;
  height: 26px;
}
.account-block .title .txt {
  margin-top: 5px;
  font-weight: bold;
  font-size: 18px;
  color: #ED6B28;
}
.account-block .title .welcome {
  font-size: 17px;
  margin-top: 9px;
  color: #737373;
}
.account-block .func-area {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 0 -10px;
}
.account-block .func-area .item {
  position: relative;
  width: calc(16.667% - 10px);
  margin: 10px 0 0 10px;
  background-color: #f0f5ee;
  border-radius: 50%;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-block .func-area .item .unread {
  position: absolute;
  top: 7px;
  right: 7px;
  line-height: 15px;
  background-color: #e80009;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 17px;
}
.account-block .func-area .item .wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.account-block .func-area .item svg {
  width: 60px;
  height: 60px;
  color: #ED6B28;
}
.account-block .func-area .item .name {
  margin-top: 15px;
  font-size: 17px;
  font-weight: bold;
  color: #737373;
}
.account-block .func-area .item::before {
  content: "";
  padding-top: 100%;
}
.account-block .func-area .item:hover {
  background-color: #ED6B28;
  transform: translateY(-10px);
}
.account-block .func-area .item:hover svg,
.account-block .func-area .item:hover .name {
  color: #fff;
}
@media (max-width: 1023px) {
  .account-block .func-area .item {
    width: calc(20% - 10px);
  }
}
@media (max-width: 900px) {
  .account-block .func-area .item {
    width: calc(25% - 10px);
  }
}
@media (max-width: 768px) {
  .account-block {
    height: 100vh;
  }
}
@media (max-width: 700px) {
  .account-block .title .welcome {
    font-weight: bold;
    text-shadow: 1px 0px 2px white, 0px 1px 2px white, -1px 0px 2px white, 0px -1px 2px white;
  }
  .account-block .func-area .item {
    width: calc(33.33% - 10px);
  }
}
@media (max-width: 550px) {
  .account-block .func-area .item {
    width: calc(50% - 10px);
  }
}
@media (max-width: 425px) {
  .account-block {
    padding: 50px 20px;
  }
}

/* 變更密碼 */
.password-block {
  padding: 40px;
}
.password-block .title {
  color: #000;
  font-size: 19px;
  font-weight: bold;
}
.password-block .uiform {
  margin: 20px 0 0;
}
.password-block .buttons {
  margin-top: 20px;
}
@media (max-width: 425px) {
  .password-block {
    padding: 40px 20px;
  }
  .password-block .buttons.col2 .button {
    width: 100%;
  }
  .password-block .buttons.col2 .button + .button {
    margin-top: 10px;
  }
}

/* 修改資料 */
.edit-block {
  background: url("../image/vein1-green.png"), url("../image/vein3-green.png");
  background-repeat: no-repeat;
  background-position: left -8% bottom -45%, right -10% top -25%;
  background-size: 344px 259px, 314px 205px;
  padding: 50px;
}
.edit-block .title {
  color: #000;
  font-size: 19px;
  font-weight: bold;
}
.edit-block .uiform {
  margin-top: 30px;
}
.edit-block .picture {
  background: center no-repeat;
  width: 150px;
  height: 150px;
  background-size: contain;
  margin: 20px auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.edit-block .picture .wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.edit-block .picture .picture-change {
  background: #e6e6e6 center no-repeat;
  color: #524643;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
}
@media (max-width: 650px) {
  .edit-block .picture {
    margin-bottom: 0;
  }
}
.edit-block .buttons {
  margin-top: 50px;
}
.edit-block .buttons.col2 .button {
  max-width: 160px;
}
@media (max-width: 768px) {
  .edit-block {
    padding: 50px 20px;
  }
}
@media (max-width: 650px) {
  .edit-block .uiform {
    margin-top: 0;
  }
}
@media (max-width: 425px) {
  .edit-block .buttons.col2 .button {
    width: 100%;
    max-width: none;
  }
  .edit-block .buttons.col2 .button + .button {
    margin-top: 10px;
  }
}

.wish-block {
  background: url("../image/vein1-green.png"), url("../image/vein3-green.png");
  background-repeat: no-repeat;
  background-position: left -8% bottom -45%, right -10% top -25%;
  background-size: 344px 259px, 314px 205px;
  padding: 50px;
}
.wish-block .title {
  color: #000;
  font-size: 19px;
  font-weight: bold;
}
.wish-block .wish-list {
  margin-top: 20px;
}
.wish-block .wish-list .body {
  margin-top: 40px;
}
.wish-block .wish-list .body .item {
  display: flex;
  align-items: center;
}
.wish-block .wish-list .body .item .thumb {
  flex: none;
  background: center no-repeat;
  background-size: contain;
  width: 80px;
  height: 80px;
}
.wish-block .wish-list .body .item .wrap {
  flex: auto;
  display: flex;
  align-items: center;
}
.wish-block .wish-list .body .item .name {
  flex: auto;
  font-size: 18px;
  padding: 0 5px;
}
.wish-block .wish-list .body .item .stock {
  flex: none;
  width: 100px;
  text-align: center;
}
.wish-block .wish-list .body .item .buttons {
  flex: none;
  max-width: 150px;
}
.wish-block .wish-list .body .item .buttons .button {
  padding: 5px;
}
.wish-block .wish-list .body .item .buttons .button + .button {
  margin-top: 5px;
}
.wish-block .wish-list .body .item + .item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #cccccc;
}
@media (max-width: 650px) {
  .wish-block .wish-list .body .item .wrap {
    flex-wrap: wrap;
    margin-left: 10px;
  }
  .wish-block .wish-list .body .item .buttons {
    max-width: none;
    flex-wrap: nowrap;
    margin-top: 10px;
  }
  .wish-block .wish-list .body .item .buttons .button + .button {
    margin: 0 0 0 10px;
  }
}
@media (max-width: 425px) {
  .wish-block .wish-list .body .item .name {
    width: 100%;
  }
  .wish-block .wish-list .body .item .stock {
    margin-top: 5px;
    text-align: left;
  }
  .wish-block .wish-list .body .item .buttons {
    margin-top: 5px;
  }
  .wish-block .wish-list .body .item .buttons .button {
    font-size: 15px;
  }
}
.wish-block .wish-list .body .empty {
  font-size: 19px;
  margin: 0;
  font-weight: bold;
}
.wish-block .buttons.action {
  margin-top: 40px;
}
@media (max-width: 600px) {
  .wish-block {
    padding: 50px 20px;
  }
}
@media (max-width: 425px) {
  .wish-block {
    padding: 50px 10px;
  }
}

.order-block {
  background: url("../image/vein1-green.png"), url("../image/vein3-green.png");
  background-repeat: no-repeat;
  background-position: left -8% bottom -45%, right -10% top -25%;
  background-size: 344px 259px, 314px 205px;
  padding: 50px;
}
.order-block .title {
  color: #000;
  font-size: 19px;
  font-weight: bold;
}
.order-block .order-list {
  margin-top: 20px;
}
.order-block .order-list .pagination {
  margin-top: 20px;
}
.order-block .order-info {
  margin-top: 20px;
}
.order-block .order-info .info {
  display: flex;
  justify-content: space-between;
}
.order-block .order-info .info .bold {
  font-weight: bold;
}
.order-block .order-info .products,
.order-block .order-info .payship,
.order-block .order-info .historys,
.order-block .order-info .comment,
.order-block .order-info .buttons {
  margin-top: 20px;
}
.order-block .order-info .products .total {
  text-align: right;
}
.order-block .order-info .payment-total {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.order-block .order-info .payment-total .item {
  flex: auto;
}
.order-block .order-info .payment-total .pdata {
  display: inline-block;
  padding: 5px;
  background-color: #f2f2f2;
}
.order-block .order-info .payment-total .pdata .data {
  display: flex;
}
.order-block .order-info .payment-total .pdata .name {
  text-align: right;
  min-width: 100px;
}
.order-block .order-info .payment-total .totals {
  flex: none;
  max-width: 480px;
}
.order-block .order-info .payment-total .wrap {
  display: flex;
  justify-content: flex-end;
}
.order-block .order-info .payment-total .wrap .value {
  min-width: 100px;
  padding-right: 5px;
  text-align: right;
}
.order-block .order-info .payment-total .buttons {
  margin-top: 0;
}
.order-block .order-info .payship {
  display: flex;
  flex-wrap: wrap;
}
.order-block .order-info .payship .wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.order-block .order-info .payship .wrap .title {
  width: 100%;
}
.order-block .order-info .payship .wrap + .wrap {
  margin-top: 20px;
}
.order-block .order-info .payship .item {
  display: flex;
  width: calc(50% - 10px);
  text-align: left;
  word-break: break-all;
}
.order-block .order-info .payship .item .name {
  min-width: 100px;
  text-align: right;
}
.order-block .order-info .historys .item {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}
.order-block .order-info .historys .item + .item {
  border-top: 1px solid #cccccc;
}
.order-block .order-info .historys .item:hover {
  background-color: #f2f2f2;
}
.order-block .order-info .historys .status {
  margin-left: 15px;
}
.order-block .order-info .historys .wrap {
  width: 100%;
  display: flex;
}
.order-block .order-info .historys .note {
  width: 80px;
}
.order-block .order-info .historys .txt {
  width: calc(100% - 80px);
}
.order-block .order-info .buttons .button {
  min-width: 180px;
}
@media (max-width: 900px) {
  .order-block .order-info .payship .item {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .order-block .order-info .info {
    flex-wrap: wrap;
  }
  .order-block .order-info .info .item {
    width: calc(50% - 10px);
  }
  .order-block .order-info table.list thead td {
    white-space: nowrap;
  }
  .order-block .order-info .payment-total {
    flex-wrap: wrap;
  }
  .order-block .order-info .payment-total .item {
    order: 2;
    flex: none;
    width: 100%;
  }
  .order-block .order-info .payment-total .item.totals {
    order: 1;
    margin-bottom: 10px;
    max-width: none;
  }
  .order-block .order-info .payment-total .pdata {
    width: 100%;
  }
  .order-block .order-info .payment-total .buttons .button {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .order-block .order-info .info .item {
    width: 100%;
  }
}
.order-block .buttons {
  margin-top: 40px;
}
@media (max-width: 700px) {
  .order-block {
    padding: 50px 20px;
  }
}
@media (max-width: 560px) {
  .order-block .order-list table.list td.total {
    display: none;
  }
}
@media (max-width: 500px) {
  .order-block {
    padding: 50px 10px;
  }
  .order-block .order-list table.list tbody td {
    font-size: 13px;
  }
  .order-block .order-list table.list td.date {
    display: none;
  }
}

.cart-step {
  background: url("../image/vein1-green.png"), url("../image/vein3-green.png");
  background-repeat: no-repeat;
  background-position: left -50px bottom -90px, right -30px top -50px;
  background-size: 344px 259px, 314px 205px;
  padding: 70px 50px 50px;
}
.cart-step .step {
  display: flex;
  margin: 0px 0px 30px -35px;
}
.cart-step .step .item {
  flex: auto;
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 40px;
  background-color: #f0f5ee;
  color: #000;
  width: calc(25% - 35px);
  margin-left: 35px;
}
.cart-step .step .item .stage {
  position: relative;
  width: 80px;
  flex: none;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart-step .step .item .stage .num {
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
}
.cart-step .step .item .stage::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 20px;
  top: 0;
  right: 12px;
  bottom: 0;
  margin: auto;
  background-color: #999999;
}
.cart-step .step .item .wrap {
  position: relative;
  width: calc(100% - 80px);
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.cart-step .step .item .wrap .text {
  margin-top: -15px;
  font-weight: bold;
}
.cart-step .step .item .wrap svg {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 25px;
  bottom: -5px;
  color: #ED6B28;
}
.cart-step .step .item + .item {
  position: relative;
}
.cart-step .step .item + .item::before {
  content: "▶";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ED6B28;
}
.cart-step .step .item.hover {
  color: #fff;
  background-color: #ED6B28;
}
.cart-step .step .item.hover .stage::after {
  background-color: #fff;
}
.cart-step .step .item.hover .wrap svg {
  color: #fff;
}
@media (max-width: 850px) {
  .cart-step .step {
    margin-left: 0;
  }
  .cart-step .step .item {
    margin-left: 0;
    border-radius: 0;
  }
  .cart-step .step .item .wrap .text {
    margin-top: 0;
  }
  .cart-step .step .item .wrap svg {
    bottom: 0;
    width: 50px;
    height: 50px;
    opacity: 0.15;
  }
  .cart-step .step .item + .item::before {
    display: none;
  }
}
@media (max-width: 700px) {
  .cart-step .step .item {
    flex-direction: column;
    justify-content: center;
  }
  .cart-step .step .item .stage {
    flex-direction: row;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
  }
  .cart-step .step .item .stage .num {
    font-size: 24px;
    line-height: 24px;
  }
  .cart-step .step .item .stage::after {
    display: none;
  }
  .cart-step .step .item .wrap {
    width: auto;
    height: auto;
    justify-content: center;
    overflow: visible;
  }
  .cart-step .step .item .wrap svg {
    bottom: -10px;
    right: -15px;
  }
}
@media (max-width: 500px) {
  .cart-step .step .item {
    display: none;
    flex-direction: row;
  }
  .cart-step .step .item.hover {
    display: flex;
  }
  .cart-step .step .item.hover .stage {
    width: 80px;
    flex: none;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cart-step .step .item.hover .stage .num {
    font-size: 28px;
    line-height: 28px;
    font-weight: bold;
  }
  .cart-step .step .item.hover .stage::after {
    display: block;
  }
  .cart-step .step .item.hover .wrap {
    width: calc(100% - 80px);
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .cart-step .step .item.hover .wrap .text {
    font-size: 22px;
  }
  .cart-step .step .item.hover .wrap svg {
    width: 55px;
    height: 55px;
    right: 20px;
    bottom: -5px;
    opacity: 1;
  }
}
.cart-step .step1 .payandship {
  display: flex;
  justify-content: space-between;
}
.cart-step .step1 .payandship .grid-wrap {
  flex: none;
  width: calc(50% - 15px);
}
.cart-step .step1 .payandship .grid-wrap .title {
  font-size: 19px;
  font-weight: bold;
  color: #ED6B28;
}
.cart-step .step1 .payandship .grid-wrap .grid-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -20px;
}
.cart-step .step1 .payandship .grid-wrap .grid-block .item {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 20px 0 0 20px;
  background-color: #f0f5ee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-step .step1 .payandship .grid-wrap .grid-block .item .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart-step .step1 .payandship .grid-wrap .grid-block .item .icon {
  width: 45px;
  height: 45px;
  color: #ED6B28;
}
.cart-step .step1 .payandship .grid-wrap .grid-block .item .text {
  color: #000;
  letter-spacing: 0;
  margin-top: 10px;
}
.cart-step .step1 .payandship .grid-wrap .grid-block .item:hover, .cart-step .step1 .payandship .grid-wrap .grid-block .item.selected {
  background-color: #ED6B28;
}
.cart-step .step1 .payandship .grid-wrap .grid-block .item:hover .icon, .cart-step .step1 .payandship .grid-wrap .grid-block .item.selected .icon {
  color: #fff;
}
.cart-step .step1 .payandship .grid-wrap .grid-block .item:hover .text, .cart-step .step1 .payandship .grid-wrap .grid-block .item.selected .text {
  color: #fff;
}
@media (max-width: 970px) {
  .cart-step .step1 .payandship {
    flex-direction: column;
  }
  .cart-step .step1 .payandship .grid-wrap {
    width: 100%;
  }
  .cart-step .step1 .payandship .grid-wrap + .grid-wrap {
    margin-top: 20px;
  }
}
.cart-step .step1 .cart-list {
  margin-top: 30px;
}
.cart-step .step1 .cart-list .item {
  display: flex;
  align-items: center;
}
.cart-step .step1 .cart-list .item .thumb {
  flex: none;
  width: 80px;
}
.cart-step .step1 .cart-list .item .thumb img {
  max-width: 100%;
}
.cart-step .step1 .cart-list .item .info-wrap {
  display: flex;
  width: calc(100% - 140px);
}
.cart-step .step1 .cart-list .item .info {
  flex: auto;
  padding: 0 10px;
  line-height: 1.5em;
  color: #ED6B28;
}
.cart-step .step1 .cart-list .item .quantity {
  flex: none;
  width: 140px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 5px;
}
.cart-step .step1 .cart-list .item .quantity input {
  flex: auto;
  border: none;
  margin: 0 3px;
  text-align: center;
  font-size: 17px;
}
.cart-step .step1 .cart-list .item .quantity .quantity-button {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
  color: #999999;
  width: 30px;
  height: 30px;
  border-radius: 3px;
}
.cart-step .step1 .cart-list .item .quantity .quantity-button svg {
  width: 20px;
  height: 20px;
}
.cart-step .step1 .cart-list .item .quantity .quantity-button:hover {
  color: #fff;
  background-color: #ED6B28;
}
.cart-step .step1 .cart-list .item .quantity .minbuy {
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  color: #e80009;
}
.cart-step .step1 .cart-list .item .price {
  display: flex;
  justify-content: flex-end;
  flex: none;
  width: 80px;
}
.cart-step .step1 .cart-list .item .total {
  display: flex;
  justify-content: flex-end;
  flex: none;
  width: 80px;
}
.cart-step .step1 .cart-list .item .remove {
  display: flex;
  flex: none;
  width: 60px;
}
.cart-step .step1 .cart-list .item .remove svg {
  transform: rotateZ(45deg);
  width: 30px;
  height: 30px;
}
.cart-step .step1 .cart-list .item .remove:hover {
  color: #e80009;
}
.cart-step .step1 .cart-list .item .center {
  justify-content: center;
}
.cart-step .step1 .cart-list .item + .item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #cccccc;
}
@media (max-width: 700px) {
  .cart-step .step1 .cart-list .item.heading .info,
.cart-step .step1 .cart-list .item.heading .quantity,
.cart-step .step1 .cart-list .item.heading .price,
.cart-step .step1 .cart-list .item.heading .total {
    display: none;
  }
  .cart-step .step1 .cart-list .item .info-wrap {
    flex-wrap: wrap;
  }
  .cart-step .step1 .cart-list .item .info-wrap .info {
    order: 1;
    width: 100%;
    margin-bottom: 5px;
    padding-left: 0;
  }
  .cart-step .step1 .cart-list .item .info-wrap .quantity {
    order: 4;
    padding: 0;
    width: auto;
  }
  .cart-step .step1 .cart-list .item .info-wrap .quantity input {
    flex: none;
    width: 40px;
  }
  .cart-step .step1 .cart-list .item .info-wrap .price {
    order: 2;
    width: auto;
    align-items: center;
    margin-right: 10px;
  }
  .cart-step .step1 .cart-list .item .info-wrap .price::before {
    content: "價格 : ";
  }
  .cart-step .step1 .cart-list .item .info-wrap .total {
    order: 3;
    width: auto;
    align-items: center;
    margin-right: 10px;
  }
  .cart-step .step1 .cart-list .item .info-wrap .total::before {
    content: "小計 : ";
  }
}
@media (max-width: 500px) {
  .cart-step .step1 .cart-list .item .info-wrap .quantity {
    margin-top: 10px;
    width: 100%;
  }
  .cart-step .step1 .cart-list .item .info-wrap .quantity input {
    flex: auto;
    width: auto;
  }
}
.cart-step .step1 .cart-list .empty {
  margin: 10px 0 0 0;
  padding-top: 10px;
  border-top: 1px solid #cccccc;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 69px;
}
.cart-step .step1 .cart-total {
  border-top: 1px solid #cccccc;
  background-color: #f0f5ee;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cart-step .step1 .cart-total .item {
  padding: 5px 0;
}
.cart-step .step1 .cart-total .item.total .price {
  color: #ED6B28;
  font-size: 28px;
  font-weight: bold;
}
.cart-step .step1 .buttons {
  margin-top: 40px;
}
.cart-step .step1 .buttons.col2 .button {
  max-width: 160px;
}
.cart-step .step3 .title {
  color: #000;
  font-size: 19px;
  font-weight: bold;
}
.cart-step .step3 .invoice {
  display: flex;
  margin-top: 20px;
}
.cart-step .step3 .invoice .item {
  display: flex;
}
.cart-step .step3 .invoice .item .label {
  align-items: flex-start;
}
.cart-step .step3 .invoice .item .radio {
  flex: auto;
  display: flex;
  flex-direction: column;
}
.cart-step .step3 .invoice .item .radio .label {
  white-space: nowrap;
}
.cart-step .step3 .invoice .item .radio .radio-item {
  display: flex;
  align-items: center;
}
.cart-step .step3 .invoice .item .radio .radio-item .wrap {
  display: flex;
}
.cart-step .step3 .invoice .item .radio .radio-item.invoice3 .invoice3-wrap {
  display: flex;
}
.cart-step .step3 .invoice .item .radio .radio-item.invoice3 .input {
  height: 30px;
  margin-left: 10px;
}
.cart-step .step3 .invoice .item .radio .radio-item + .radio-item {
  margin-top: 10px;
}
.cart-step .step3 .invoice .invoice_note {
  color: #e80009;
  margin-left: 10px;
}
.cart-step .step3 .note {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.cart-step .step3 .note .textarea {
  align-items: flex-start;
}
.cart-step .step3 .note .item {
  width: 100%;
}
.cart-step .step3 .note .item.agree {
  display: flex;
}
.cart-step .step3 .note .item.agree .checkbox {
  margin-right: 5px;
}
.cart-step .step3 .heading {
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 2px solid #ED6B28;
}
.cart-step .step3 .heading .set {
  margin-left: 10px;
}
.cart-step .step3 .wrap-col2 {
  margin-top: 0;
}
.cart-step .step3 .buttons {
  margin-top: 30px;
}
.cart-step .step3 .buttons.col2 .button {
  max-width: 160px;
}
@media (max-width: 768px) {
  .cart-step .step3 .wrap-col2 {
    margin: 0;
  }
  .cart-step .step3 .wrap-col2 .item {
    width: 100%;
    margin: 20px 0 0;
  }
}
@media (max-width: 680px) {
  .cart-step .step3 .invoice .item {
    flex-wrap: wrap;
  }
  .cart-step .step3 .invoice .item .radio {
    width: 100%;
    margin: 10px 0 0 20px;
  }
}
@media (max-width: 560px) {
  .cart-step .step3 .invoice .item .radio .radio-item.invoice3 .invoice3-wrap {
    flex-wrap: wrap;
  }
  .cart-step .step3 .invoice .item .radio .radio-item.invoice3 .invoice3-wrap .input {
    width: 100%;
    margin-left: 0;
  }
  .cart-step .step3 .invoice .item .radio .radio-item.invoice3 .invoice3-wrap .input + .input {
    margin-top: 10px;
  }
}
@media (max-width: 425px) {
  .cart-step .step3 .invoice .item .radio .radio-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .cart-step .step3 .invoice .item .radio .radio-item.invoice3 .invoice3-wrap .input {
    margin-top: 10px;
  }
  .cart-step .step3 .invoice .item .radio .radio-item .invoice_note {
    margin: 10px 0 0 6px;
  }
  .cart-step .step3 .invoice .item .radio .radio-item + .radio-item {
    margin-top: 20px;
  }
  .cart-step .step3 .note .textarea {
    flex-direction: column;
  }
  .cart-step .step3 .note .textarea textarea {
    margin-top: 10px;
    width: 100%;
  }
}
.cart-step .step4 .msg-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart-step .step4 .msg-info .icon {
  width: 103px;
  height: 103px;
  background: center no-repeat;
  background-size: contain;
}
.cart-step .step4 .msg-info .wrap {
  margin-top: 20px;
  text-align: center;
}
.cart-step .step4 .msg-info .title {
  font-size: 28px;
  font-weight: bold;
  color: #ED6B28;
}
.cart-step .step4 .msg-info .note {
  margin-top: 10px;
}
.cart-step .step4 .msg-info .note a {
  color: #ED6B28;
}
.cart-step .step4 .msg-info.success .title {
  color: #ED6B28;
}
.cart-step .step4 .msg-info.success .icon {
  background-image: url("../image/success.png");
}
.cart-step .step4 .msg-info.failure .title {
  color: #e80009;
}
.cart-step .step4 .msg-info.failure .icon {
  background-image: url("../image/fail.png");
}
.cart-step .step4 .info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
.cart-step .step4 .info .pay {
  flex: none;
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
}
.cart-step .step4 .info .pay .item {
  font-size: 17px;
  line-height: 1.2em;
  padding: 3px 0;
}
.cart-step .step4 .info .note {
  flex: none;
  width: calc(50% - 10px);
}
.cart-step .step4 .buttons.col2 {
  justify-content: center;
  margin-top: 40px;
}
.cart-step .step4 .buttons.col2 .button {
  max-width: 150px;
}
.cart-step .step4 .buttons.col2 .button + .button {
  margin-left: 20px;
}
@media (max-width: 500px) {
  .cart-step .step4 .info .pay {
    width: 100%;
  }
  .cart-step .step4 .info .note {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 375px) {
  .cart-step .step4 .buttons.col2 {
    flex-direction: column;
  }
  .cart-step .step4 .buttons.col2 .button {
    max-width: none;
  }
  .cart-step .step4 .buttons.col2 .button + .button {
    margin: 20px 0 0;
  }
}
.cart-step.mulberry {
  background: url("../image/vein1-green.png"), url("../image/vein3-green.png"), url("../image/mulberry2.png");
  background-repeat: no-repeat;
  background-position: left -50px bottom -90px, right -30px top -50px, right 50px bottom -140px;
  background-size: 344px 259px, 314px 205px, 249px 250px;
}
@media (max-width: 900px) {
  .cart-step {
    padding: 70px 20px 50px;
  }
}
@media (max-width: 375px) {
  .cart-step {
    padding: 70px 10px 50px;
  }
}