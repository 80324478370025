.cart-step {
	background: url("../image/vein1-green.png"), url("../image/vein3-green.png");
	background-repeat: no-repeat;
	background-position: left -50px bottom -90px, right -30px top -50px;
	background-size: 344px 259px, 314px 205px;
	padding: 70px 50px 50px;

	.step {
		display: flex;
		margin: 0px 0px 30px -35px;

		.item {
			flex: auto;
			display: flex;
			align-items: center;
			height: 80px;
			border-radius: 40px;
			background-color: $mColor6;
			color: #000;
			width: calc(25% - 35px);
			margin-left: 35px;

			.stage {
				position: relative;
				width: 80px;
				flex: none;
				font-size: 11px;
				line-height: 11px;
				letter-spacing: 0;
				display: flex;
				flex-direction: column;
				align-items: center;

				.num {
					font-size: 28px;
					line-height: 28px;
					font-weight: bold;
				}

				&::after {
					content: "";
					position: absolute;
					width: 1px;
					height: 20px;
					top: 0;
					right: 12px;
					bottom: 0;
					margin: auto;
					background-color: useGray(12);
				}
			}

			.wrap {
				position: relative;
				width: calc(100% - 80px);
				height: 100%;
				overflow: hidden;
				display: flex;
				align-items: center;

				.text {
					margin-top: -15px;
					font-weight: bold;
				}

				svg {
					width: 40px;
					height: 40px;
					position: absolute;
					right: 25px;
					bottom: -5px;
					color: $mColor1;
				}
			}

			+ .item {
				position: relative;

				&::before {
					content: "▶";
					position: absolute;
					top: 0;
					bottom: 0;
					left: -23px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: $mColor1;
				}
			}

			&.hover {
				color: #fff;
				background-color: $mColor1;

				.stage {
					&::after {
						background-color: #fff;
					}
				}

				.wrap {
					svg {
						color: #fff;
					}
				}
			}
		}

		@include rwd-width(850) {
			margin-left: 0;

			.item {
				margin-left: 0;
				border-radius: 0;

				.wrap {
					.text {
						margin-top: 0;
					}
					svg {
						bottom: 0;
						width: 50px;
						height: 50px;
						opacity: 0.15;
					}
				}

				+ .item {
					&::before {
						display: none;
					}
				}
			}
		}

		@include rwd-width(700) {
			.item {
				flex-direction: column;
				justify-content: center;

				.stage {
					flex-direction: row;
					font-size: 24px;
					line-height: 24px;
					font-weight: bold;

					.num {
						font-size: 24px;
						line-height: 24px;
					}

					&::after {
						display: none;
					}
				}

				.wrap {
					width: auto;
					height: auto;
					justify-content: center;
					overflow: visible;

					svg {
						bottom: -10px;
						right: -15px;
					}
				}
			}
		}

		@include rwd-width(500) {
			.item {
				display: none;
				flex-direction: row;

				&.hover {
					display: flex;

					.stage {
						width: 80px;
						flex: none;
						font-size: 11px;
						line-height: 11px;
						letter-spacing: 0;
						display: flex;
						flex-direction: column;
						align-items: center;

						.num {
							font-size: 28px;
							line-height: 28px;
							font-weight: bold;
						}

						&::after {
							display: block;
						}
					}

					.wrap {
						width: calc(100% - 80px);
						height: 100%;
						overflow: hidden;
						display: flex;
						align-items: center;
						justify-content: flex-start;

						.text {
							font-size: 22px;
						}

						svg {
							width: 55px;
							height: 55px;
							right: 20px;
							bottom: -5px;
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.step1 {
		.payandship {
			display: flex;
			justify-content: space-between;

			.grid-wrap {
				flex: none;
				width: calc(50% - 15px);

				.title {
					font-size: 19px;
					font-weight: bold;
					color: $mColor1;
				}

				.grid-block {
					display: flex;
					flex-wrap: wrap;
					margin: 0 0 0 -20px;

					.item {
						position: relative;
						width: 120px;
						height: 120px;
						margin: 20px 0 0 20px;
						background-color: $mColor6;
						display: flex;
						justify-content: center;
						align-items: center;

						.wrap {
							display: flex;
							flex-direction: column;
							align-items: center;
						}

						.icon {
							width: 45px;
							height: 45px;
							color: $mColor1;
						}

						.text {
							color: #000;
							letter-spacing: 0;
							margin-top: 10px;
						}

						&:hover,
						&.selected {
							background-color: $mColor1;

							.icon {
								color: #fff;
							}

							.text {
								color: #fff;
							}
						}
					}
				}
			}

			@include rwd-width(970) {
				flex-direction: column;

				.grid-wrap {
					width: 100%;

					+ .grid-wrap {
						margin-top: 20px;
					}
				}
			}
		}

		.cart-list {
			margin-top: 30px;

			.item {
				display: flex;
				align-items: center;

				.thumb {
					flex: none;
					width: 80px;

					img {
						max-width: 100%;
					}
				}

				.info-wrap {
					display: flex;
					width: calc(100% - 140px);
				}

				.info {
					flex: auto;
					padding: 0 10px;
					line-height: 1.5em;
					color: $mColor1;
				}

				.quantity {
					flex: none;
					width: 140px;
					display: flex;
					flex-wrap: wrap;
					padding: 0 5px;

					input {
						flex: auto;
						border: none;
						margin: 0 3px;
						text-align: center;
						font-size: 17px;
					}

					.quantity-button {
						flex: none;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: useGray(17);
						color: useGray(12);
						width: 30px;
						height: 30px;
						border-radius: 3px;

						svg {
							width: 20px;
							height: 20px;
						}

						&:hover {
							color: #fff;
							background-color: $mColor1;
						}
					}

					.minbuy {
						width: 100%;
						margin-top: 5px;
						font-size: 12px;
						text-align: center;
						color: $red;
					}
				}

				.price {
					display: flex;
					justify-content: flex-end;
					flex: none;
					width: 80px;
				}

				.total {
					display: flex;
					justify-content: flex-end;
					flex: none;
					width: 80px;
				}

				.remove {
					display: flex;
					flex: none;
					width: 60px;

					svg {
						transform: rotateZ(45deg);
						width: 30px;
						height: 30px;
					}

					&:hover {
						color: $red;
					}
				}

				.center {
					justify-content: center;
				}

				+ .item {
					margin-top: 10px;
					padding-top: 10px;
					border-top: 1px solid useGray(16);
				}

				@include rwd-width(700) {
					&.heading {
						.info,
						.quantity,
						.price,
						.total {
							display: none;
						}
					}

					.info-wrap {
						flex-wrap: wrap;

						.info {
							order: 1;
							width: 100%;
							margin-bottom: 5px;
							padding-left: 0;
						}

						.quantity {
							order: 4;
							padding: 0;
							width: auto;

							input {
								flex: none;
								width: 40px;
							}
						}

						.price {
							order: 2;
							width: auto;
							align-items: center;
							margin-right: 10px;

							&::before {
								content: "價格 : ";
							}
						}

						.total {
							order: 3;
							width: auto;
							align-items: center;
							margin-right: 10px;

							&::before {
								content: "小計 : ";
							}
						}
					}
				}

				@include rwd-width(500) {
					.info-wrap {
						.quantity {
							margin-top: 10px;
							width: 100%;

							input {
								flex: auto;
								width: auto;
							}
						}
					}
				}
			}

			.empty {
				margin: 10px 0 0 0;
				padding-top: 10px;
				border-top: 1px solid useGray(16);
				width: 100%;
				font-size: 24px;
				font-weight: bold;
				text-align: center;
				line-height: 69px;
			}
		}

		.cart-total {
			border-top: 1px solid useGray(16);
			background-color: $mColor6;
			margin-top: 10px;
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			.item {
				padding: 5px 0;

				&.total {
					.price {
						color: $mColor1;
						font-size: 28px;
						font-weight: bold;
					}
				}
			}
		}

		.buttons {
			margin-top: 40px;

			&.col2 {
				.button {
					max-width: 160px;
				}
			}
		}
	}

	.step3 {
		.title {
			color: #000;
			font-size: 19px;
			font-weight: bold;
		}

		.invoice {
			display: flex;
			margin-top: 20px;

			.item {
				display: flex;

				.label {
					align-items: flex-start;
				}

				.radio {
					flex: auto;
					display: flex;
					flex-direction: column;

					.label {
						white-space: nowrap;
					}

					.radio-item {
						display: flex;
						align-items: center;

						.wrap {
							display: flex;
						}

						&.invoice3 {
							.invoice3-wrap {
								display: flex;
							}

							.input {
								height: 30px;
								margin-left: 10px;
							}
						}

						+ .radio-item {
							margin-top: 10px;
						}
					}
				}
			}

			.invoice_note {
				color: $red;
				margin-left: 10px;
			}
		}

		.note {
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;

			.textarea {
				align-items: flex-start;
			}

			.item {
				width: 100%;

				&.agree {
					display: flex;

					.checkbox {
						margin-right: 5px;
					}
				}
			}
		}

		.heading {
			display: flex;
			align-items: center;
			margin-top: 25px;
			padding-top: 25px;
			border-top: 2px solid $mColor1;

			.set {
				margin-left: 10px;
			}
		}

		.wrap-col2 {
			margin-top: 0;
		}

		.buttons {
			margin-top: 30px;

			&.col2 {
				.button {
					max-width: 160px;
				}
			}
		}

		@include rwd-width(768) {
			.wrap-col2 {
				margin: 0;

				.item {
					width: 100%;
					margin: 20px 0 0;
				}
			}
		}

		@include rwd-width(680) {
			.invoice {
				.item {
					flex-wrap: wrap;

					.radio {
						width: 100%;
						margin: 10px 0 0 20px;
					}
				}
			}
		}

		@include rwd-width(560) {
			.invoice {
				.item .radio .radio-item.invoice3 .invoice3-wrap {
					flex-wrap: wrap;

					.input {
						width: 100%;
						margin-left: 0;

						+ .input {
							margin-top: 10px;
						}
					}
				}
			}
		}

		@include rwd-width(425) {
			.invoice .item .radio .radio-item {
				flex-direction: column;
				align-items: flex-start;

				&.invoice3 .invoice3-wrap {
					.input {
						margin-top: 10px;
					}
				}

				.invoice_note {
					margin: 10px 0 0 6px;
				}

				+ .radio-item {
					margin-top: 20px;
				}
			}

			.note {
				.textarea {
					flex-direction: column;

					textarea {
						margin-top: 10px;
						width: 100%;
					}
				}
			}
		}
	}

	.step4 {
		.msg-info {
			display: flex;
			flex-direction: column;
			align-items: center;

			.icon {
				width: 103px;
				height: 103px;
				background: center no-repeat;
				background-size: contain;
			}

			.wrap {
				margin-top: 20px;
				text-align: center;
			}

			.title {
				font-size: 28px;
				font-weight: bold;
				color: $mColor1;
			}

			.note {
				margin-top: 10px;

				a {
					color: $mColor1;
				}
			}

			&.success {
				.title {
					color: $mColor1;
				}
				.icon {
					background-image: url("../image/success.png");
				}
			}

			&.failure {
				.title {
					color: $red;
				}
				.icon {
					background-image: url("../image/fail.png");
				}
			}
		}

		.info {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 30px;

			.pay {
				flex: none;
				width: calc(50% - 10px);
				display: flex;
				flex-direction: column;

				.item {
					font-size: 17px;
					line-height: 1.2em;
					padding: 3px 0;
				}
			}

			.note {
				flex: none;
				width: calc(50% - 10px);
			}
		}

		.buttons.col2 {
			justify-content: center;
			margin-top: 40px;

			.button {
				max-width: 150px;

				+ .button {
					margin-left: 20px;
				}
			}
		}

		@include rwd-width(500) {
			.info {
				.pay {
					width: 100%;
				}

				.note {
					width: 100%;
					margin-top: 20px;
				}
			}
		}

		@include rwd-width(375) {
			.buttons.col2 {
				flex-direction: column;

				.button {
					max-width: none;

					+ .button {
						margin: 20px 0 0;
					}
				}
			}
		}
	}

	&.mulberry {
		background: url("../image/vein1-green.png"), url("../image/vein3-green.png"), url("../image/mulberry2.png");
		background-repeat: no-repeat;
		background-position: left -50px bottom -90px, right -30px top -50px, right 50px bottom -140px;
		background-size: 344px 259px, 314px 205px, 249px 250px;
	}

	@include rwd-width(900) {
		padding: 70px 20px 50px;
	}

	@include rwd-width(375) {
		padding: 70px 10px 50px;
	}
}
